import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { RestaurantModel } from "../../models/restaurant.model";
import { UserService } from "../../services/user.service";
import { UserModel } from "../../models/user.model";
import { Subscription } from "rxjs";
import { environment } from "../../../../environments/environment";
import { QrCodeIconComponent } from "../qr-code-icon/qr-code-icon.component";
import { ModalService } from "../../services/modal.service";

@Component({
  selector: "app-restaurant-selector",
  templateUrl: "./restaurant-selector.component.html",
  styleUrls: ["./restaurant-selector.component.scss"],
})
export class RestaurantSelectorComponent implements OnDestroy, OnInit {
  public readonly FRONTEND_URL = environment.frontendUrl;
  private user: UserModel;
  private subscriptions: Subscription[] = [];
  displayDropdown = true;
  selectedRestaurantId = 0;
  restaurants: RestaurantModel[];
  selectedRestaurant: RestaurantModel;
  private unsubscribe: Subscription[] = [];
  filteredRestaurants: RestaurantModel[] = [];
  displayedRestaurants: RestaurantModel[] = [];
  searchDropdownOpen = false;
  searchTerm: string = "";
  currentPage = 1;
  itemsPerPage = 10;
  totalPages = 1;

  constructor(
    private userService: UserService,
    private modalService: ModalService,
    private elRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.subscriptions.push(
      this.userService.user.subscribe((user) => {
        if (user) {
          this.user = user;
          this.loadRestaurants();
        }
      })
    );
  }

  ngOnInit() {
    this.renderer.listen("document", "click", (event: Event) => {
      if (!this.elRef.nativeElement.contains(event.target)) {
        this.searchDropdownOpen = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  handleSelection(restaurant: RestaurantModel): void {
    this.selectedRestaurant = restaurant;
    this.selectedRestaurantId = restaurant.id;
    this.userService.setUsersRestaurant(restaurant.id);
  }

  private loadRestaurants(): void {
    this.userService
      .getRestaurants()
      .subscribe((restaurants: RestaurantModel[]) => {
        this.restaurants = restaurants;
        this.restaurants.forEach((restaurant: RestaurantModel) => {
          if (this.user.restaurantId === restaurant.id) {
            this.selectedRestaurant = restaurant;
          }
        });

        if (this.selectedRestaurant) {
          const selectedIndex = this.restaurants.findIndex(
            (restaurant) => restaurant.id === this.selectedRestaurant.id
          );

          if (selectedIndex !== -1) {
            this.restaurants.splice(selectedIndex, 1);
            this.restaurants.unshift(this.selectedRestaurant);
          }
        }

        this.filterRestaurants();
        this.displayDropdown =
          ((this.user.isAdmin || this.user.isManager) &&
            this.restaurants.length > 1) ||
          (!this.user.restaurantId && this.restaurants.length > 0);
        this.initSelectedRestaurant();
      });
  }

  private initSelectedRestaurant(): void {
    this.restaurants.forEach((restaurant: RestaurantModel) => {
      if (this.user.restaurantId === restaurant.id) {
        this.selectedRestaurant = restaurant;
      }
    });
  }

  goToMenu() {
    if (this.selectedRestaurant) {
      const menuUrl = `${this.FRONTEND_URL}/restaurants/${this.selectedRestaurant.slug}/welcome`;
      window.open(menuUrl, "_blank");
    }
  }

  createQRCode(): void {
    const modalRef = this.modalService.open(QrCodeIconComponent, {
      centered: true,
    });

    modalRef.componentInstance.restaurantSlug = this.selectedRestaurant.slug;

    const canceledSubscription = modalRef.componentInstance.closed.subscribe(
      () => {
        this.modalService.close(modalRef);
      }
    );

    this.unsubscribe.push(canceledSubscription);
  }

  preventDropdownClose(event: Event): void {
    event.stopPropagation();
  }

  toggleSearchDropdown(event: Event) {
    event.stopPropagation();
    this.searchDropdownOpen = !this.searchDropdownOpen;
    if (
      this.searchDropdownOpen &&
      this.filteredRestaurants.length < this.itemsPerPage
    ) {
      this.currentPage = 1;
    }
  }

  public filterRestaurants(): void {
    if (this.searchTerm) {
      const searchTermLowerCase = this.searchTerm.toLowerCase();
      this.filteredRestaurants = this.restaurants.filter((restaurant) =>
        restaurant.name.toLowerCase().includes(searchTermLowerCase)
      );
    } else {
      this.filteredRestaurants = this.restaurants;
    }
    this.totalPages = Math.ceil(
      this.filteredRestaurants.length / this.itemsPerPage
    );

    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
    }

    if (this.filteredRestaurants.length < this.itemsPerPage) {
      this.currentPage = 1;
    }

    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    this.displayedRestaurants = this.filteredRestaurants.slice(
      startIndex,
      startIndex + this.itemsPerPage
    );
  }

  nextPage(event: Event) {
    event.stopPropagation();
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.filterRestaurants();
    }
  }

  previousPage(event: Event) {
    event.stopPropagation();
    if (this.currentPage > 1) {
      this.currentPage--;
      this.filterRestaurants();
    }
  }

  clearSearchTerm() {
    this.searchTerm = "";
    this.filterRestaurants();
  }
}
