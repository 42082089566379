<h5>{{label}}</h5>
<div class="image-input image-input-outline"
     id="kt_profile_avatar"
     [class.image-input-empty]="!imageUrl">
    <div class="image-input-wrapper" [class.streched]="stretched"  [class.cover]="coverBackground" [style.backgroundImage]="getPic()"></div>
    <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
           data-action="change"
           data-toggle="tooltip"
           title=""
           data-original-title="Change avatar">
        <i class="fa fa-pen icon-sm text-muted"></i>
        <input #imageInput
               type="file"
               accept=".png, .jpg, .jpeg"
               (change)="processFile(imageInput)">
    </label>
    <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
          data-action="cancel" data-toggle="tooltip" title="" data-original-title="Cancel avatar">
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
    <span *ngIf="imageUrl"
          class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
          (click)="deletePic()"
          data-action="remove" data-toggle="tooltip" title="" data-original-title="Remove avatar">
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
</div>
<span class="form-text text-muted">{{'Allowed file types: png, jpg, jpeg.' | translate}}</span>
<span class="form-text text-muted">{{'Max size: 5mb' | translate}}</span>

