import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { UserModel } from "../../models/user.model";
import { RestaurantsService } from "../../services/restaurants.service";

@Component({
  selector: "app-user-selector",
  templateUrl: "./user-selector.component.html",
  styleUrls: ["./user-selector.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserSelectorComponent),
      multi: true,
    },
  ],
})
export class UserSelectorComponent implements OnInit {
  @Input() selectedUserId: any;
  @Input() restaurantId: number;
  @Input() userRole: string;

  users: UserModel[];
  selectedUser: UserModel;

  constructor(private restaurantService: RestaurantsService) {}

  ngOnInit(): void {
    this.loadUsers();
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn: (_: any) => {}) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  writeValue(value: any) {
    this.selectedUserId = value;
  }

  handleSelection(user: UserModel) {
    this.selectedUser = user;
    this.propagateChange(user.userId);
  }

  private loadUsers() {
    this.restaurantService
      .getRestaurantUsers(this.restaurantId, { role: this.userRole })
      .subscribe((response) => {
        if (response.body) {
          this.users = response.body;
          this.initSelectedUser();
        }
      });
  }

  private initSelectedUser() {
    this.selectedUser = this.users.find(
      (user: any) => user.userId === this.selectedUserId
    );
  }
}
