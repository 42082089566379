<div class="modal-header">
    <h4 class="modal-title">{{title | translate}}</h4>
    <button type="button" class="close" aria-label="Close">
        <span aria-hidden="true"></span>
    </button>
</div>
<div class="modal-body">
    <h6 class="font-weight-light m-0 p-0">{{message | translate}}</h6>
    <div class="btn-group row no-gutters w-100 input-group mt-2">
        <div class="btn-group col" ngbDropdown role="group">
            <button id="categorySelectorDropdown" type="button"
                    class="btn btn-outline-secondary text-left text-truncate"
                    ngbDropdownToggle>
                {{reason ? (reason | translate) : ('Select a reason' | translate)}}
                <i class="fal fa-angle-down float-right mt-3"></i>
            </button>
            <div aria-labelledby="categorySelectorDropdown" class="dropdown-menu w-100" ngbDropdownMenu>
                <button type="button" *ngFor="let reason of reasons" class="text-left text-truncate" ngbDropdownItem
                        (click)="setReason(reason)">
                    {{reason | translate}}
                </button>
            </div>
        </div>
    </div>
    <textarea *ngIf="reason == REASON_OTHER" [(ngModel)]="customReason" class="form-control resize-none mt-3"
              maxlength="100" placeholder="{{'Type in a reason' | translate}}" rows="3"></textarea>
    <h6 class="font-weight-light m-0 p-0 mt-4 text-warning">{{'This action cannot be undone!' | translate}}</h6>
</div>
<div class="modal-footer">
    <button type="button" tabindex="-1" (click)="cancel()" class="btn btn-light">
        {{cancelButtonText | translate}}
    </button>
    <button type="button" tabindex="-1" (click)="accept()" class="btn btn-success" [disabled]="!isReasonEntered">
        {{confirmButtonText | translate}}
    </button>
</div>
