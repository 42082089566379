import moment from 'moment';
import { environment } from '../../../../environments/environment';

export class AuthModel {
  private static authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  authToken: string;
  refreshToken: string;
  expiresIn: number;
  tokenType: string;
  scope: string;


  static getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(AuthModel.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  static setAuthToLocalStorage(auth: AuthModel): boolean {
    if (auth && auth.authToken) {
      localStorage.setItem(AuthModel.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  static logout(): void {
    localStorage.removeItem(AuthModel.authLocalStorageToken);
    window.location.reload();
  }

  setAuth(response: any) {
    this.authToken = response.access_token;
    this.refreshToken = response.refresh_token;
    this.expiresIn = response.expires_in;
    this.tokenType = response.token_type;
    this.scope = response.scope;
    return this;
  }

}
