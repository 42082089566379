import { Component, EventEmitter, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { environment } from "../../../../environments/environment";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { UserService } from "../../../shared/services/user.service";
import { RestaurantsService } from "../../../shared/services/restaurants.service";

@Component({
  selector: "app-qr-code-icon",
  templateUrl: "./qr-code-icon.component.html",
  styleUrls: ["./qr-code-icon.component.scss"],
})
export class QrCodeIconComponent implements OnInit {
  @Input() restaurantSlug: string;
  couponsList: any[] = [];

  closed = new EventEmitter<boolean>();

  restaurantForm: FormGroup;
  isSaving = false;

  frontendUrl = environment.frontendUrl;

  constructor(
    private userService: UserService,
    private restaurantsService: RestaurantsService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadCoupons();
  }

  close(): void {
    this.closed.emit(true);
  }

  get couponControl(): FormControl {
    return this.restaurantForm.get("coupon") as FormControl;
  }

  get url() {
    const coupon = this.couponControl.value;
    const restaurantUrl = `${this.frontendUrl}/restaurants/${this.restaurantSlug}`;

    if (!coupon) {
      return `${restaurantUrl}/welcome`;
    }

    return `${restaurantUrl}/welcome?coupon=${coupon.code}`;
  }

  private initForm(): void {
    this.restaurantForm = new FormGroup({
      coupon: new FormControl(""),
    });
  }

  private loadCoupons(): void {
    this.userService.user.subscribe((user) => {
      if (user) {
        this.restaurantsService
          .getCoupons(user?.restaurantId)
          .subscribe((coupons) => {
            this.couponsList = [
              ...this.couponsList,
              ...coupons.body.map((coupon) => ({
                key: coupon.id,
                value: coupon.name,
                code: coupon.code,
              })),
            ];
          });
      }
    });
  }

  saveAsImage(parent) {
    const parentElement =
      parent.qrcElement.nativeElement.querySelector("img").src;

    const blobData = this.convertBase64ToBlob(parentElement);

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobData, "Qrcode");
    } else {
      const blob = new Blob([blobData], { type: "image/png" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Qrcode";
      link.click();
    }
  }

  private convertBase64ToBlob(Base64Image: any) {
    const parts = Base64Image.split(";base64,");
    const imageType = parts[0].split(":")[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: imageType });
  }

  goToMenu() {
    window.open(this.url, "_blank");
  }
}
