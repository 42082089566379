<div class="row" [formGroup]="formGroup">
    <div class="form-group col-md-12">
        <label for="address">{{'Address' | translate}}</label>
        <input type="text"
               class="form-control"
               id="address"
               formControlName="street">
        <small *ngIf="formGroup.get('street').touched && formGroup.get('street').errors?.required"
               class="text-danger">
            {{'Address is required' | translate}}
        </small>
    </div>
    <div class="form-group col-md-4">
        <label for="city">{{'City' | translate}}</label>
        <input type="text"
               class="form-control"
               id="city"
               formControlName="city">
        <small *ngIf="formGroup.get('city').touched && formGroup.get('city').errors?.required"
               class="text-danger">
            {{'City is required' | translate}}
        </small>
    </div>
    <div class="form-group col-md-4">
        <label for="zipCode">{{'Zip Code' | translate}}</label>
        <input type="text"
               class="form-control"
               id="zipCode"
               formControlName="zipcode">
        <small *ngIf="formGroup.get('zipcode').touched && formGroup.get('zipcode').errors?.required"
               class="text-danger">
            {{'Zipcode is required' | translate}}
        </small>
    </div>
    <div class="form-group col-md-4">
        <label for="country">{{'Country' | translate}}</label>
        <select id="country"
                name="country"
                class="form-control"
                formControlName="countryCode">
            <option value="{{country.code}}" *ngFor="let country of countries">{{country.name}}</option>
        </select>
        <small *ngIf="formGroup.get('countryCode').touched && formGroup.get('countryCode').errors?.required"
               class="text-danger">
            {{'Country is required' | translate}}
        </small>
    </div>
</div>
<div class="mapouter my-3">
    <div class="gmap_canvas">
        <agm-map 
        [latitude]="lat" 
        [longitude]="lng" 
        [zoom]="16" 
        [styles]="styles"
        [disableDefaultUI]="true"
        >
        <agm-map-type-control></agm-map-type-control>
        <agm-zoom-control></agm-zoom-control>
        <agm-fullscreen-control></agm-fullscreen-control>
            <agm-marker 
            [latitude]="lat" 
            [longitude]="lng" 
            [markerDraggable]="true" 
            (dragEnd)="choseLocation($event)"
            [iconUrl]="'/assets/media/logos/narudzba-icon.svg'"
            >
                <agm-info-window>
                    <strong>{{'Place a marker on location of your restaurant' | translate}}</strong>
                </agm-info-window>
            </agm-marker>
        </agm-map>
    </div>
</div>