import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ORDER_STATUSES } from '../../globals';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderStatusComponent implements OnChanges {

  @Input() status: string;
  @Input() class: string = '';

  key: string;
  color: string;
  label: string;
  cssClass: string;

  constructor(
    private translateService: TranslateService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    let orderStatus = ORDER_STATUSES.find(st => st.key === this.status);
    this.key = orderStatus?.key;
    this.color = orderStatus?.color;
    this.label = orderStatus?.value || 'N/A';
    this.cssClass = `${this.key === 'pending' || this.key === 'accepted' ? 'text-dark' : ''} label label-light-${this.color} label-pill label-inline ${this.class}`.trim();
  }

}
