import { AddressModel } from './address.model';
import { RestaurantModel } from './restaurant.model';
import { BaseModel } from './base.model';
import { COMMISSION } from '../globals';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export class CompanyModel extends BaseModel {

  static readonly NO_COMMISSION = {
    key: _('No Commission'),
    value: 'no-commission',
    color: 'danger'
  };

  id: number;
  name: string;
  addressId: number;
  address?: AddressModel;
  restaurants?: RestaurantModel[];
  hasCommission?: boolean;
  commissionType?: string;
  commissionAmount?: number;
  vatNumber?: string;
  currency?: string;


  get childModels() {
    return {
      address: AddressModel,
      restaurants: RestaurantModel
    };
  }

  get commissionTypeObj() {
    if(!this.hasCommission){
      return CompanyModel.NO_COMMISSION;
    }
    const commissionType = COMMISSION.TYPES.find(type => type.value === this.commissionType);
    if (commissionType) {
      return commissionType;
    }
    this.commissionAmount = 0;
    return CompanyModel.NO_COMMISSION;
  }

  toApi(model?: this): any {
    return {
      ...super.toApi(model),
      has_commission: model ? (model.hasCommission ? 1 : 0) : (this.hasCommission ? 1 : 0)
    };
  }
}
