import { Component, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter } from './date.adapter';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CustomDateParser } from './date.parser';
import { FormHelper } from '../../helpers/form.helper';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatepickerComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
    {
      provide: NgbDateAdapter,
      useClass: CustomAdapter
    },
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateParser
    }
  ]
})
export class DatepickerComponent implements ControlValueAccessor {

  date = new FormControl('');
  @Input() label: string = _('Date');

  constructor() {
  }

  onTouched = () => {
  }

  onValidationChange = () => {
  }


  writeValue(time: string): void {
    this.date.setValue(time);
  }

  registerOnChange(onChange: any): void {
    this.date.valueChanges.subscribe(onChange);
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    this.onTouched();
  }

  setDisabledState(disabled: boolean): void {
    disabled ? this.date.disable() : this.date.enable();
  }

  validate(control: AbstractControl): ValidationErrors {
    if (FormHelper.hasValidator(control, 'required')) {
      const dateIsBlank = !this.date.value;
      return dateIsBlank ? {dateIsBlank} : null;
    }
    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }


}
