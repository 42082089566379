<!--begin::Signin-->
<div class="login-form login-signin">
    <!--begin::Form-->
    <form
        class="form"
        [formGroup]="loginForm"
        novalidate="novalidate"
        id="kt_login_signin_form"
        (ngSubmit)="submit()">
        <!--begin::Title-->
        <div class="pb-13 pt-lg-0 pt-5">
            <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                {{'Welcome to Dinego' | translate}}
            </h3>
        </div>
        <!--end::Title-->

        <!-- begin::Alert error-->
        <ng-container *ngIf="hasError">
            <div class="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div class="alert-text">
                    {{'The login details are incorrect' | translate}}
                </div>
            </div>
        </ng-container>
        <!-- end::Alert error-->

        <!--begin::Form group-->
        <div class="form-group">
            <label class="font-size-h6 font-weight-bolder text-dark">
                {{'Email' | translate}}
            </label>
            <input
                class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                type="email"
                name="email"
                formControlName="email"
                autocomplete="off"/>
            <ng-container
                [ngTemplateOutlet]="formError"
                [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'Email is required' | translate,
                    control: loginForm.controls['email']
                }">
            </ng-container>
            <ng-container
                [ngTemplateOutlet]="formError"
                [ngTemplateOutletContext]="{
                    validation: 'email',
                    message: 'Email is Invalid' | translate,
                    control: loginForm.controls['email']
                }">
            </ng-container>
            <ng-container
                [ngTemplateOutlet]="formError"
                [ngTemplateOutletContext]="{
                    validation: 'minLength',
                    message: 'Email should have at least 3 symbols' | translate,
                    control: loginForm.controls['email']
                }">
            </ng-container>
            <ng-container
                [ngTemplateOutlet]="formError"
                [ngTemplateOutletContext]="{
                    validation: 'maxLength',
                    message: 'Email should have a maximum of 360 symbols' | translate,
                    control: loginForm.controls['email']
                }">
            </ng-container>
        </div>
        <!--end::Form group-->

        <!--begin::Form group-->
        <div class="form-group">
            <div class="d-flex justify-content-between mt-n5">
                <label class="font-size-h6 font-weight-bolder text-dark pt-5">
                    {{'Password' | translate}}
                </label>
                <a routerLink="/auth/forgot-password"
                   class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                   id="kt_login_forgot">
                    {{'Forgot Password' | translate}}
                </a>
            </div>

            <input
                class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                type="password"
                name="password"
                autocomplete="off"
                formControlName="password"/>
            <ng-container
                [ngTemplateOutlet]="formError"
                [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'Password is required' | translate,
                    control: loginForm.controls['password']
                }">
            </ng-container>
            <ng-container
                [ngTemplateOutlet]="formError"
                [ngTemplateOutletContext]="{
                    validation: 'minlength',
                    message: 'Password should have at least 3 symbols' | translate,
                    control: loginForm.controls['password']
                }">
            </ng-container>
            <ng-container
                [ngTemplateOutlet]="formError"
                [ngTemplateOutletContext]="{
                    validation: 'maxLength',
                    message: 'Password should have a maximum of 360 symbols' | translate,
                    control: loginForm.controls['password']
                }">
            </ng-container>
        </div>
        <!--end::Form group-->

        <!--begin::Action-->
        <div class="pb-lg-0 pb-5">
            <button
                type="submit"
                id="kt_login_signin_submit"
                class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                [disabled]="loginForm.invalid">
                {{'Sign In' | translate}}
            </button>

            <ng-container *ngIf="isLoading$ | async">
                <span class="spinner spinner-primary ml-5"></span>
            </ng-container>
        </div>
        <!--end::Action-->
    </form>
    <!--end::Form-->
</div>
<!--end::Signin-->

<ng-template
    #formError
    let-control="control"
    let-message="message"
    let-validation="validation">
    <ng-container *ngIf="control.hasError(validation) && control.touched">
        <div class="fv-plugins-message-container">
            <div class="fv-help-block">
                {{ message }}
            </div>
        </div>
    </ng-container>
</ng-template>
