import { ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-multiple-item-selector',
  templateUrl: './multiple-item-selector.component.html',
  styleUrls: ['./multiple-item-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleItemSelectorComponent),
      multi: true
    }
  ]
})
export class MultipleItemSelectorComponent implements OnInit, ControlValueAccessor {

  @Input() list: any[];
  @Input() listName: string;
  @Input() selectedItems: any[] = [];

  @Input() loadingList: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
    this.writeValue(this.selectedItems);
  }

  handleSelection(item: any, e) {
    if (e.target.checked) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
    }
    this.propagateChange(this.selectedItems);
  }

  resetSelection() {
    this.selectedItems = [];
    this.propagateChange(null);
  }

  writeValue(value: any): void {
    if (!value || value.length == 0) {
      return;
    }

    this.selectedItems = [];

    value.split(',').forEach(item => {
      const listItem = this.list.find(i => i.key === item);
      this.selectedItems.push(listItem);
    });

    this.propagateChange(this.selectedItems);
  }

  propagateChange = (_: any) => {

  };

  registerOnChange(fn: (_: any) => {}) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {

  }

  public get items() {
    return this.selectedItems;
  }
}
