<div class="btn-group custom no-gutters w-100 input-group">
    <div class="btn-group w-100" ngbDropdown container="body" role="group">
        <button type="button" class="btn btn-outline-secondary text-left" [disabled]="loadingList"
                [ngClass]="{'text-muted': !selectedItems?.length}"
                ngbDropdownToggle>
            <span class="mr-4">
                <ng-container *ngIf="selectedItems">
                    {{selectedItems[0]?.value | translate}}
                    <ng-container *ngIf="selectedItems.length > 1">
                        (+{{selectedItems.length - 1}})
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!selectedItems || selectedItems?.length < 1">
                    {{listName}}
                </ng-container>
            </span>
            <i class="fal fa-angle-down float-right mt-3"></i>
        </button>
        <div class="dropdown-menu w-auto position-absolute" ngbDropdownMenu>
            <span *ngIf="selectedItems" class="small m-4 text-nowrap">
                {{listName}}
            </span>
            <div *ngIf="selectedItems" class="dropdown-divider"></div>
            <label class="checkbox small m-4 text-nowrap" *ngFor="let item of list">
                <input type="checkbox" (change)="handleSelection(item, $event)"
                       [checked]="selectedItems.indexOf(item) != -1">
                <span class="mr-4"></span>{{item.value | translate}}
            </label>
        </div>
    </div>
</div>
