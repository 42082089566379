import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageSnippet } from './image-snippet.model';
import { ToastManagementService } from '../../services/toast-management.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent {
  private selectedFile: ImageSnippet | undefined;
  readonly MAX_UPLOAD_SIZE = 5000000;

  constructor(
    private toast: ToastManagementService
  ) {
  }

  @Input() imageUrl: string;
  @Input() placeholderImage = '/assets/media/users/blank.png';
  @Input() stretched = false;
  @Input() coverBackground = false;
  @Input() label = 'Image Upload';
  @Output() image = new EventEmitter<any>();


  getPic() {
    if (this.selectedFile) {
      return `url('${this.selectedFile.src}')`;
    }
    if (this.imageUrl != '') {
      return `url('${this.imageUrl}')`;
    }
    return 'none';
  }

  deletePic() {
    this.imageUrl = this.placeholderImage;
    this.selectedFile = undefined;
    this.image.emit(null);
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    if (file.size <= this.MAX_UPLOAD_SIZE) {
      const reader = new FileReader();
      reader.addEventListener('load', (event: any) => {
        this.selectedFile = new ImageSnippet(event.target.result, file);
        this.image.emit(this.selectedFile.file);
      });
      reader.readAsDataURL(file);
    } else {
      this.toast.showDanger(_('The image you selected is too large.'));
    }
  }
}
