<form [formGroup]="restaurantForm">
  <div class="modal-header">
    <h4>{{ "Create QR code" | translate }}</h4>
    <button type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="couponType">{{ "Coupon" | translate }}</label>
              <app-filter-item-selector
                id="couponType"
                [formControl]="couponControl"
                [selectedItem]="couponControl.value"
                [list]="couponsList"
                [listName]="'Coupon' | translate"
              >
              </app-filter-item-selector>
            </div>
          </div>

          <div class="col-6 m-auto">
            <div class="d-flex flex-grow-1 overflow-hidden">
              <qrcode
                #parent
                [qrdata]="url"
                [width]="1024"
                [errorCorrectionLevel]="'M'"
                [elementType]="'img'"
                [cssClass]="'d-flex flex-grow-1 justify-content-center'"
              ></qrcode>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-sm btn-light-success"
      (click)="goToMenu()"
    >
      {{ "Go to menu" | translate }}
      <i class="fas fa-external-link-alt ml-2"></i>
    </button>
    <button
      type="button"
      class="btn btn-sm btn-success"
      (click)="saveAsImage(parent)"
    >
      {{ "Download" | translate }}
      <i class="fal fa-download ml-2"></i>
    </button>
    <button type="button" (click)="close()" class="btn btn-light">
      {{ "Close" | translate }}
    </button>
  </div>
</form>
