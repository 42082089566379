import { Component, EventEmitter, OnInit } from '@angular/core';
import { CompanyFormGroup } from './company.form';
import { Validators } from '@angular/forms';
import {COMMISSION, CURRENCIES} from '../../globals';
import { CompanyService } from '../../services/company.service';
import { CompanyModel } from '../../models/company.model';

@Component({
  selector: 'app-company-modal',
  templateUrl: './company-modal.component.html',
  styleUrls: ['./company-modal.component.scss']
})
export class CompanyModalComponent implements OnInit {

  form = new CompanyFormGroup();
  commissionTypes = COMMISSION.TYPES;
  currencies = CURRENCIES;
  companySaved = new EventEmitter<CompanyModel>();
  canceled = new EventEmitter<boolean>();

  isSaving: boolean = false;

  set company(company: CompanyModel) {
    this.form = new CompanyFormGroup(company);
  }

  constructor(
    private companyService: CompanyService
  ) {

  }

  ngOnInit(): void {
    this.form.get('hasCommission').valueChanges.subscribe(value => {
      this.toggleControls(value);
    });
  }


  save(): void {
    if (this.form.valid) {
      this.isSaving = true;
      this.companyService.saveCompany(new CompanyModel(this.form.value)).subscribe(res => {
        if (res.body) {
          this.companySaved.emit(res.body);
          this.isSaving = false;
        }
      });
    } else {
      this.form.updateValueAndValidity();
      this.form.markAllAsTouched();
    }
  }

  cancel() {
    this.canceled.emit(true);
  }

  private toggleControls(hasCommission: boolean): void {
    const toggleControls = ['commissionType', 'commissionAmount'];
    toggleControls.forEach(control => {
      if (!hasCommission) {
        this.form.get(control).disable();
      } else {
        this.form.get(control).enable();
        this.form.get(control).setValidators(Validators.required);
      }
      return control;
    });
  }

}
