<div class="d-flex justify-content-between align-items-center flex-wrap flex-row-reverse">
    <!-- start: Paginator -->
    <div class="d-flex align-items-center py-3">
        <!-- begin: loading -->
        <div class="d-flex align-items-center" *ngIf="isLoading">
            <div class="mr-2 text-muted">{{'Loading...' | translate}}</div> <!-- TODO: Translate -->
            <div class="spinner spinner-primary mr-10"></div>
        </div>
        <!-- end: loading -->

        <!-- begin: pageSize -->
        <span class="react-bootstrap-table-pagination-total mr-2">
            {{'Results per page' | translate}}: <!-- TODO: Translate -->
        </span>
        <select
            class="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light false"
            style="width: 75px"
            [(ngModel)]="paginator.pageSize"
            (change)="sizeChange()"
        >
            <option class="btn" *ngFor="let ps of pageSizes">
                {{ ps }}
            </option>
        </select>
        <span class="react-bootstrap-table-pagination-total">
        {{ (paginator.page - 1) * paginator.pageSize + 1 }} -
            {{ (paginator.page * paginator.pageSize > paginator.total) ? paginator.total : paginator.page * paginator.pageSize }}
            {{'out of' | translate}} {{ paginator.total }} <!-- TODO: Translate -->
        </span>
        <!-- end: pageSize -->
    </div>
    <div class="d-flex flex-wrap py-2 mr-3" *ngIf="paginator.total / paginator.pageSize > 1">
        <ng-pagination
            [collectionSize]="paginator.total"
            [(page)]="paginator.page"
            [maxSize]="4"
            [rotate]="true"
            [boundaryLinks]="true"
            [pageSize]="paginator.pageSize"
            (pageChange)="pageChange($event)"
        ></ng-pagination>
    </div>
    <!-- end: Paginator -->
</div>
