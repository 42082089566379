<div class="btn-group row no-gutters w-100">
    <div class="btn-group col" ngbDropdown role="group">
        <button id="categorySelectorDropdown" type="button" class="btn btn-outline-secondary text-left" ngbDropdownToggle>
            {{selectedCategory?.name || ('Select category' | translate)}}
            <i class="fal fa-angle-down float-right mt-3"></i>
        </button>
        <div aria-labelledby="categorySelectorDropdown" class="dropdown-menu w-100" ngbDropdownMenu>
            <button type="button" *ngFor="let category of productCategories" ngbDropdownItem
                [ngClass]="'level-' + category.level"
                (click)="handleSelection(category)">
                {{category.name}}
            </button>
        </div>
    </div>
    <div *ngIf="hasCreateButton" class="btn-group col-auto">
        <button type="button" class="btn btn-outline-secondary btn-icon"
            (click)="handleCreateCategoryModal()">
            <i class="fal fa-plus"></i>
        </button>
    </div>
</div>

