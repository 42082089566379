import { BaseModel } from './base.model';
import { COUNTRIES } from '../globals';

export class AddressModel extends BaseModel {
  id: number;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  countryCode: string;
  lat: number;
  long: number;

  get asString(): string {
    return [this.line1, this.line2].join(', ');
  }

  get asHtml(): string {
    return [this.line1, this.line2].join('<br>');
  }

  get line1(): string {
    return (`${this.street}`).trim();
  }

  get line2(): string {
    return (`${this.zipcode} ${this.city}`).trim();
  }

  get line3(): string {
    return (`${this.state ? this.state + ',' : ''} ${this.country}`).trim();
  }

  get country(): string {
    return this.countryCode ? COUNTRIES.find(o => o.code == this.countryCode).name : '';
  }

}

export class CustomerAddressModel extends BaseModel {
  id: number;
  type: string;
  address: AddressModel;
  isDeleted: 0 | 1;

  get childModels(): {} {
    return {
      address: AddressModel
    };
  }

}
