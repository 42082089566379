<form #formElement class="card" [formGroup]="form" (ngSubmit)="save()">
    <div class="card-header">
        <h4 class="mb-0">{{title | translate}}</h4>
    </div>
    <div class="card-body row">
        <app-image-upload [label]="'User Image' | translate"
                          [imageUrl]="user?.pic"
                          [coverBackground]="true"
                          (image)="setImage('imageFile', $event)"
                          class="col-md-12 d-flex flex-column align-items-center mb-5">
        </app-image-upload>
        <div class="form-group col-md-6">
            <label for="userFirstName">
                {{'First Name ' | translate}}
            </label>
            <input type="text" class="form-control" id="userFirstName" formControlName="firstName">
            <small *ngIf="form.get('firstName').touched && form.get('firstName').errors?.required" class="text-danger">
                {{'First Name is required' | translate}}
            </small>
        </div>
        <div class="form-group col-md-6">
            <label for="userLastName">
                {{'Last Name ' | translate}}
            </label>
            <input type="text" class="form-control" id="userLastName" formControlName="lastName">
            <small *ngIf="form.get('lastName').touched && form.get('lastName').errors?.required" class="text-danger">
                {{'Last Name is required' | translate}}
            </small>
        </div>
        <div class="form-group col-md-6">
            <label for="userEmail">
                {{'Email ' | translate}}
            </label>
            <input type="text" class="form-control" id="userEmail" formControlName="email">
            <small *ngIf="form.get('email').touched && form.get('email').errors?.required" class="text-danger">
                {{'Email is required ' | translate}}
            </small>
            <small *ngIf="form.get('email').touched && form.get('email').errors?.email" class="text-danger">
                {{'Email is invalid ' | translate}}
            </small>
        </div>
        <div class="form-group col-md-6">
            <label for="userPosPasscode">
                {{'POS Pin ' | translate}}
            </label>
            <input type="password" class="form-control" id="userPosPasscode" formControlName="posPasscode">
            <small *ngIf="form.get('posPasscode').touched && form.get('posPasscode').errors?.maxlength" class="text-danger">
                {{ 'POS Pin can have maximum of 5 digits ' | translate }}
            </small>
            <small *ngIf="form.get('posPasscode').touched && form.get('posPasscode').errors?.pattern" class="text-danger" >
                {{ 'POS Pin must only contain digits ' | translate }}
            </small>
        </div>
        <div class="form-group col-md-6">
            <label for="userPassword">
                {{'Password ' | translate}}
            </label>
            <input type="password" class="form-control" id="userPassword" formControlName="newPassword">
            <small *ngIf="form.get('newPassword').touched && form.get('newPassword').errors?.required" class="text-danger">
                {{'Password is required ' | translate}}
            </small>
        </div>
        <div class="form-group col-md-6">
            <label>
                {{'Confirm password ' | translate}}
            </label>
            <input type="password" class="form-control" formControlName="passwordRepeat">
            <small *ngIf="form.get('passwordRepeat').touched && form.get('passwordRepeat').errors?.matchValidator" class="text-danger">
                {{'Passwords do not match ' | translate}}
            </small>
        </div>
        <div class="form-group col-md-6" *ngIf="updateSelf">
            <label for="userPhone">
                {{'Phone number ' | translate}}
            </label>
            <input type="text" class="form-control" id="userPhone" formControlName="phone">
            <small *ngIf="form.get('phone').touched && form.get('phone').errors?.required" class="text-danger">
                {{'Phone number is required' | translate}}
            </small>
        </div>
        <div class="form-group col-md-6" *ngIf="!updateSelf">
            <label>{{'Role' | translate}}</label>
            <select class="form-control" formControlName="role">
                <option *ngFor="let role of loggedUser.RESTAURANT_ROLES" [value]="role.value">
                    {{role.key}}
                </option>
            </select>
            <small *ngIf="form.get('role').touched && form.get('role').errors?.required" class="text-danger">
                {{'Role is required' | translate}}
            </small>
        </div>
    </div>
    <div class="card-footer d-flex align-items-center justify-content-end">
        <button (click)="cancel()" type="button" class="btn btn-secondary">
            {{'Cancel' | translate}}
        </button>
        <button type="submit" class="btn btn-success ml-2" [disabled]="saving">
            {{'Save' | translate}}
            <span *ngIf="saving" class="spinner-border spinner-border-sm ml-2" role="status">
                    <span class="sr-only">{{'Loading...' | translate}}</span>
                </span>
        </button>
    </div>
</form>
