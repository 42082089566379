<div class="col-auto px-2" ngbDropdown>
  <button
    type="button"
    class="btn btn-outline-secondary text-left rounded d-flex justify-content-between"
    ngbDropdownToggle
  >
    <i class="fal fa-shopping-bag p-0"></i>
    <span class="d-none d-md-inline-block ml-2">{{ "Menu" | translate }}</span>
  </button>
  <div class="dropdown-menu" ngbDropdownMenu>
    <button
      type="button"
      ngbDropdownItem
      class="d-flex align-items-center"
      (click)="goToMenu()"
    >
      <i class="fal fa-external-link-alt p-0"></i>
      <span class="ml-2">{{ "Go to menu" | translate }}</span>
    </button>
    <button
      type="button"
      ngbDropdownItem
      class="d-flex align-items-center"
      (click)="createQRCode()"
    >
      <i class="fal fa-qrcode p-0"></i>
      <span class="ml-2">{{ "Create QR code" | translate }}</span>
    </button>
  </div>
</div>

<div class="col input-group px-2" (click)="toggleSearchDropdown($event)">
  <button
    type="button"
    class="btn btn-outline-secondary text-left rounded d-flex justify-content-between"
    style="width: 180px"
  >
    <span class="text-truncate" style="max-width: 140px">
      {{ selectedRestaurant?.name || ("Select restaurant" | translate) }}
    </span>
    <i class="fal fa-angle-down pt-3 pl-3"></i>
  </button>
  <div
    class="dropdown-menu"
    [ngStyle]="{ display: searchDropdownOpen ? 'block' : 'none' }"
  >
    <div class="px-4 py-2">
      <input
        type="text"
        class="form-control"
        [placeholder]="'Search...' | translate"
        [(ngModel)]="searchTerm"
        (ngModelChange)="filterRestaurants()"
        (click)="preventDropdownClose($event)"
      />
    </div>
    <button
      *ngFor="let restaurant of displayedRestaurants"
      type="button"
      class="dropdown-item"
      [ngClass]="{
        'font-weight-bold bg-light': selectedRestaurant?.id === restaurant.id
      }"
      (click)="handleSelection(restaurant)"
    >
      <span class="text-truncate">
        {{ restaurant.name }}
        <i
          class="fal fa-check pl-2"
          *ngIf="selectedRestaurant?.id === restaurant.id"
        ></i>
      </span>
    </button>
    <div class="p-4" *ngIf="totalPages > 1">
      <div class="pagination-controls w-100">
        <div
          class="pagination-buttons w-100 d-flex justify-content-between align-items-center"
        >
          <button
            class="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
            (click)="previousPage($event)"
            [disabled]="currentPage === 1"
          >
            <i class="fal fa-chevron-left"></i>
          </button>
          <span
            [innerHTML]="
              'PAGE_OF_TOTAL'
                | translate
                  : {
                      currentPage: currentPage,
                      totalPages: totalPages
                    }
            "
          ></span>
          <button
            class="btn btn-icon btn-sm btn-light btn-hover-primary ml-2 my-1"
            (click)="nextPage($event)"
            [disabled]="currentPage === totalPages"
          >
            <i class="fal fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
