import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AddressModel} from '../../models/address.model';


export class AddressFormGroup extends FormGroup {
  constructor(value?: AddressModel) {
    super({
      street: new FormControl(value && value ? value.street : '', Validators.required),
      city: new FormControl(value && value ? value.city : '', Validators.required),
      state: new FormControl(value && value ? value.state : ''),
      zipcode: new FormControl(value && value ? value.zipcode : '', Validators.required),
      countryCode: new FormControl(value && value ? value.countryCode : '', Validators.required),
      lat: new FormControl(value && value ? value.lat : ''),
      long: new FormControl(value && value ? value.long : ''),
    });
  }

}
