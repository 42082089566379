import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PAYMENT_METHOD, PAYMENT_METHODS } from '../../globals';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {

  @Input() paymentMethod: string;
  @Input() size: string;

  type: string = '';
  color: string = '';
  icon: string = '';

  PAYMENT_METHOD = PAYMENT_METHOD;

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    const typeObj = PAYMENT_METHODS.find(type => type.key === this.paymentMethod);
    this.type = typeObj?.value || 'N/A';
    this.color = typeObj?.color;
    this.icon = typeObj?.icon;
  }

}
