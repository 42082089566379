import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-daterange-selector',
  templateUrl: './daterange-selector.component.html',
  styleUrls: ['./daterange-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DaterangeSelectorComponent),
      multi: true
    }
  ]
})
export class DaterangeSelectorComponent implements OnInit, ControlValueAccessor {

  @Input() dateRange: any;
  @Input() timePicker: boolean = true;
  @Input() openingDirection: string = 'right';
  @Input() label = _('From date - To date');

  locale: any = {};
  ranges: any = {};

  constructor(
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.setLabels();

    this.translate.onLangChange.subscribe(lang => {
      this.setLabels();
    });
  }

  setLabels() {
    const m = moment().locale(this.translate.currentLang);

    this.locale = {
      applyLabel: this.translateLabel(_('Apply')),
      clearLabel: this.translateLabel(_('Clear')),
      customRangeLabel: this.translateLabel(_('Custom range')),
      daysOfWeek: m.localeData().weekdaysMin(),
      monthNames: m.localeData().months(),
      format: 'DD.MM.YYYY H:mm',
      separator: ' - ',
      firstDay: 1
    };

    this.ranges = [];

    this.ranges[this.translateLabel(_('Today'))] = [moment().startOf('day'), moment().endOf('day')];
    this.ranges[this.translateLabel(_('This week'))] = [moment().startOf('isoWeek'), moment().endOf('isoWeek')];
    this.ranges[this.translateLabel(_('Last week'))] = [moment().startOf('isoWeek').subtract(7, 'days'), moment().endOf('isoWeek').subtract(7, 'days')];
    this.ranges[this.translateLabel(_('This month'))] = [moment().startOf('month'), moment().endOf('month')];
    this.ranges[this.translateLabel(_('Last month'))] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
  }

  translateLabel(value: string) {
    return this.translate.instant(value);
  }

  writeValue(value: any): void {
    this.dateRange = value;
  }

  registerOnChange(fn: (_: any) => {}) {
    this.propagateChange = fn;
  }

  propagateChange = (_: any) => {
  };

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  datesUpdated(value: any) {
    if (value.endDate?.$d == 'Invalid Date') {
      this.writeValue({startDate: this.dateRange.startDate, endDate: this.dateRange.startDate, startTime: this.dateRange.startTime, endTime:this.dateRange.endTime});
      this.propagateChange(this.dateRange);
    }
  }
}
