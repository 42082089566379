import { BaseModel } from './base.model';

export class DeviceModel extends BaseModel {
    id: number;
    restaurantId: number;
    name: string;
    description: string;
    serialNumber: string;
    ipAddress: string;
    deviceType: string;
    language: string;
}

