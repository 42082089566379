import { NgModule, APP_INITIALIZER, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { UserService } from './shared/services/user.service';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ThermalPrintModule } from 'ng-thermal-print';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { QuillModule } from 'ngx-quill';

function appInitializer(userService: UserService) {
  return () => {
    return new Promise((resolve) => {
      userService.initialize().add(resolve);
    });
  };
}

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: '',
  nullable: true,
  min: 0,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    HttpClientModule,
    ClipboardModule,
    AppRoutingModule,
    NgbModule,
    SharedModule,
    CoreModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: environment.appLanguage
    }),
    NgxDaterangepickerMd.forRoot(),
    ThermalPrintModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: ['places']
    }),
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', { 'list': 'ordered'}, { 'list': 'bullet' }, 'image', { 'header': [1, 2, 3, 4, 5, 6, false] }],
        ]
      }
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [UserService],
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: environment.appCurrency || 'CHF'
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
