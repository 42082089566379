import { Printer } from '../printer';
import { PrintService, UsbDriver } from 'ng-thermal-print';
import { Subscription, throwError } from 'rxjs';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import { PrintingData } from '../printing-data';
import EscPosEncoder from 'esc-pos-encoder';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { PrinterService } from '../../services/printer.service';

export class UsbPrinter extends Printer {

  static readonly PRINTER_TYPE = 'usb_printer';

  static readonly CONNECTION_STATUS_WAITING = 'waiting';
  static readonly CONNECTION_STATUS_NOT_AVAILABLE = 'not-available';
  static readonly CONNECTION_STATUS_CONNECTED = 'connected';
  static readonly CONNECTION_STATUS_DISCONNECTED = 'disconnected';
  static readonly CONNECTION_STATUS_RECONNECTED_WAITING = 'reconnected-waiting';
  static readonly CONNECTION_STATUS_RECONNECTED = 'reconnected';

  private printService: PrintService;
  private usbDriver: UsbDriver;

  private encoder: EscPosEncoder;

  private connectionStatus: string;

  private subscription: Subscription;

  constructor(printerService?: PrinterService, printer?: any) {
    super(printerService, printer);
    this.printerType = UsbPrinter.PRINTER_TYPE;
    this.printService = new PrintService();

    this.connectionStatus = UsbPrinter.CONNECTION_STATUS_WAITING;

    if (this.printerService) {
      this.connect();
    }
  }

  async connect(): Promise<void> {
    this.usbDriver = new UsbDriver(this.vendorId, this.productId);

    this.printService.setDriver(this.usbDriver, 'ESC/POS');

    this.subscription?.unsubscribe();

    this.subscription = this.printService.isConnected.pipe(debounceTime(2000), distinctUntilChanged()).subscribe(result => {

        this.changeConnectionStatus(result);
        if (this.isNotAvailable) {
          this.printerService.showErrorMessage(this.deviceName, _('Error connecting to printer'));
          return;
        }

        if (this.isConnected) {
          //this.printFromQueue();
          return;
        }

        if (this.isDisconnected) {
          this.printQueue = [];
          this.printerService.showErrorMessage(this.deviceName, _('Printer disconnected'));
          return;
        }

        if (this.isReconnectedAndWaiting) {
          this.connect();
        }

        if (this.isReconnected) {
          this.connectionStatus = UsbPrinter.CONNECTION_STATUS_RECONNECTED;
          this.printerService.showSuccessMessage(this.deviceName, _('Printer reconnected'));
        }
      }
    );

  }

  async printData(): Promise<void> {

    if (!this.printQueue) {
      this.currentlyPrinting = false;
      return;
    }

    let data = this.printQueue.shift();

    if (!data) {
      this.currentlyPrinting = false;
      return;
    }

    if (!this.canPrint) {
      if (this.isWaiting || this.isReconnectedAndWaiting) {
        this.printQueue.push(data);
      }
      await this.connect();
      return;
    }

    this.transformPrintData(data);

    this.printService.init();
    this.printService.driver.write(this.encoder.encode());

    this.printService
      .feed(data.getEndFeed)
      .cut('full')
      .flush();

    data.callback();

    this.printData();
  }

  protected transformPrintData(data: PrintingData): void {
    this.encoder = new EscPosEncoder();

    this.encoder.codepage('cp850');

    data.getLines.forEach(line => {
      UsbPrinter.setTextSize(this.encoder, line.size, line.size);

      this.encoder
        .bold(line.bold)
        .align(line.alignment)
        .underline(line.underlined)
        .line(line.getText());
    });

  }

  private static setTextSize(encoder: EscPosEncoder, width: number, height: number) {
    if (!width && !height) {
      return;
    }
    encoder.raw([0x1d, 0x21, (height - 1) | (width - 1) << 4]);
  }

  protected changeConnectionStatus(isConnected: boolean): void {
    switch (this.connectionStatus) {
      case UsbPrinter.CONNECTION_STATUS_WAITING:
        this.connectionStatus = isConnected ? UsbPrinter.CONNECTION_STATUS_CONNECTED : UsbPrinter.CONNECTION_STATUS_NOT_AVAILABLE;
        break;
      case UsbPrinter.CONNECTION_STATUS_NOT_AVAILABLE:
        if (isConnected) {
          this.connectionStatus = UsbPrinter.CONNECTION_STATUS_CONNECTED;
        }
        break;
      case UsbPrinter.CONNECTION_STATUS_CONNECTED:
        if (!isConnected) {
          this.connectionStatus = UsbPrinter.CONNECTION_STATUS_DISCONNECTED;
        }
        break;
      case UsbPrinter.CONNECTION_STATUS_DISCONNECTED:
        if (isConnected) {
          this.connectionStatus = UsbPrinter.CONNECTION_STATUS_RECONNECTED_WAITING;
        }
        break;
      case UsbPrinter.CONNECTION_STATUS_RECONNECTED_WAITING:
        if (isConnected) {
          this.connectionStatus = UsbPrinter.CONNECTION_STATUS_RECONNECTED;
        }
        break;
      case UsbPrinter.CONNECTION_STATUS_RECONNECTED:
        if (!isConnected) {
          this.connectionStatus = UsbPrinter.CONNECTION_STATUS_DISCONNECTED;
        }
        break;
    }

    super.changeConnectionStatus(this.canPrint);
  }

  private get isConnected(): boolean {
    return this.connectionStatus == UsbPrinter.CONNECTION_STATUS_CONNECTED;
  }

  private get isReconnectedAndWaiting(): boolean {
    return this.connectionStatus == UsbPrinter.CONNECTION_STATUS_RECONNECTED_WAITING;
  }

  private get isReconnected(): boolean {
    return this.connectionStatus == UsbPrinter.CONNECTION_STATUS_RECONNECTED;
  }

  private get isNotAvailable(): boolean {
    return this.connectionStatus == UsbPrinter.CONNECTION_STATUS_NOT_AVAILABLE;
  }

  private get isWaiting(): boolean {
    return this.connectionStatus == UsbPrinter.CONNECTION_STATUS_WAITING;
  }

  private get isDisconnected(): boolean {
    return this.connectionStatus == UsbPrinter.CONNECTION_STATUS_DISCONNECTED;
  }

  private get canPrint(): boolean {
    return this.isConnected || this.isReconnected;
  }

  toJSON() {
    return {...this, printService: null, printerService: null, usbDriver: null, encoder: null, subscription: null};
  }

}
