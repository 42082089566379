import { Printer } from '../printer';
import {
  WebSocketPrint,
  setLocalAddress,
  setConnectionMode,
  resetWsConnection,
  getServerURL
} from '../services/bixolon-web-printing-service/bxlcommon';
import {
  setPosId,
  checkPrinterStatus,
  printText,
  getPosData,
  cutPaper,
  setInternationalCharset,
  setCharacterset
} from '../services/bixolon-web-printing-service/bxlpos';
import { Line, PrintingData } from '../printing-data';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { PrinterService } from '../../services/printer.service';

export class BixolonWebPrinter extends Printer {

  static readonly PRINTER_TYPE = 'bixolon_web_printer';

  private wsPrint: WebSocketPrint;

  private static issueID: number = 1;

  private static lastAlignment: number = 0;

  private errorConnectingToPrinter: boolean = false;

  private timesReconnected: number = 0;

  constructor(printerService?: PrinterService, printer?) {
    super(printerService, printer);

    this.ipAddress = printer?.ipAddress ? printer?.ipAddress : '127.0.0.1';
    this.port = printer?.port ? printer?.port : 18080;
    this.printerType = BixolonWebPrinter.PRINTER_TYPE;

    if (this.printerService) {
      this.connect().catch(error => {
        if (this.printerService) {
          this.printerService.showErrorMessage(this.deviceName, error.message);
        }
      });
    }
  }

  async canConnect(connectionOpenedCallback: void, connectionClosedCallback: void, printingResult: void): Promise<void> {
    const strSubmit = getPosData();
    BixolonWebPrinter.issueID++;

    setLocalAddress(this.ipAddress, this.port);

    this.wsPrint = new WebSocketPrint(getServerURL().url, this.logicalName, '', printingResult, connectionOpenedCallback, connectionClosedCallback);

    this.wsPrint.send(strSubmit);

    resetWsConnection();
  }

  async connect(): Promise<void> {
    await this.canConnect(this.onConnectionOpened.bind(this), this.onConnectionClosed.bind(this), this.printingResult.bind(this));
  }

  async printData(): Promise<void> {

    if (!this.printQueue) {
      this.currentlyPrinting = false;
      return;
    }

    let data = this.printQueue.shift();

    if (!data) {
      this.currentlyPrinting = false;
      return;
    }

    setPosId(BixolonWebPrinter.issueID);
    checkPrinterStatus();

    setCharacterset(1252);

    this.transformPrintData(data);

    cutPaper(1);

    const strSubmit = getPosData();
    BixolonWebPrinter.issueID++;

    setLocalAddress(this.ipAddress, this.port);

    this.wsPrint = new WebSocketPrint(getServerURL().url, this.logicalName, '', this.printingResult.bind(this), this.onConnectionOpened.bind(this), this.onConnectionClosed.bind(this), data.callback);

    this.wsPrint.send(strSubmit);

    this.printData();
  }

  protected transformPrintData(data: PrintingData): void {
    data.getLines.forEach(line => {
      printText(line.getText() + '\n', line.size - 1, line.size - 1, line.bold, false, line.underlined, 0, BixolonWebPrinter.getLineAlignment(line.alignment));
    });
    for (let i = 0; i < data.getEndFeed / 2; i++) {
      printText('\n', 0, 0, false, false, false, 0, 0);
    }
  }

  printingResult(result, callback): void {
    switch (result) {
      case 'undefined:not found match device':
        resetWsConnection();
        this.changeConnectionStatus(false);
        throw new Error(_('Cannot find a printer with given logical name.'));
      default:
        if (result.includes('success')) {
          this.changeConnectionStatus(true);
          if (callback) {
            callback();
          }
          return;
        }
        if (result.includes('error')) {
          resetWsConnection();
          this.changeConnectionStatus(false);
          this.errorConnectingToPrinter = true;
          if (this.printerService) {
            this.printerService.showErrorMessage(this.deviceName, _('Error connecting to printer.'));
          }
        }
    }
  }

  private onConnectionOpened(): void {
    this.connected = true;
    this.errorConnectingToPrinter = false;
    this.timesReconnected = 0;
  }

  private onConnectionClosed(): void {
    if (!this.wsPrint.isClosed()) {
      return;
    }

    if (!this.errorConnectingToPrinter && this.timesReconnected == 1 && this.printerService) {
      this.printerService.showErrorMessage(this.deviceName, _('Cannot connect to the Web Print app. Please check if the app is running.'));
    }

    if (this.timesReconnected == 1 && this.connected) {
      this.changeConnectionStatus(false);
    }

    if (this.timesReconnected < 2) {
      this.timesReconnected++;
    }

    if (this.wsPrint) {
      this.wsPrint.reconnect();
    }
  }

  private static getLineAlignment(alignment: string): number {
    switch (alignment) {
      case Line.LINE_ALIGNMENT_LEFT:
        this.lastAlignment = 0;
        return 0;
      case Line.LINE_ALIGNMENT_CENTER:
        this.lastAlignment = 1;
        return 1;
      case Line.LINE_ALIGNMENT_RIGHT:
        this.lastAlignment = 2;
        return 2;
      default:
        return this.lastAlignment;
    }
  }

  get deviceName(): string {
    return this.logicalName;
  }

}
