import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService } from '../_services/auth-http';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private authData: AuthModel;

  constructor(
    private authHttpService: AuthHTTPService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let authReq = req;
    this.authData = AuthModel.getAuthFromLocalStorage();

    const token = this.authData?.authToken;
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }

    return next.handle(authReq).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && !authReq.url.includes('/token') && (error.status == 401 || error.status == 403)) {
        return this.handle401Error(authReq, next);
      }

      return throwError(error);
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.authData.refreshToken;
      if (token) {
        return this.authHttpService.refreshToken(token).pipe(
          switchMap((auth: AuthModel) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(auth.authToken);
            AuthModel.setAuthToLocalStorage(auth);
            return next.handle(this.addTokenHeader(request, auth.authToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            AuthModel.logout();
            return throwError(err);
          })
        );
      }

    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token)});
  }
}
