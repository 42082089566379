<!--begin::Signin-->
<div class="login-form login-signin">
  <!--begin::Form-->
  <form
          class="form"
          [formGroup]="passwordResetForm"
          novalidate="novalidate"
          id="kt_login_signin_form"
          (ngSubmit)="submit()">

    <!--begin::Form group-->
    <div class="form-group">
      <div class="d-flex justify-content-between mt-n5">
        <label class="font-size-h6 font-weight-bolder text-dark pt-5">
          {{'Password' | translate}}
        </label>
      </div>

      <input
              class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
              type="password"
              name="password"
              autocomplete="off"
              formControlName="password"/>
      <ng-container
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'Password is required' | translate,
                    control: passwordResetForm.controls['password']
                }">
      </ng-container>
      <ng-container
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                    validation: 'minlength',
                    message: 'Password should have at least 3 symbols' | translate,
                    control: passwordResetForm.controls['password']
                }">
      </ng-container>
      <ng-container
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                    validation: 'maxLength',
                    message: 'Password should have a maximum of 360 symbols' | translate,
                    control: passwordResetForm.controls['password']
                }">
      </ng-container>
    </div>
    <!--end::Form group-->

    <!--begin::Form group-->
    <div class="form-group">
      <div class="d-flex justify-content-between mt-n5">
        <label class="font-size-h6 font-weight-bolder text-dark pt-5">
          {{'Confirm Password' | translate}}
        </label>
      </div>

      <input
              class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
              type="password"
              name="password"
              autocomplete="off"
              formControlName="passwordRepeat"/>
      <ng-container
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'Confirm Password is required' | translate,
                    control: passwordResetForm.controls['passwordRepeat']
                }">
      </ng-container>
      <ng-container
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                    validation: 'minlength',
                    message: 'Confirm Password should have at least 3 symbols' | translate,
                    control: passwordResetForm.controls['passwordRepeat']
                }">
      </ng-container>
      <ng-container
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                    validation: 'maxLength',
                    message: 'Confirm Password should have a maximum of 360 symbols' | translate,
                    control: passwordResetForm.controls['passwordRepeat']
                }">
      </ng-container>
    </div>
    <!--end::Form group-->

    <!--begin::Action-->
    <div class="pb-lg-0 pb-5">
      <button
              type="submit"
              id="kt_login_signin_submit"
              class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
              [disabled]="passwordResetForm.invalid">
        {{'Change Password' | translate}}
      </button>

      <ng-container *ngIf="isLoading$ | async">
        <span class="spinner spinner-primary ml-5"></span>
      </ng-container>
    </div>
    <!--end::Action-->
  </form>
  <!--end::Form-->
</div>
<!--end::Signin-->

<ng-template
        #formError
        let-control="control"
        let-message="message"
        let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && control.touched">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        {{ message }}
      </div>
    </div>
  </ng-container>
</ng-template>
