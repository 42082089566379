export class StringHelper {

  static toUnderscore(s: string) {
    return s.split(/(?=[A-Z])/).join('_').toLowerCase();
  }

  static toCamel(s: string) {
    return s.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
  }
}
