import { Component, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastManagementService } from '../../services/toast-management.service';
import { BixolonWebPrinter } from '../../printing/printers/bixolon.web.printer';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-add-bixolon-web-printer-modal',
  templateUrl: './add-bixolon-web-printer-modal.component.html',
  styleUrls: ['./add-bixolon-web-printer-modal.component.scss']
})
export class AddBixolonWebPrinterModalComponent implements OnInit {

  printer: BixolonWebPrinter;

  saved = new EventEmitter<BixolonWebPrinter>();

  form: FormGroup;

  isSaving = false;

  constructor(private toast: ToastManagementService, private translate: TranslateService) {
    this.printer = new BixolonWebPrinter();
  }

  ngOnInit(): void {
    this.initForm();
  }

  cancel() {
    this.saved.emit(null);
  }

  save() {
    if (this.form.valid) {
      this.isSaving = true;

      this.printer = new BixolonWebPrinter(null, {
        logicalName: this.logicalName.value,
        ipAddress: this.ipAddress.value,
        port: this.port.value
      });

      this.printer.canConnect(this.connectedCallback.bind(this), this.errorCallback.bind(this), this.printingError.bind(this));
    }
  }

  private connectedCallback() {
    this.saved.emit(this.printer);
    this.isSaving = false;
  }

  private errorCallback() {
    this.isSaving = false;
    this.toast.showDanger(this.translateLabel(_('Cannot connect to the Web Print app. Please check if the app is running.')));
  }

  private printingError(result): void {
    switch (result) {
      case 'undefined:not found match device':
        this.toast.showDanger(this.translateLabel(_('Cannot find a printer with given logical name.')));
      default:
        if (result.includes('success')) {
          this.saved.emit(this.printer);
        }
        if (result.includes('error')) {
          this.toast.showDanger(this.translateLabel(_('Error connecting to printer.')));
        }
    }
    this.isSaving = false;
  }

  get logicalName(): AbstractControl {
    return this.form.get('logicalName');
  }

  get ipAddress(): AbstractControl {
    return this.form.get('ipAddress');
  }

  get port(): AbstractControl {
    return this.form.get('port');
  }

  private initForm(): void {
    this.form = new FormGroup({
      logicalName: new FormControl(this.printer.logicalName, Validators.required),
      ipAddress: new FormControl(this.printer.ipAddress, Validators.required),
      port: new FormControl(this.printer.port, [Validators.required, Validators.pattern('^[0-9]*$')]),
    });
  }

  translateLabel(value: string) {
    return this.translate.instant(value);
  }
}
