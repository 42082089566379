import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CompanyModel} from '../../models/company.model';
import {COMMISSION} from '../../globals';
import { AddressFormGroup } from '../address-form/address.form';

export class CompanyFormGroup extends FormGroup {
  constructor(company?: CompanyModel) {
    super({
      id: new FormControl(company ? company.id : 0),
      name: new FormControl(company ? company.name : '', Validators.required),
      address: new AddressFormGroup(company ? company.address : undefined),
      vatNumber: new FormControl(company ? company.vatNumber : '', Validators.required),
      commissionType: new FormControl({
        value: company ? company.commissionType : COMMISSION.DEFAULT_TYPE,
        disabled: company ? !company.hasCommission : true
      }),
      commissionAmount: new FormControl({
        value: company ? company.commissionAmount : COMMISSION.DEFAULT_AMOUNT,
        disabled: company ? !company.hasCommission : true
      }),
      hasCommission: new FormControl(company ? !!company.hasCommission : false),
      currency: new FormControl(company ? company.currency : '', Validators.required)
    });
  }
}
