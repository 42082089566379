import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const DynamicAsideMenuConfig = {
  items: [
    {
      title: 'Dashboard',
      root: true,
      icon: 'flaticon2-architecture-and-city',
      svg: './assets/media/svg/icons/Design/Layers.svg',
      page: '/dashboard',
      translate: _('Dashboard'),
      bullet: 'dot',
    },
    {
      title: 'Restaurants',
      root: true,
      icon: 'flaticon2-expand',
      page: '/restaurants',
      translate: _('Restaurants'),
      svg: './assets/media/svg/icons/Food/Dinner.svg'
    },
  ]
};
