export const environment = {
  production: false,
  appVersion: 'v726dinego',
  USERDATA_KEY: 'authf649fc9a5f55',
  apiUrl: 'https://api.dinego-dev.singularity.is/v1',
  adminUrl: 'https://admin.dinego-dev.singularity.is',
  frontendUrl: 'https://dinego-dev.singularity.is',
  clientId: 'dinego-dev',
  clientSecret: 'TbXwsSpr5EkLbddl4TcSCZr3qucQoti4',
  googleApiKey: 'AIzaSyA5Z9XRAAscVgNwMyAU3waS11O4o2y1nhc',
  appLanguage: 'de',
  appCurrency: 'CHF',
  appLogo: '/assets/media/logos/logo-light.svg'
};
