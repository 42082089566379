import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';

export const DINEGO_INFO = {
    companyName: 'Dinego GmbH',
    address: 'Pauline-Stoffel-Weg 10, 9320 Arbon',
    vatNumber: 'CHE-376.201.905'
};

export const API = {
    USERS_URL: `/users`
};

export const MOBILE_BREAKPOINT = 768;

export const COUNTRIES = [
    {name: 'Afghanistan', code: 'AF'},
    {name: 'Åland Islands', code: 'AX'},
    {name: 'Albania', code: 'AL'},
    {name: 'Algeria', code: 'DZ'},
    {name: 'American Samoa', code: 'AS'},
    {name: 'AndorrA', code: 'AD'},
    {name: 'Angola', code: 'AO'},
    {name: 'Anguilla', code: 'AI'},
    {name: 'Antarctica', code: 'AQ'},
    {name: 'Antigua and Barbuda', code: 'AG'},
    {name: 'Argentina', code: 'AR'},
    {name: 'Armenia', code: 'AM'},
    {name: 'Aruba', code: 'AW'},
    {name: 'Australia', code: 'AU'},
    {name: 'Austria', code: 'AT'},
    {name: 'Azerbaijan', code: 'AZ'},
    {name: 'Bahamas', code: 'BS'},
    {name: 'Bahrain', code: 'BH'},
    {name: 'Bangladesh', code: 'BD'},
    {name: 'Barbados', code: 'BB'},
    {name: 'Belarus', code: 'BY'},
    {name: 'Belgium', code: 'BE'},
    {name: 'Belize', code: 'BZ'},
    {name: 'Benin', code: 'BJ'},
    {name: 'Bermuda', code: 'BM'},
    {name: 'Bhutan', code: 'BT'},
    {name: 'Bolivia', code: 'BO'},
    {name: 'Bosnia and Herzegovina', code: 'BA'},
    {name: 'Botswana', code: 'BW'},
    {name: 'Bouvet Island', code: 'BV'},
    {name: 'Brazil', code: 'BR'},
    {name: 'British Indian Ocean Territory', code: 'IO'},
    {name: 'Brunei Darussalam', code: 'BN'},
    {name: 'Bulgaria', code: 'BG'},
    {name: 'Burkina Faso', code: 'BF'},
    {name: 'Burundi', code: 'BI'},
    {name: 'Cambodia', code: 'KH'},
    {name: 'Cameroon', code: 'CM'},
    {name: 'Canada', code: 'CA'},
    {name: 'Cape Verde', code: 'CV'},
    {name: 'Cayman Islands', code: 'KY'},
    {name: 'Central African Republic', code: 'CF'},
    {name: 'Chad', code: 'TD'},
    {name: 'Chile', code: 'CL'},
    {name: 'China', code: 'CN'},
    {name: 'Christmas Island', code: 'CX'},
    {name: 'Cocos (Keeling) Islands', code: 'CC'},
    {name: 'Colombia', code: 'CO'},
    {name: 'Comoros', code: 'KM'},
    {name: 'Congo', code: 'CG'},
    {name: 'Congo, The Democratic Republic of the', code: 'CD'},
    {name: 'Cook Islands', code: 'CK'},
    {name: 'Costa Rica', code: 'CR'},
    {name: 'Cote D\'Ivoire', code: 'CI'},
    {name: 'Croatia', code: 'HR'},
    {name: 'Cuba', code: 'CU'},
    {name: 'Cyprus', code: 'CY'},
    {name: 'Czech Republic', code: 'CZ'},
    {name: 'Denmark', code: 'DK'},
    {name: 'Djibouti', code: 'DJ'},
    {name: 'Dominica', code: 'DM'},
    {name: 'Dominican Republic', code: 'DO'},
    {name: 'Ecuador', code: 'EC'},
    {name: 'Egypt', code: 'EG'},
    {name: 'El Salvador', code: 'SV'},
    {name: 'Equatorial Guinea', code: 'GQ'},
    {name: 'Eritrea', code: 'ER'},
    {name: 'Estonia', code: 'EE'},
    {name: 'Ethiopia', code: 'ET'},
    {name: 'Falkland Islands (Malvinas)', code: 'FK'},
    {name: 'Faroe Islands', code: 'FO'},
    {name: 'Fiji', code: 'FJ'},
    {name: 'Finland', code: 'FI'},
    {name: 'France', code: 'FR'},
    {name: 'French Guiana', code: 'GF'},
    {name: 'French Polynesia', code: 'PF'},
    {name: 'French Southern Territories', code: 'TF'},
    {name: 'Gabon', code: 'GA'},
    {name: 'Gambia', code: 'GM'},
    {name: 'Georgia', code: 'GE'},
    {name: 'Germany', code: 'DE'},
    {name: 'Ghana', code: 'GH'},
    {name: 'Gibraltar', code: 'GI'},
    {name: 'Greece', code: 'GR'},
    {name: 'Greenland', code: 'GL'},
    {name: 'Grenada', code: 'GD'},
    {name: 'Guadeloupe', code: 'GP'},
    {name: 'Guam', code: 'GU'},
    {name: 'Guatemala', code: 'GT'},
    {name: 'Guernsey', code: 'GG'},
    {name: 'Guinea', code: 'GN'},
    {name: 'Guinea-Bissau', code: 'GW'},
    {name: 'Guyana', code: 'GY'},
    {name: 'Haiti', code: 'HT'},
    {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
    {name: 'Holy See (Vatican City State)', code: 'VA'},
    {name: 'Honduras', code: 'HN'},
    {name: 'Hong Kong', code: 'HK'},
    {name: 'Hungary', code: 'HU'},
    {name: 'Iceland', code: 'IS'},
    {name: 'India', code: 'IN'},
    {name: 'Indonesia', code: 'ID'},
    {name: 'Iran, Islamic Republic Of', code: 'IR'},
    {name: 'Iraq', code: 'IQ'},
    {name: 'Ireland', code: 'IE'},
    {name: 'Isle of Man', code: 'IM'},
    {name: 'Israel', code: 'IL'},
    {name: 'Italy', code: 'IT'},
    {name: 'Jamaica', code: 'JM'},
    {name: 'Japan', code: 'JP'},
    {name: 'Jersey', code: 'JE'},
    {name: 'Jordan', code: 'JO'},
    {name: 'Kazakhstan', code: 'KZ'},
    {name: 'Kenya', code: 'KE'},
    {name: 'Kiribati', code: 'KI'},
    {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
    {name: 'Korea, Republic of', code: 'KR'},
    {name: 'Kuwait', code: 'KW'},
    {name: 'Kyrgyzstan', code: 'KG'},
    {name: 'Lao People\'S Democratic Republic', code: 'LA'},
    {name: 'Latvia', code: 'LV'},
    {name: 'Lebanon', code: 'LB'},
    {name: 'Lesotho', code: 'LS'},
    {name: 'Liberia', code: 'LR'},
    {name: 'Libyan Arab Jamahiriya', code: 'LY'},
    {name: 'Liechtenstein', code: 'LI'},
    {name: 'Lithuania', code: 'LT'},
    {name: 'Luxembourg', code: 'LU'},
    {name: 'Macao', code: 'MO'},
    {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
    {name: 'Madagascar', code: 'MG'},
    {name: 'Malawi', code: 'MW'},
    {name: 'Malaysia', code: 'MY'},
    {name: 'Maldives', code: 'MV'},
    {name: 'Mali', code: 'ML'},
    {name: 'Malta', code: 'MT'},
    {name: 'Marshall Islands', code: 'MH'},
    {name: 'Martinique', code: 'MQ'},
    {name: 'Mauritania', code: 'MR'},
    {name: 'Mauritius', code: 'MU'},
    {name: 'Mayotte', code: 'YT'},
    {name: 'Mexico', code: 'MX'},
    {name: 'Micronesia, Federated States of', code: 'FM'},
    {name: 'Moldova, Republic of', code: 'MD'},
    {name: 'Monaco', code: 'MC'},
    {name: 'Mongolia', code: 'MN'},
    {name: 'Montenegro', code: 'ME'},
    {name: 'Montserrat', code: 'MS'},
    {name: 'Morocco', code: 'MA'},
    {name: 'Mozambique', code: 'MZ'},
    {name: 'Myanmar', code: 'MM'},
    {name: 'Namibia', code: 'NA'},
    {name: 'Nauru', code: 'NR'},
    {name: 'Nepal', code: 'NP'},
    {name: 'Netherlands', code: 'NL'},
    {name: 'Netherlands Antilles', code: 'AN'},
    {name: 'New Caledonia', code: 'NC'},
    {name: 'New Zealand', code: 'NZ'},
    {name: 'Nicaragua', code: 'NI'},
    {name: 'Niger', code: 'NE'},
    {name: 'Nigeria', code: 'NG'},
    {name: 'Niue', code: 'NU'},
    {name: 'Norfolk Island', code: 'NF'},
    {name: 'Northern Mariana Islands', code: 'MP'},
    {name: 'Norway', code: 'NO'},
    {name: 'Oman', code: 'OM'},
    {name: 'Pakistan', code: 'PK'},
    {name: 'Palau', code: 'PW'},
    {name: 'Palestinian Territory, Occupied', code: 'PS'},
    {name: 'Panama', code: 'PA'},
    {name: 'Papua New Guinea', code: 'PG'},
    {name: 'Paraguay', code: 'PY'},
    {name: 'Peru', code: 'PE'},
    {name: 'Philippines', code: 'PH'},
    {name: 'Pitcairn', code: 'PN'},
    {name: 'Poland', code: 'PL'},
    {name: 'Portugal', code: 'PT'},
    {name: 'Puerto Rico', code: 'PR'},
    {name: 'Qatar', code: 'QA'},
    {name: 'Reunion', code: 'RE'},
    {name: 'Romania', code: 'RO'},
    {name: 'Russian Federation', code: 'RU'},
    {name: 'RWANDA', code: 'RW'},
    {name: 'Saint Helena', code: 'SH'},
    {name: 'Saint Kitts and Nevis', code: 'KN'},
    {name: 'Saint Lucia', code: 'LC'},
    {name: 'Saint Pierre and Miquelon', code: 'PM'},
    {name: 'Saint Vincent and the Grenadines', code: 'VC'},
    {name: 'Samoa', code: 'WS'},
    {name: 'San Marino', code: 'SM'},
    {name: 'Sao Tome and Principe', code: 'ST'},
    {name: 'Saudi Arabia', code: 'SA'},
    {name: 'Senegal', code: 'SN'},
    {name: 'Serbia and Montenegro', code: 'CS'},
    {name: 'Seychelles', code: 'SC'},
    {name: 'Sierra Leone', code: 'SL'},
    {name: 'Singapore', code: 'SG'},
    {name: 'Slovakia', code: 'SK'},
    {name: 'Slovenia', code: 'SI'},
    {name: 'Solomon Islands', code: 'SB'},
    {name: 'Somalia', code: 'SO'},
    {name: 'South Africa', code: 'ZA'},
    {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
    {name: 'Spain', code: 'ES'},
    {name: 'Sri Lanka', code: 'LK'},
    {name: 'Sudan', code: 'SD'},
    {name: 'Suriname', code: 'SR'},
    {name: 'Svalbard and Jan Mayen', code: 'SJ'},
    {name: 'Swaziland', code: 'SZ'},
    {name: 'Sweden', code: 'SE'},
    {name: 'Switzerland', code: 'CH'},
    {name: 'Syrian Arab Republic', code: 'SY'},
    {name: 'Taiwan, Province of China', code: 'TW'},
    {name: 'Tajikistan', code: 'TJ'},
    {name: 'Tanzania, United Republic of', code: 'TZ'},
    {name: 'Thailand', code: 'TH'},
    {name: 'Timor-Leste', code: 'TL'},
    {name: 'Togo', code: 'TG'},
    {name: 'Tokelau', code: 'TK'},
    {name: 'Tonga', code: 'TO'},
    {name: 'Trinidad and Tobago', code: 'TT'},
    {name: 'Tunisia', code: 'TN'},
    {name: 'Turkey', code: 'TR'},
    {name: 'Turkmenistan', code: 'TM'},
    {name: 'Turks and Caicos Islands', code: 'TC'},
    {name: 'Tuvalu', code: 'TV'},
    {name: 'Uganda', code: 'UG'},
    {name: 'Ukraine', code: 'UA'},
    {name: 'United Arab Emirates', code: 'AE'},
    {name: 'United Kingdom', code: 'GB'},
    {name: 'United States', code: 'US'},
    {name: 'United States Minor Outlying Islands', code: 'UM'},
    {name: 'Uruguay', code: 'UY'},
    {name: 'Uzbekistan', code: 'UZ'},
    {name: 'Vanuatu', code: 'VU'},
    {name: 'Venezuela', code: 'VE'},
    {name: 'Viet Nam', code: 'VN'},
    {name: 'Virgin Islands, British', code: 'VG'},
    {name: 'Virgin Islands, U.S.', code: 'VI'},
    {name: 'Wallis and Futuna', code: 'WF'},
    {name: 'Western Sahara', code: 'EH'},
    {name: 'Yemen', code: 'YE'},
    {name: 'Zambia', code: 'ZM'},
    {name: 'Zimbabwe', code: 'ZW'}
];

export const COMMISSION_TYPES = {
    percentage: 'percentage',
    perOrder: 'per-order',
    fixed: 'fixed',
    monthly: 'monthly',
    yearly: 'yearly'
};

export const COMMISSION = {
    TYPES: [
        {
            key: _('Percentage'),
            value: COMMISSION_TYPES.percentage,
            color: 'primary'
        },
        {
            key: _('Per order'),
            value: COMMISSION_TYPES.perOrder,
            color: 'info'
        },
        {
            key: _('Fixed'),
            value: COMMISSION_TYPES.fixed,
            color: 'success'
        },
        {
            key: _('Monthly'),
            value: COMMISSION_TYPES.monthly,
            color: 'warning'
        },
        {
            key: _('Yearly'),
            value: COMMISSION_TYPES.yearly,
            color: 'warn'
        },
    ],
    DEFAULT_TYPE: 'percentage',
    DEFAULT_AMOUNT: 2.00
};

export const COUPON_TYPES = {
    percentage: 'percentage',
    amount: 'amount'
};

export const COUPON = {
    TYPES: [
        {
            key: COUPON_TYPES.percentage,
            value: _('Percentage'),
            color: 'primary'
        },
        {
            key: COUPON_TYPES.amount,
            value: _('Amount'),
            color: 'success'
        }
    ]
};

export const PAYMENT_METHOD = {
    cash: 'cash',
    creditCard: 'credit-card',
    applePay: 'apple-pay',
    googlePay: 'google-pay',
    twint: 'twint',
};

export const PAYMENT_METHODS = [
    {
        value: _('Cash'),
        key: PAYMENT_METHOD.cash,
        color: 'success',
        icon: 'fal fa-wallet',
        largeIcon: 'fal fa-wallet'
    },
    {
        value: _('Credit Card'),
        key: PAYMENT_METHOD.creditCard,
        color: 'primary',
        icon: 'fal fa-credit-card',
        largeIcon: 'fal fa-credit-card'
    },
    {
        value: _('Apple pay'),
        key: PAYMENT_METHOD.applePay,
        color: 'info',
        icon: 'fab fa-apple-pay',
        largeIcon: 'fab fa-apple'
    },
    {
        value: _('Google pay'),
        key: PAYMENT_METHOD.googlePay,
        color: 'warning',
        icon: 'fab fa-google-pay',
        largeIcon: 'fab fa-google'
    },
    {
        value: _('Twint'),
        key: PAYMENT_METHOD.twint,
        color: 'danger',
        icon: 'fal fa-credit-card',
        largeIcon: 'fal fa-credit-card'
    }
];

export const RATING_VALUES = [ 
    {
        value: _('Terrible'),
        key: 1
    },
    {
        value: _('Bad'),
        key: 2
    },
    {
        value: _('Meh'),
        key: 3
    },
    {
        value: _('Good'),
        key: 4
    },
    {
        value: _('Awesome'),
        key: 5
    }
]

export const PRODUCT_TYPE = {
    food: 'food',
    beverage: 'beverage'
};

export const PRODUCT_TYPES = [
    {
        value: 'food',
        key: _('Food'),
    },
    {
        value: 'beverage',
        key: _('Beverage'),
    },
];

export const ORDER_TYPE = {
    delivery: 'delivery',
    takeaway: 'takeaway',
    inRestaurant: 'in-restaurant'
};

export const ORDER_TYPES = [
    {
        value: _('Delivery'),
        key: ORDER_TYPE.delivery,
        control: 'hasDelivery',
        color: 'warning',
        icon: 'fa-motorcycle',
        colorCode: '#FFA800'
    },
    {
        value: _('Takeaway'),
        key: ORDER_TYPE.takeaway,
        control: 'hasTakeaway',
        color: 'info',
        icon: 'fa-shopping-bag',
        colorCode: '#8950FC'
    },
    {
        value: _('Dine-In'),
        key: ORDER_TYPE.inRestaurant,
        control: 'hasInRestaurant',
        color: 'primary',
        icon: 'fa-utensils-alt',
        colorCode: '#3699FF'
    }
];

export const ROLE = {
    admin: 'admin',
    manager: 'manager',
    customer: 'customer',
    staff: 'staff'
};

export const ROLES = [
    {
        key: _('Admin'),
        value: ROLE.admin
    },
    {
        key: _('Manager'),
        value: ROLE.manager
    },
    {
        key: _('Staff'),
        value: ROLE.staff
    },
    {
        key: _('Customer'),
        value: ROLE.customer
    },
];

export const WEEK_DAYS = [
    {
        key: _('Monday'),
        value: 'monday'
    },
    {
        key: _('Tuesday'),
        value: 'tuesday'
    },
    {
        key: _('Wednesday'),
        value: 'wednesday'
    },
    {
        key: _('Thursday'),
        value: 'thursday'
    },
    {
        key: _('Friday'),
        value: 'friday'
    },
    {
        key: _('Saturday'),
        value: 'saturday'
    },
    {
        key: _('Sunday'),
        value: 'sunday'
    },
];

export const RESTAURANT_NAV = [
    {
        link: '/info',
        icon: 'fal fa-info-circle',
        text: _('Base Information')
    },
    {
        link: '/users',
        icon: 'fal fa-users',
        text: _('Users')
    },
    {
        link: '/working-hours',
        icon: 'fal fa-history',
        text: _('Working Hours')
    },
    {
        link: '/holidays',
        icon: 'fal fa-calendar-alt',
        text: _('Holidays')
    },
    {
        link: '/ordering-types',
        icon: 'fal fa-box',
        text: _('Ordering Types')
    },
    {
        link: '/delivery-area',
        icon: 'fal fa-map-marked',
        text: _('Delivery Area')
    },
    {
        link: '/declaration',
        icon: 'fal fa-receipt',
        text: _('Declaration')
    }
];

export const ORDER_ITEM_STATUS = {
    pending: 'pending',
    accepted: 'accepted',
    ready: 'ready',
    finished: 'finished',
    canceled: 'canceled',
    posCart: 'pos-cart'
};

export const ORDER_STATUS = {
    pending: 'pending',
    accepted: 'accepted',
    preparing: 'preparing',
    ready: 'ready',
    finished: 'finished',
    canceled: 'canceled'
};

export const PAYMENT_STATUS = {
    pending: 'pending',
    captured: 'captured',
    paid: 'paid',
    refunded: 'refunded',
    created: 'created',
    canceled: 'canceled',
    failed: 'failed',
    authorised: 'authorised',
    settled: 'settled'
};

export const PAYMENT_STATUSES = [
    {
        value: _('Created'),
        key: PAYMENT_STATUS.created,
        color: 'light'
    },
    {
        value: _('Pending'),
        key: PAYMENT_STATUS.pending,
        color: 'primary'
    },
    {
        value: _('Authorised'),
        key: PAYMENT_STATUS.authorised,
        color: 'primary'
    },
    {
        value: _('Captured'),
        key: PAYMENT_STATUS.captured,
        color: 'success'
    },
    {
        value: _('Settled'),
        key: PAYMENT_STATUS.settled,
        color: 'success'
    },
    {
        value: _('Canceled'),
        key: PAYMENT_STATUS.canceled,
        color: 'warning'
    },
    {
        value: _('Refunded'),
        key: PAYMENT_STATUS.refunded,
        color: 'danger'
    },
    {
        value: _('Failed'),
        key: PAYMENT_STATUS.failed,
        color: 'danger'
    }
];

export const ORDER_STATUSES = [
    {
        value: _('Pending'),
        key: ORDER_STATUS.pending,
        color: 'gray'
    },
    {
        value: _('Accepted'),
        key: ORDER_STATUS.accepted,
        color: 'light'
    },
    {
        value: _('Ready'),
        key: ORDER_STATUS.ready,
        color: 'info'
    },
    {
        value: _('Finished'),
        key: ORDER_STATUS.finished,
        color: 'success'
    },
    {
        value: _('Canceled'),
        key: ORDER_STATUS.canceled,
        color: 'danger'
    }
];

export const CARD_BRAND = {
    mastercard: 'Mastercard',
    mastercardCaps: 'MASTERCARD',
    mastercardDebit: 'MASTERCARD DEBIT',
    mastercardCredit: 'MCI CREDIT',
    visa: 'VISA',
    visaDebit: 'VISA DEBIT',
    visaCredit: 'VISA CREDIT',
    dinersClub: 'DINERS CLUB',
    americanExpress: 'AMERICAN EXPRESS',
    discover: 'DISCOVER',
    jcb: 'JCB',
    maestro: 'MAESTRO',
};

export const CARD_BRANDS = [
    {
        key: 'mastercard-alt',
        value: CARD_BRAND.mastercard
    }, {
        key: 'mastercard-alt',
        value: CARD_BRAND.mastercardCaps
    },
    {
        key: 'mastercard-alt',
        value: CARD_BRAND.mastercardDebit
    },
    {
        key: 'mastercard-alt',
        value: CARD_BRAND.mastercardCredit
    },
    {
        key: 'visa',
        value: CARD_BRAND.visa
    },
    {
        key: 'visa',
        value: CARD_BRAND.visaDebit
    },
    {
        key: 'visa',
        value: CARD_BRAND.visaCredit
    },
    {
        key: 'diners-club',
        value: CARD_BRAND.dinersClub
    },
    {
        key: 'american-express',
        value: CARD_BRAND.americanExpress
    },
    {
        key: 'discover',
        value: CARD_BRAND.discover
    },
    {
        key: 'jcb',
        value: CARD_BRAND.jcb
    },
    {
        key: 'maestro',
        value: CARD_BRAND.maestro
    }
];

export const KITCHEN = {
    KITCHEN_DELIVER_TO_HOURS: 12,
    KITCHEN_REFRESH_INTERVAL_MS: 30000,
    KITCHEN_REMOVE_OLD_PRINTED_RECEIPTS_INTERVAL_MS: 24 * 3600 * 1000
};

export const REPORTS = {
    REPORTS_REFRESH_INTERVAL_MS: 30000
};

export const AVAILABILITY_DAY = {
    mon: 'onMon',
    tue: 'onTue',
    wed: 'onWed',
    thu: 'onThu',
    fri: 'onFri',
    sat: 'onSat',
    sun: 'onSun',
};

export const AVAILABILITY_DAYS = [
    {
        key: AVAILABILITY_DAY.mon,
        value: _('Mon')
    },
    {
        key: AVAILABILITY_DAY.tue,
        value: _('Tue')
    },
    {
        key: AVAILABILITY_DAY.wed,
        value: _('Wed')
    },
    {
        key: AVAILABILITY_DAY.thu,
        value: _('Thu')
    },
    {
        key: AVAILABILITY_DAY.fri,
        value: _('Fri')
    },
    {
        key: AVAILABILITY_DAY.sat,
        value: _('Sat')
    },
    {
        key: AVAILABILITY_DAY.sun,
        value: _('Sun')
    },
];

export const CANCELLATION_REASONS = [
    _('Order running late'),
    _('Wrong order sent to customer'),
    _('Some ordered items not available at the moment'),
    _('Other')
];

export const LANGUAGES = [
    {
        lang: 'en',
        name: 'English',
        flag: './assets/media/svg/flags/012-uk.svg',
    },
    {
        lang: 'de',
        name: 'Deutsch',
        flag: './assets/media/svg/flags/162-germany.svg',
    },
    {
        lang: 'fr',
        name: 'Français',
        flag: './assets/media/svg/flags/195-france.svg',
    },
    {
        lang: 'it',
        name: 'Italiano',
        flag: './assets/media/svg/flags/013-italy.svg',
    },
    {
        lang: 'sr',
        name: 'Srpski',
        flag: './assets/media/svg/flags/071-serbia.svg',
    },
];

export const CURRENCIES = [
    {
        code: 'CHF',
        value: 'CHF ',
        isPrefix: true,
        name: 'Switzerland Franc'
    },
    {
        code: 'USD',
        value: '$',
        isPrefix: true,
        name: 'United States Dollar'
    },
    {
        code: 'EUR',
        value: '€',
        isPrefix: false,
        name: 'Euro'
    },
    {
        code: 'BAM',
        value: 'KM',
        isPrefix: false,
        name: 'Bosnia and Herzegovina Convertible Mark'
    }
];

export const CATEGORIES_COLORS = [
    {
        key: 'primary',
        name: 'Primary'
    },
    {
        key: 'light-primary',
        name: 'Primary light'
    },
    {
        key: 'secondary',
        name: 'Secondary'
    },
    {
        key: 'light-secondary',
        name: 'Secondary Light'
    },
    {
        key: 'info',
        name: 'Info'
    },
    {
        key: 'light-info',
        textColor: 'info',
        name: 'Info light'
    },
    {
        key: 'warning',
        name: 'Warning'
    },
    {
        key: 'light-warning',
        name: 'Warning light'
    },
    {
        key: 'danger',
        name: 'Danger'
    },
    {
        key: 'light-danger',
        name: 'Danger light'
    },
    {
        key: 'success',
        name: 'Success'
    },
    {
        key: 'light-success',
        name: 'Success light'
    },
    {
        key: 'light',
        name: 'Light'
    },
    {
        key: 'dark',
        name: 'Dark'
    },
    {
        key: "light-red",
        name: "Light red"
    },
    {
        key: "light-orange",
        name: "Light orange"
    },
    {
        key: "light-yellow",
        name: "Light yellow"
    },
    {
        key: "light-green",
        name: "Light green"
    },
    {
        key: "light-teal",
        name: "Light teal"
    },
    {
        key: "light-blue",
        name: "Light blue"
    },
    {
        key: "light-purple",
        name: "Light purple"
    },
    {
        key: "light-magenta",
        name: "Light magenta"
    },
    {
        key: "light-brown",
        name: "Light brown"
    },
    {
        key: "light-gray",
        name: "Light gray"
    },
    {
        key: "light-orange-red",
        name: "Light orange-red"
    },
    {
        key: "light-tomato",
        name: "Light tomato"
    },
    {
        key: "light-gold",
        name: "Light gold"
    },
    {
        key: "light-yellow-green",
        name: "Light yellow-green"
    },
    {
        key: "light-olive",
        name: "Light olive"
    },
    {
        key: "light-cyan",
        name: "Light cyan"
    },
    {
        key: "light-plum",
        name: "Light plum"
    },
    {
        key: "light-lavender",
        name: "Light lavender"
    },
    {
        key: "light-pink",
        name: "Light pink"
    },
    {
        key: "light-dark-gray",
        name: "Light dark-gray"
    },
    {
        key: "red",
        name: "red"
    },
	{
        key: "orange",
        name: "orange"
    },
	{
        key: "yellow",
        name: "yellow"
    },
	{
        key: "green",
        name: "green"
    },
	{
        key: "teal",
        name: "teal"
    },
	{
        key: "blue",
        name: "blue"
    },
	{
        key: "purple",
        name: "purple"
    },
	{
        key: "magenta",
        name: "magenta"
    },
	{
        key: "brown",
        name: "brown"
    },
	{
        key: "gray",
        name: "gray"
    },
	{
        key: "orange-red",
        name: "orange-red"
    },
	{
        key: "tomato",
        name: "tomato"
    },
	{
        key: "gold",
        name: "gold"
    },
	{
        key: "yellow-green",
        name: "yellow-green"
    },
	{
        key: "olive",
        name: "olive"
    },
	{
        key: "cyan",
        name: "cyan"
    },
	{
        key: "plum",
        name: "plum"
    },
	{
        key: "lavender",
        name: "lavender"
    },
	{
        key: "pink",
        name: "pink"
    },
	{
        key: "dark-gray",
        name: "dark-gray"
    },
];

export const CURRENCY_CACHE_KEY = 'currency';

export const FLOORPLAN_SVG_SHAPES = [
    {
        name: 'stool', filepath: './assets/floorplan-shapes/stool.svg',
    },
    {
        name: 'table_circle_4', filepath: './assets/floorplan-shapes/table_circle_4.svg'
    },
    {
        name: 'table_circle_5', filepath: './assets/floorplan-shapes/table_circle_5.svg'
    },
    {
        name: 'table_circle_8', filepath: './assets/floorplan-shapes/table_circle_8.svg'
    },
    {
        name: 'table_square_1', filepath: './assets/floorplan-shapes/table_square_1.svg'
    },
    {
        name: 'table_square_2', filepath: './assets/floorplan-shapes/table_square_2.svg'
    },
    {
        name: 'table_square_3', filepath: './assets/floorplan-shapes/table_square_3.svg'
    },
    {
        name: 'table_square_4', filepath: './assets/floorplan-shapes/table_square_4.svg'
    },
    {
        name: 'table_rectangle_4', filepath: './assets/floorplan-shapes/table_rectangle_4.svg'
    },
    {
        name: 'table_rectangle_5', filepath: './assets/floorplan-shapes/table_rectangle_5.svg'
    },
    {
        name: 'table_rectangle_6', filepath: './assets/floorplan-shapes/table_rectangle_6.svg'
    },
    {
        name: 'rectangle_6v2', filepath: './assets/floorplan-shapes/table_rectangle_6v2.svg'
    },
    {
        name: 'table_rectangle_7', filepath: './assets/floorplan-shapes/table_rectangle_7.svg'
    },
    {
        name: 'table_rectangle_8', filepath: './assets/floorplan-shapes/table_rectangle_8.svg'
    },
    {
        name: 'table_rectangle_9', filepath: './assets/floorplan-shapes/table_rectangle_9.svg'
    },
    {
        name: 'table_rectangle_8v2', filepath: './assets/floorplan-shapes/table_rectangle_8v2.svg'
    },
    {
        name: 'table_rectangle_10', filepath: './assets/floorplan-shapes/table_rectangle_10.svg'
    }
];
