import { Component, EventEmitter, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CANCELLATION_REASONS } from '../../globals';

@Component({
  selector: 'app-cancel-dialog',
  templateUrl: './cancel-payment-dialog.component.html',
  styleUrls: ['./cancel-payment-dialog.component.scss']
})
export class CancelPaymentDialogComponent implements OnInit {

  readonly REASON_OTHER = _('Other');

  title: string;
  message: string;

  cancelButtonText = _('Cancel');
  confirmButtonText = _('Confirm');

  accepted = new EventEmitter<boolean>();
  canceled = new EventEmitter<boolean>();

  reasons = CANCELLATION_REASONS;
  reason: string = null;
  customReason: string = null;


  modalRef: NgbModalRef;

  constructor() {
  }

  ngOnInit(): void {
  }

  accept(): void {
    if (this.modalRef) {
      this.modalRef.close();
    }

    if (this.reason == this.REASON_OTHER) {
      this.reason = this.customReason;
    }

    this.accepted.emit(true);
  }

  cancel(): void {
    if (this.modalRef) {
      this.modalRef.close();
    }

    this.canceled.emit(true);
  }

  setReason(reason: string) {
    this.reason = reason;
  }

  get isReasonEntered(): boolean {
    if(this.reason){
      return !(this.reason == this.REASON_OTHER && !this.customReason);
    }
    return false;
  }
}
