import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { PaginatorState } from "../../../_metronic/shared/crud-table";
import { QueryParamsInterface } from "../../interfaces/query-params.interface";

@Component({
  selector: "app-paginator",
  templateUrl: "./paginator.component.html",
  styleUrls: ["./paginator.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnInit, OnChanges {
  // @ts-ignore
  @Input() request: (
    queryParams?: QueryParamsInterface
  ) => Observable<HttpResponse<any[]>>;
  @Input() params: {};
  @Input() resetToFirstPage: boolean = true;
  @Input() sort_column: string;
  @Input() sort_order: "asc" | "desc";
  @Input() indicateLoading: boolean = true;

  @Output() data = new EventEmitter<any>();
  public totalPages = 0;

  public paginatorState = new PaginatorState();
  public isLoading = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.request) {
      return;
    }

    if (changes.params) {
      if (
        !changes.params.firstChange ||
        this.resetToFirstPage ||
        this.indicateLoading ||
        (changes.params.firstChange && changes.params.currentValue)
      ) {
        this.paginatorState.page = 1;
      }
    }

    if (this.request()) {
      this.makeRequestAndEmitData({
        "per-page": this.paginatorState.pageSize,
        page: this.paginatorState.page,
        sort_column: this.sort_column,
        sort_order: this.sort_order,
      });
    }
  }

  ngOnInit(): void {
    if (!this.request) {
      this.paginatorState.page = 1;
      this.paginatorState.pageSize = 10;

      return;
    }

    this.paginatorState.page = localStorage.getItem(
      this.request.name + "_page"
    ) as unknown as number;
    this.paginatorState.pageSize = localStorage.getItem(
      this.request.name + "_pageSize"
    ) as unknown as number;

    if (!this.paginatorState.page) {
      this.paginatorState.page = 1;
      this.paginatorState.pageSize = 10;
    }
  }

  public getPaginatorState(state: PaginatorState) {
    this.paginatorState = state;
    this.isLoading = true;
    this.makeRequestAndEmitData({
      "per-page": state.pageSize,
      page: state.page,
      sort_column: this.sort_column,
      sort_order: this.sort_order,
    });
  }

  private makeRequestAndEmitData(queryParams?: QueryParamsInterface): void {
    if (!this.request) {
      return;
    }

    if (this.indicateLoading) {
      this.isLoading = true;
    }

    queryParams = { ...queryParams, ...this.params };

    this.request(queryParams).subscribe((response: HttpResponse<any>) => {
      this.isLoading = false;

      if (response.body) {
        this.data.emit({ data: response.body });
        if (response.headers.get("X-Pagination-Total-Count")) {
          this.paginatorState.total = parseInt(
            <string>response.headers.get("X-Pagination-Total-Count"),
            10
          );
        }
        if (response.headers.get("X-Pagination-Current-Page")) {
          this.paginatorState.page = parseInt(
            <string>response.headers.get("X-Pagination-Current-Page"),
            10
          );
        }
        if (response.headers.get("X-Pagination-Page-Count")) {
          this.totalPages = parseInt(
            <string>response.headers.get("X-Pagination-Page-Count"),
            10
          );
        }
        if (response.headers.get("X-Pagination-Per-Page")) {
          this.paginatorState.pageSize = parseInt(
            <string>response.headers.get("X-Pagination-Per-Page"),
            10
          );
        }
        localStorage.setItem(
          this.request.name + "_page",
          this.paginatorState.page.toString()
        );
        localStorage.setItem(
          this.request.name + "_pageSize",
          this.paginatorState.pageSize.toString()
        );
      }
    });
  }

  public reload() {
    if (this.request()) {
      this.makeRequestAndEmitData({
        "per-page": this.paginatorState.pageSize,
        page: this.paginatorState.page,
        sort_column: this.sort_column,
        sort_order: this.sort_order,
      });
    }
  }
}
