import { BaseModel } from './base.model';

export class CouponModel extends BaseModel {
  id: number;
  name: string;
  code: string;
  value: number;
  type: string;
  paymentTypes: string;
  couponUrl: string;
  qrCodeUrl: string;
  isActive: boolean;

  getPaymentTypes(): string[] {
    return this.paymentTypes.split(',');
  }
}

