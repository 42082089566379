import { BaseModel } from './base.model';
import { UserModel } from './user.model';
import { AddressModel } from './address.model';
import { OrderItem } from './order.model';

export class TableModel extends BaseModel {
  id: number;
  name: string;
  code: string;
  location: string;
  seatingCount: number;
  waiter: UserModel;
  tableUrl: string;
  qrCodeUrl: string;
  isActive: number;

  get childModels(): {} {
    return {
      waiter: UserModel
    };
  }
}
