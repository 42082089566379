import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { COUNTRIES } from '../../globals';
import { Subscription } from 'rxjs';
import { AgmGeocoder } from '@agm/core';
import { AddressModel } from '../../models/address.model';
import { GoogleMapHelper } from '../../helpers/google-map-helper';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],

})
export class AddressFormComponent implements OnInit, OnDestroy {

  lat = 47.389690;
  lng = 8.538081;
  styles = [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{
        visibility: 'off'
      }]
    }
  ];
  countries = COUNTRIES;
  private subscriptions: Subscription[] = [];
  private pinMoved = false;

  @Input() defaultLocation: { lat: number, lng: number };

  constructor(
    public controlContainer: ControlContainer,
    private geocoder: AgmGeocoder
  ) {
  }

  get formGroup(): FormGroup {
    return (this.controlContainer.control as FormGroup);
  }

  choseLocation(e: any) {
    this.pinMoved = true;
    this.lat = e.latLng.lat();
    this.lng = e.latLng.lng();
    this.formGroup.controls.lat.setValue(this.lat);
    this.formGroup.controls.long.setValue(this.lng);
    this.getAddressFromCoordinates(this.lat, this.lng);
  }

  ngOnInit(): void {
    if (this.defaultLocation) {
      this.lat = this.defaultLocation.lat;
      this.lng = this.defaultLocation.lng;
    }
    this.geocodeAddress();
    for (const ctrl in this.formGroup.controls) {
      if (ctrl !== 'lat' && ctrl !== 'long') {
        const sub = this.formGroup.controls[ctrl].valueChanges.subscribe(value => {
          this.geocodeAddress();
        });
        this.subscriptions.push(sub);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private geocodeAddress(): void {
    if (this.formGroup.valid && !this.pinMoved) {
      this.geocoder.geocode({ address: new AddressModel(this.formGroup.value).asString }).subscribe(res => {
        if (res[0]) {
          this.lat = res[0].geometry.location.lat();
          this.lng = res[0].geometry.location.lng();
          this.formGroup.controls.lat.setValue(this.lat);
          this.formGroup.controls.long.setValue(this.lng);
        }
      });
    }
  }

  private getAddressFromCoordinates(lat: number, lng: number): void {
    this.geocoder.geocode({ location: { lat, lng } }).subscribe(res => {
      if (res) {
        this.setEmptyFields(res[0]);
      }
    });
  }

  private setEmptyFields(place: any): void {
    const address = GoogleMapHelper.extractAddressComponents(place);

    if (this.ifEmpty(this.formGroup.controls.street)) {
      this.formGroup.controls.street.setValue(address.street);
    }
    if (this.ifEmpty(this.formGroup.controls.countryCode)) {
      this.formGroup.controls.country.setValue(address.country);
    }
    if (this.ifEmpty(this.formGroup.controls.city)) {
      this.formGroup.controls.city.setValue(address.city);
    }

    if (this.ifEmpty(this.formGroup.controls.zipcode)) {
      this.formGroup.controls.zipcode.setValue(address.zipcode);
    }
  }

  private ifEmpty(control: AbstractControl): boolean {
    return !control.value || control.value == '';
  }

}
