import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseApiService} from './base-api.service';
import {ToastManagementService} from './toast-management.service';
import {map} from 'rxjs/operators';
import {DeviceModel} from '../models/device.model';
import {QueryParamsInterface} from '../interfaces/query-params.interface';
import {Printer} from "../printing/printer";

const API_DEVICE_URL = '/devices';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    devices: DeviceModel[] = [];

    constructor(
        private api: BaseApiService,
        private toast: ToastManagementService,
    ) {
    }

    getDevicesByRestaurant(restaurantId: number, queryParams: QueryParamsInterface = {}): Observable<DeviceModel[]> {
        return this.api.get(`${API_DEVICE_URL}/${restaurantId}`, queryParams).pipe(
            map(response => {
                    if (response.body) {
                        const restaurantDevices = response.body.data[0].map((restaurant: DeviceModel) => new DeviceModel(restaurant));
                        this.devices = restaurantDevices;

                        return restaurantDevices;
                    }
                }
            )
        );
    }

    createDevice(device: DeviceModel, successMessage?: string): Observable<DeviceModel> {
        return this.api.post(`${API_DEVICE_URL}`, device.toApi()).pipe(
            map((res: any): DeviceModel => {
                if (res.body && res.body.success !== false) {
                    this.toast.showSuccess(successMessage);
                }
                return new DeviceModel(res.body);
            }));
    }

    updateDevice(device: DeviceModel, id: number, successMessage?: string): Observable<DeviceModel> {
        return this.api.put(`${API_DEVICE_URL}/${id}`, device.toApi()).pipe(
            map((res: any): DeviceModel => {
                if (res.body && res.body.success !== false) {
                    this.toast.showSuccess(successMessage);
                }
                return new DeviceModel(res.body);
            }));
    }

    deleteDevice(device: DeviceModel, successMessage?: string): Observable<DeviceModel> {
        return this.api.delete(`${API_DEVICE_URL}/${device.id}`).pipe(
            map((res: any): DeviceModel => {
                if (res.body && res.body.success !== false) {
                    this.toast.showSuccess(successMessage);
                }
                return new DeviceModel(res.body);
            }));
    }

    get hasSavedDevices(): boolean {
        return this.devices.length > 0;
    }

    removeDevice(device: DeviceModel) {
        this.devices.forEach((item, index) => {
            if (item === device) {
                this.devices.splice(index, 1);
            }
        });
    }
}
