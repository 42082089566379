import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { HttpResponse } from '@angular/common/http';
import { DeliveryRuleModel, RestaurantModel } from '../models/restaurant.model';
import { map } from 'rxjs/operators';
import { ToastManagementService } from './toast-management.service';
import { Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { OrderTypeModel } from '../models/order-type.model';
import { QueryParamsInterface, UserParamsInterface, RatingFilterInterface, TableParamsInterface } from '../interfaces/query-params.interface';
import { UserModel } from '../models/user.model';
import { WorkingHoursModel } from '../models/working-hours.model';
import { HolidayModel } from '../models/holiday.model';
import { TableModel } from '../models/table.model';
import { PaymentModel } from '../models/order.model';
import { TagModel } from '../models/product.model';
import { HttpHelper } from '../helpers/http.helper';
import { CouponModel } from '../models/coupon.model';
import { RatingModel } from '../models/rating.model';
import { CURRENCIES } from '../globals';
import { environment } from '../../../environments/environment';

const API_RESTAURANTS_URL = '/restaurants';
const API_RATING_URL = '/order-ratings';

@Injectable({
  providedIn: 'root'
})
export class RestaurantsService {

  constructor(
    private api: BaseApiService,
    private toast: ToastManagementService,
    private router: Router
  ) {

  }

  public static getCurrencyConfig() {
    return CURRENCIES.find((currency) => currency.code === environment.appCurrency);
  }

  getRestaurants(queryParams: QueryParamsInterface = {}): Observable<HttpResponse<RestaurantModel[]>> {
    queryParams.expand = queryParams.expand ? queryParams.expand : 'address,order_types.payment_methods,logo';

    return this.api.get(API_RESTAURANTS_URL, queryParams);
  }

  getRestaurant(id: number): Observable<HttpResponse<RestaurantModel>> {
    return this.api.get(`${API_RESTAURANTS_URL}/${id}`, {expand: 'address,order_types.payment_methods,delivery_rules,logo,cover,company,working_hours,inactive_message,table_locations'}).pipe(
      map(response => {
          const res = JSON.parse(JSON.stringify(response));
          if (res.body) {
            res.body = new RestaurantModel(res.body);
          }
          return res;
        }
      )
    );
  }

  getRestaurantUsers(id: number, queryParams: UserParamsInterface = {}): Observable<HttpResponse<UserModel[]>> {
    queryParams.expand = queryParams.expand ? queryParams.expand : 'user_id';

    return this.api.get(`${API_RESTAURANTS_URL}/${id}/users`, queryParams).pipe(
      map(response => {
          const res = JSON.parse(JSON.stringify(response));
          if (res.body) {
            res.body = res.body.map(user => new UserModel(user));
          }
          return res;
        }
      )
    );
  }

  getRestaurantWorkingHours(id: number): Observable<HttpResponse<WorkingHoursModel>> {
    return this.api.get(`${API_RESTAURANTS_URL}/${id}/working-hours`).pipe(
      map(response => {
          const res = JSON.parse(JSON.stringify(response));
          if (res.body) {
            res.body = new WorkingHoursModel(res.body);
          }
          return res;
        }
      )
    );
  }

  getRestaurantHolidays(id: number): Observable<HttpResponse<HolidayModel[]>> {
    return this.api.get(`${API_RESTAURANTS_URL}/${id}/holidays`).pipe(
      map(response => {
          const res = JSON.parse(JSON.stringify(response));
          if (res.body) {
            res.body = res.body.map(holiday => new HolidayModel(holiday));
          }
          return res;
        }
      )
    );
  }

  saveRestaurant(restaurant: RestaurantModel, successMessage?: string): Observable<HttpResponse<RestaurantModel>> {
    successMessage = successMessage || _('Restaurant saved Successfully');
    const restaurantApiData = new RestaurantModel({}).toApi(restaurant);

    return this.api.postFormData(`${API_RESTAURANTS_URL}${restaurant.id ? '/' + restaurant.id : ''}`, restaurantApiData).pipe(
      map(res => {
        if (res.body && res.body.success !== false) {
          if (!restaurant.id) {
            this.router.navigate(['restaurants', res.body.id]).then();
          }
          this.toast.showSuccess(successMessage);
        }
        const response = JSON.parse(JSON.stringify(res));
        response.body = new RestaurantModel(response.body);
        return response;
      })
    );
  }

  saveRestaurantDeclaration(declaration: string, restaurantId: number): Observable<HttpResponse<RestaurantModel>> {
    return this.api.put(`${API_RESTAURANTS_URL}/${restaurantId}/declaration`, {declaration}).pipe(
      map(res => {
        if (res.body && res.body.success !== false) {
          this.toast.showSuccess(_('Declaration saved successfully'));
        }
        const response = JSON.parse(JSON.stringify(res));
        response.body = new RestaurantModel(response.body);
        return response;
      })
    );
  }

  saveRestaurantUser(user: UserModel, restaurantId: number): Observable<HttpResponse<UserModel>> {
    const userApiData = new UserModel({}).toApi(user);
    return this.api.postFormData(`${API_RESTAURANTS_URL}/${restaurantId}/users${user.id ? '/' + user.id : ''}`, userApiData).pipe(
      map(res => {
        if (res.body && res.body.success !== false) {
          this.toast.showSuccess('User saved Successfully.');
        }
        const response = JSON.parse(JSON.stringify(res));
        response.body = new UserModel(response.body);
        return response;
      })
    );
  }

  saveRestaurantOrderTypes(orderTypes: OrderTypeModel[], restaurantId: number): Observable<HttpResponse<OrderTypeModel[]>> {
    const types = orderTypes.map(orderType => orderType.toApi());

    return this.api.put(`${API_RESTAURANTS_URL}/${restaurantId}/order-types`, types).pipe(
      map(res => {
        if (res.body && res.body.success !== false) {
          this.toast.showSuccess(_('Order types saved.'));
        }
        const response = JSON.parse(JSON.stringify(res));
        response.body = response.body.map(type => new OrderTypeModel(type));
        return response;
      })
    );
  }

  saveRestaurantDeliveryRules(deliveryRules: DeliveryRuleModel[], restaurantId: number): Observable<HttpResponse<DeliveryRuleModel[]>> {
    const rules = deliveryRules.map(deliveryRule => deliveryRule.toApi());

    return this.api.put(`${API_RESTAURANTS_URL}/${restaurantId}/delivery-rules`, rules).pipe(
      map(res => {
        if (res.body && res.body.success !== false) {
          this.toast.showSuccess(_('Delivery rules saved.'));
        }
        const response = JSON.parse(JSON.stringify(res));
        response.body = response.body.map(rule => new DeliveryRuleModel(rule));
        return response;
      })
    );
  }

  saveRestaurantWorkingHours(workingHours, restaurantId: number): Observable<HttpResponse<WorkingHoursModel>> {
    const payload = new WorkingHoursModel({}).toApi(workingHours);

    return this.api.put(`${API_RESTAURANTS_URL}/${restaurantId}/working-hours`, payload).pipe(
      map(res => {
        if (res.body && res.body.success !== false) {
          this.toast.showSuccess(_('Working hours saved.'));
        }
        const response = JSON.parse(JSON.stringify(res));
        response.body = new WorkingHoursModel(response.body);
        return response;
      })
    );
  }

  saveRestaurantHolidays(holidays: HolidayModel[], restaurantId: number): Observable<HttpResponse<HolidayModel[]>> {
    const payload = holidays.map(holiday => holiday.toApi());

    return this.api.put(`${API_RESTAURANTS_URL}/${restaurantId}/holidays`, payload).pipe(
      map(res => {
        if (res.body && res.body.success !== false) {
          this.toast.showSuccess(_('Holidays saved.'));
        }
        const response = JSON.parse(JSON.stringify(res));
        response.body = response.body.map(item => new HolidayModel(item));
        return response;
      })
    );
  }

  getTables(restaurantId: number, queryParams: TableParamsInterface = {}): Observable<HttpResponse<TableModel[]>> {
    queryParams.is_active = 1;
    queryParams.expand = queryParams.expand ? queryParams.expand : 'waiter,table_url,qr_code_url';

    return this.api.get(`${API_RESTAURANTS_URL}/${restaurantId}/tables`, queryParams);
  }

  getOrderRating(restaurantId: number, queryParams: RatingFilterInterface = {}): Observable<HttpResponse<RatingModel[]>> {
    const expandedQueryParams = { ...queryParams, expand: 'order,customer,order.address' };
    return this.api.get(`${API_RATING_URL}/restaurant/${restaurantId}`, expandedQueryParams);
  }

  saveTable(table: TableModel, restaurantId: number, successMessage?: string): Observable<TableModel> {
    return table.id ? this.updateTable(table, restaurantId, successMessage) : this.createTable(table, restaurantId, successMessage);
  }

  createTable(table: TableModel, restaurantId: number, successMessage?: string): Observable<TableModel> {
    return this.api.post(`${API_RESTAURANTS_URL}/${restaurantId}/tables`, table.toApi()).pipe(
      map((res: any): TableModel => {
        successMessage = successMessage || _('Table successfully saved.');
        if (res.body && res.body.success !== false) {
          this.toast.showSuccess(successMessage);
        }
        return new TableModel(res.body);
      }));
  }

  updateTable(table: TableModel, restaurantId: number, successMessage?: string): Observable<TableModel> {
    return this.api.put(`${API_RESTAURANTS_URL}/${restaurantId}/tables/${table.id}`, table.toApi()).pipe(
      map((res: any): TableModel => {
        successMessage = successMessage || _('Table successfully saved.');
        if (res.body && res.body.success !== false) {
          this.toast.showSuccess(successMessage);
        }
        return new TableModel(res.body);
      }));
  }

  getCoupons(restaurantId: number, queryParams: QueryParamsInterface = {}): Observable<HttpResponse<CouponModel[]>> {
    queryParams.expand = queryParams.expand ? queryParams.expand : 'coupon_url,qr_code_url';

    return this.api.get(`${API_RESTAURANTS_URL}/${restaurantId}/coupons?is_active=1`, queryParams);
  }

  saveCoupon(coupon: CouponModel, restaurantId: number, successMessage?: string): Observable<CouponModel> {
    return coupon.id ? this.updateCoupon(coupon, restaurantId, successMessage) : this.createCoupon(coupon, restaurantId, successMessage);
  }

  createCoupon(coupon: CouponModel, restaurantId: number, successMessage?: string): Observable<CouponModel> {
    return this.api.post(`${API_RESTAURANTS_URL}/${restaurantId}/coupons`, coupon.toApi()).pipe(
        map((res: any): CouponModel => {
          successMessage = successMessage || _('Coupon successfully saved.');
          if (res.body && res.body.success !== false) {
            this.toast.showSuccess(successMessage);
          }
          return new CouponModel(res.body);
        }));
  }

  updateCoupon(coupon: CouponModel, restaurantId: number, successMessage?: string): Observable<CouponModel> {
    return this.api.put(`${API_RESTAURANTS_URL}/${restaurantId}/coupons/${coupon.id}`, coupon.toApi()).pipe(
        map((res: any): CouponModel => {
          successMessage = successMessage || _('Coupon successfully saved.');
          if (res.body && res.body.success !== false) {
            this.toast.showSuccess(successMessage);
          }
          return new CouponModel(res.body);
        }));
  }

  getRestaurantPayments(queryParams: QueryParamsInterface = {}): Observable<HttpResponse<PaymentModel[]>> {
    queryParams.expand = queryParams.expand ? queryParams.expand : 'order.address,order.customer,order.order_items.addons';
    queryParams = HttpHelper.filterEmptyQueryParams(queryParams);

    return this.api.get(`${API_RESTAURANTS_URL}/payments`, queryParams);
  }

  getRestaurantTags(id: number): Observable<HttpResponse<TagModel[]>> {
    return this.api.get(`${API_RESTAURANTS_URL}/${id}/tags`).pipe(
      map(response => {
          const res = JSON.parse(JSON.stringify(response));
          if (res.body) {
            res.body = res.body.map(tag => new TagModel(tag));
          }
          return res;
        }
      )
    );
  }

}
