import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from '../../../../../core';
import { UserModel } from '../../../../../../shared/models/user.model';
import { ModalService } from '../../../../../../shared/services/modal.service';
import { UserService } from '../../../../../../shared/services/user.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../../../modules/auth';
import { UserFormComponent } from '../../../../../../shared/user/user-form/user-form.component';
import { PrinterSettingsModalComponent } from '../../../../../../shared/components/printer-settings-modal/printer-settings-modal.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-user-dropdown-inner',
  templateUrl: './user-dropdown-inner.component.html',
  styleUrls: ['./user-dropdown-inner.component.scss'],
})
export class UserDropdownInnerComponent implements OnInit, OnDestroy {

  extrasUserDropdownStyle: 'light' | 'dark' = 'light';
  user: UserModel;
  private subscriptions: Subscription[] = [];


  constructor(
    private layout: LayoutService,
    private auth: AuthService,
    private modalService: ModalService,
    private userService: UserService
  ) {

  }

  ngOnInit(): void {
    this.extrasUserDropdownStyle = this.layout.getProp(
      'extras.user.dropdown.style'
    );
    this.subscriptions.push(
      this.userService.user.subscribe(user => {
        this.user = user;
      })
    );
  }

  updateProfile() {
    const modalRef = this.modalService.open(UserFormComponent, {centered: true, size: 'lg'});
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.updateSelf = true;
    modalRef.componentInstance.title = _('Update profile info')
    this.subscriptions.push(
      modalRef.componentInstance.canceled.subscribe(() => {
        this.modalService.close(modalRef);
      })
    );
    this.subscriptions.push(
      modalRef.componentInstance.saved.subscribe(value => {
        this.userService.saveUserInfo(value).subscribe();
        this.modalService.close(modalRef);
      })
    );
  }

  logout() {
    this.auth.logout();
    document.location.reload();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  openPrinterSettingsModal() {
    const modalRef = this.modalService.open(PrinterSettingsModalComponent, {centered: true, size: 'lg'});
    this.subscriptions.push(
      modalRef.componentInstance.saved.subscribe(value => {
        this.modalService.close(modalRef);
      })
    );
  }
}
