import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ORDER_STATUSES, ORDER_TYPE, ORDER_TYPES } from '../../globals';

@Component({
  selector: 'app-order-type',
  templateUrl: './order-type.component.html',
  styleUrls: ['./order-type.component.scss']
})
export class OrderTypeComponent implements OnInit {
  @Input() orderType: string;
  @Input() class: string = '';

  type: string = '';
  color: string = '';
  icon: string = '';
  cssClass: string = '';

  ORDER_TYPE = ORDER_TYPE;

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    const typeObj = ORDER_TYPES.find(type => type.key === this.orderType);

    this.type = typeObj?.value || 'N/A';
    this.color = typeObj?.color;
    this.icon = typeObj?.icon;
    this.cssClass = `label label-light-${this.color} label-pill label-inline text-nowrap ${this.class}`;
  }

}
