<div class="card card-custom card-stretch gutter-b">
  <!-- Header -->
  <div class="card-header border-0">
    <h3 class="card-title font-weight-bolder text-dark">Authors</h3>
    <div class="card-toolbar">
      <div
        ngbDropdown
        class="dropdown dropdown-inline"
        data-toggle="tooltip"
        title="Quick actions"
        data-placement="bottom"
      >
        <a
          ngbDropdownToggle
          class="btn btn-light-primary btn-sm font-weight-bolder dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Create
        </a>
        <div
          ngbDropdownMenu
          class="dropdown-menu dropdown-menu-sm dropdown-menu-right"
        >
          <app-dropdown-menu2></app-dropdown-menu2>
        </div>
      </div>
    </div>
  </div>
  <!-- Body -->
  <div class="card-body pt-2">
    <!--begin::Item-->
    <div class="d-flex align-items-center mb-10">
      <!--begin::Symbol-->
      <div class="symbol symbol-40 symbol-light-success mr-5">
        <span class="symbol-label">
          <img
            alt="boy avatar"
            src="./assets/media/svg/avatars/009-boy-4.svg"
            class="h-75 align-self-end"
          />
        </span>
      </div>
      <!--end::Symbol-->

      <!--begin::Text-->
      <div class="d-flex flex-column flex-grow-1 font-weight-bold">
        <a href="#" class="text-dark text-hover-primary mb-1 font-size-lg"
          >Ricky Hunt</a
        >
        <span class="text-muted">PHP, SQLite, Artisan CLI</span>
      </div>
      <!--end::Text-->

      <app-widget3-dropdown></app-widget3-dropdown>
    </div>
    <!--end::Item-->

    <!--begin::Item-->
    <div class="d-flex align-items-center mb-10">
      <!--begin::Symbol-->
      <div class="symbol symbol-40 symbol-light-success mr-5">
        <span class="symbol-label">
          <img
            alt=""
            src="./assets/media/svg/avatars/006-girl-3.svg"
            class="h-75 align-self-end"
          />
        </span>
      </div>
      <!--end::Symbol-->

      <!--begin::Text-->
      <div class="d-flex flex-column flex-grow-1 font-weight-bold">
        <a href="#" class="text-dark text-hover-primary mb-1 font-size-lg"
          >Anne Clarc</a
        >
        <span class="text-muted">PHP, SQLite, Artisan CLI</span>
      </div>
      <!--end::Text-->

      <app-widget3-dropdown></app-widget3-dropdown>
    </div>
    <!--end::Item-->

    <!--begin::Item-->
    <div class="d-flex align-items-center mb-10">
      <!--begin::Symbol-->
      <div class="symbol symbol-40 symbol-light-success mr-5">
        <span class="symbol-label">
          <img
            alt=""
            src="./assets/media/svg/avatars/011-boy-5.svg"
            class="h-75 align-self-end"
          />
        </span>
      </div>
      <!--end::Symbol-->

      <!--begin::Text-->
      <div class="d-flex flex-column flex-grow-1 font-weight-bold">
        <a href="#" class="text-dark text-hover-primary mb-1 font-size-lg"
          >Kristaps Zumman</a
        >
        <span class="text-muted">PHP, SQLite, Artisan CLI</span>
      </div>
      <!--end::Text-->

      <app-widget3-dropdown></app-widget3-dropdown>
    </div>
    <!--end::Item-->

    <!--begin::Item-->
    <div class="d-flex align-items-center mb-10">
      <!--begin::Symbol-->
      <div class="symbol symbol-40 symbol-light-success mr-5">
        <span class="symbol-label">
          <img
            alt=""
            src="./assets/media/svg/avatars/015-boy-6.svg"
            class="h-75 align-self-end"
          />
        </span>
      </div>
      <!--end::Symbol-->

      <!--begin::Text-->
      <div class="d-flex flex-column flex-grow-1 font-weight-bold">
        <a href="#" class="text-dark text-hover-primary mb-1 font-size-lg"
          >Ricky Hunt</a
        >
        <span class="text-muted">PHP, SQLite, Artisan CLI</span>
      </div>
      <!--end::Text-->

      <app-widget3-dropdown></app-widget3-dropdown>
    </div>
    <!--end::Item-->

    <!--begin::Item-->
    <div class="d-flex align-items-center mb-2">
      <!--begin::Symbol-->
      <div class="symbol symbol-40 symbol-light-success mr-5">
        <span class="symbol-label">
          <img
            alt=""
            src="./assets/media/svg/avatars/016-boy-7.svg"
            class="h-75 align-self-end"
          />
        </span>
      </div>
      <!--end::Symbol-->

      <!--begin::Text-->
      <div class="d-flex flex-column flex-grow-1 font-weight-bold">
        <a href="#" class="text-dark text-hover-primary mb-1 font-size-lg"
          >Carles Puyol</a
        >
        <span class="text-muted">PHP, SQLite, Artisan CLI</span>
      </div>
      <!--end::Text-->

      <app-widget3-dropdown></app-widget3-dropdown>
    </div>
    <!--end::Item-->
  </div>
</div>
