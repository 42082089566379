export class DateHelper {

  static API_DELIMITER = '-';
  static DELIMITER = '/';

  static toModel(value: string | null): string | null {
    if (value) {
      const date = value.split(this.API_DELIMITER);

      if(date.length != 3){
        return value;
      }

      const year = parseInt(date[0], 10);
      const month = parseInt(date[1], 10);
      const day = parseInt(date[2], 10);

      return day + this.DELIMITER + month + this.DELIMITER + year;
    }
    return null;
  }

  static toApi(value: string | null): string | null {
    if (value) {
      const date = value.split(this.DELIMITER);

      if(date.length != 3){
        return value;
      }

      const day = parseInt(date[0], 10);
      const month = parseInt(date[1], 10);
      const year = parseInt(date[2], 10);

      return year + this.API_DELIMITER + month + this.API_DELIMITER + day;
    }
    return null;
  }
}
