import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ToastManagementService } from './toast-management.service';
import { CategoryModel } from '../models/product.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

const API_CATEGORY_URL = '/categories';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private api: BaseApiService,
    private toast: ToastManagementService,
  ) {
  }

  getCategories(params = {}): Observable<CategoryModel[]> {
    return this.api.get(API_CATEGORY_URL, params).pipe(
      map(this.transformResponseModelArray()),
      catchError(this.handleError<CategoryModel[]>('getCategories', []))
    );
  }

  getCategory(id: number, params: any = {}): Observable<CategoryModel> {
    return this.api.get(`${API_CATEGORY_URL}/${id}`, params).pipe(
      map(this.transformResponse()),
      catchError(this.handleError<CategoryModel>(`getCategory id=${id}`))
    );
  }

  saveCategory(category: CategoryModel, successMessage?: string): Observable<CategoryModel> {
    return category.id ? this.updateCategory(category, successMessage) : this.createCategory(category, successMessage);
  }

  createCategory(category: CategoryModel, successMessage?: string): Observable<CategoryModel> {
    return this.api.postFormData(`${API_CATEGORY_URL}?expand=parent_id,sort,image,availability_hours`, category.toApi()).pipe(
      map(this.transformResponse(successMessage))
    );
  }

  updateCategory(category: CategoryModel, successMessage?: string): Observable<CategoryModel> {
    return this.api.postFormData(`${API_CATEGORY_URL}/${category.id}?expand=parent_id,sort,image,availability_hours`, category.toApi()).pipe(
      map(this.transformResponse(successMessage))
    );
  }

  updateCategories(categories: CategoryModel[], successMessage?: string): Observable<HttpResponse<CategoryModel[]>> {
    return this.api.put(`${API_CATEGORY_URL}`, categories.map(category => category.toApi())).pipe(
      map(res => {
        if (res.body && res.body.success !== false) {
          this.toast.showSuccess(_('Categories order saved.'));
        }
        return res;
      })
    );
  }

  private transformResponse(successMessage?: string) {
    successMessage = successMessage || _('Category successfully saved.');
    return (res: any): CategoryModel => {
      if (res.body && res.body.success !== false) {
        this.toast.showSuccess(successMessage);
      }
      return new CategoryModel(res.body);
    };
  }

  private transformResponseModelArray() {
    return (res: any): CategoryModel[] => {
      if (res.body) {
        return res.body.map(category => new CategoryModel(category));
      }

      return null;
    };
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }
}
