import { Injectable } from '@angular/core';
import { AuthService } from '../../modules/auth';
import { UserModel } from '../models/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { TranslationService } from './translation.service';
import { API, CURRENCIES } from '../globals';
import { QueryParamsInterface } from '../interfaces/query-params.interface';
import { RestaurantModel } from '../models/restaurant.model';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { ToastManagementService } from './toast-management.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private USER = new BehaviorSubject<UserModel>(undefined);
  user = this.USER.asObservable();
  static currencyCode: string = environment.appCurrency;

  get userInfo(): UserModel {
    return this.USER.value;
  }

  constructor(
    private authService: AuthService,
    private api: BaseApiService,
    private translationService: TranslationService,
    private toast: ToastManagementService
  ) {
    AuthService.LOGIN.subscribe(() => {
      this.initialize();
    });

    TranslationService.TRANSLATED.subscribe(lang => {
      this.changeUserLanguage(lang);
    });
  }

  public static getCurrencyConfig() {
    return CURRENCIES.find((currency) => currency.code === UserService.currencyCode);
  }

  initialize() {
    return this.authService.getUserByToken().subscribe((user: UserModel) => {
      if (user && user.currency) {
        UserService.currencyCode = user.currency;
      }
      if (user && user.language) {
        this.translationService.translateTo(user.language);
        this.translationService.setLanguageToStorage(user.language);
      } else {
        this.translationService.setLanguage(environment.appLanguage);
        this.translationService.setLanguageToStorage(environment.appLanguage);
        this.translationService.translateTo(environment.appLanguage);
      }
      this.USER.next(new UserModel(user));
    });
  }

  private changeUserLanguage(lang: string): void {
    this.api.put(`${API.USERS_URL}/me`, {language: lang}).subscribe();
  }

  getRestaurants(queryParams: QueryParamsInterface = {}): Observable<RestaurantModel[]> {
    return this.api.get(`${API.USERS_URL}/restaurants`, queryParams).pipe(
      map(response => {
          if (response.body) {
            return response.body.map(restaurant => new RestaurantModel(restaurant));
          }
        }
      )
    );
  }

  setUsersRestaurant(restaurantId: number): void {
    this.api.put(`${API.USERS_URL}/restaurants`, {restaurant_id: restaurantId}).subscribe(res => {
      if (res.body) {
        window.location.reload();
      }
    });
  }

  saveUserInfo(user: UserModel, successMessage?: string): Observable<HttpResponse<UserModel>> {
    successMessage = successMessage || _('Profile data updated successfully');
    const userApiData = new UserModel({}).toApi(user);

    return this.api.postFormData(`${API.USERS_URL}/me`, userApiData).pipe(
      map(res => {
        if (res.body && res.body.success !== false) {
          this.toast.showSuccess(successMessage);
        }

        const response = JSON.parse(JSON.stringify(res));
        response.body = new UserModel(response.body);
        return response;
      })
    );
  }

}
