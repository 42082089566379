import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lists-widget3',
  templateUrl: './lists-widget3.component.html',
})
export class ListsWidget3Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
