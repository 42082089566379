import { UsbDriver } from 'ng-thermal-print';
import { UsbPrinter } from '../printers/usb.printer';
import { Printer } from '../printer';
import { first } from 'rxjs/operators';

export class UsbPrinterConnector {

  async selectPrinter(): Promise<Printer> {
    let usbDriver = new UsbDriver();

    let printer: Printer;

    await usbDriver.requestUsb().pipe(first()).toPromise().then(result => {

      if (result.deviceClass != 0 && result.deviceClass != 7) {
        throw new Error('Selected device is not a printer');
      }

      printer = new UsbPrinter(null,{
        manufacturer: result.manufacturerName,
        model: result.productName,
        vendorId: result.vendorId,
        serialNumber: result.serialNumber,
        productId: result.productId
      });
    });

    return printer;
  }

}
