<div class="input-group">

    <div class="input-group" role="group">

        <input type="text" ngxDaterangepickerMd class="form-control"
               (ngModelChange)="propagateChange($event)"
               (datesUpdated)="datesUpdated($event)"
               [(ngModel)]="dateRange"
               [showCustomRangeLabel]="true"
               [alwaysShowCalendars]="true"
               [showClearButton]="true"
               [showRangeLabelOnInput]="true"
               [linkedCalendars]="true"
               [ranges]="ranges"
               [locale]="locale"
               [opens]="openingDirection"
               [timePicker]="timePicker"
               [timePicker24Hour]="true"
               placeholder="{{label | translate}}"/>

        <div class="input-group-append">
            <span class="input-group-text">
                <i class="fal fa-calendar"></i>
            </span>
        </div>

    </div>
</div>
