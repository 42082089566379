import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InlineSVGModule } from "ng-inline-svg";
import { ExtrasModule } from "../_metronic/partials/layout/extras/extras.module";
import {
  NgbButtonsModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbProgressbarModule,
  NgbTimepickerModule,
  NgbToastModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { SubheaderModule } from "../_metronic/partials/layout/subheader/subheader.module";
import { WidgetsModule } from "../_metronic/partials/content/widgets/widgets.module";
import { MatListModule } from "@angular/material/list";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ImageUploadComponent } from "./components/image-upload/image-upload.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NotificationComponent } from "./components/notification/notification.component";
import { AddressFormComponent } from "./components/address-form/address-form.component";
import { CompanyModalComponent } from "./components/company-modal/company-modal.component";
import { PaginatorComponent } from "./components/paginator/paginator.component";
import { MatPaginatorModule } from "@angular/material/paginator";
import { CRUDTableModule } from "../_metronic/shared/crud-table";
import { CategorySelectorComponent } from "./components/category-selector/category-selector.component";
import { TimepickerComponent } from "./components/timepicker/timepicker.component";
import { DatepickerComponent } from "./components/datepicker/datepicker.component";
import { ImagePreviewDirective } from "./directives/image-preview.directive";
import { LightboxModule } from "ngx-lightbox";
import { NgxCurrencyModule } from "ngx-currency";
import { RestaurantSelectorComponent } from "./components/restaurant-selector/restaurant-selector.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatChipsModule } from "@angular/material/chips";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { OrderTypeComponent } from "./components/order-type/order-type.component";
import { CategoryFilterPipe } from "./filter/CategoryFilterPipe";
import { UserSelectorComponent } from "./components/user-selector/user-selector.component";
import { RouterModule } from "@angular/router";
import { PaymentStatusComponent } from "./components/payment-status/payment-status.component";
import { OrderStatusComponent } from "./components/order-status/order-status.component";
import { TranslateModule } from "@ngx-translate/core";
import { DateTimePipe } from "./pipes/dateTimePipe";
import { FilterItemSelectorComponent } from "./components/filter-item-selector/filter-item-selector.component";
import { DaterangeSelectorComponent } from "./components/daterange-selector/daterange-selector.component";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { AgmCoreModule } from "@agm/core";
import { PrinterSettingsModalComponent } from "./components/printer-settings-modal/printer-settings-modal.component";
import { MultipleItemSelectorComponent } from "./components/multiple-item-selector/multiple-item-selector.component";
import { SafePipe } from "./pipes/safe.pipe";
import { CancelPaymentDialogComponent } from "./components/cancel-payment-dialog/cancel-payment-dialog.component";
import { PaymentMethodComponent } from "./components/payment-method/payment-method.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { AddBixolonWebPrinterModalComponent } from "./components/add-bixolon-web-printer-modal/add-bixolon-web-printer-modal.component";
import { QRCodeModule } from "angularx-qrcode";
import { QrCodeIconComponent } from "./components/qr-code-icon/qr-code-icon.component";
import { AddServerPrinterModalComponent } from './components/add-server-printer-modal/add-server-printer-modal.component';
import { CustomCurrencyPipe } from "./pipes/custom-currency.pipe";

@NgModule({
  declarations: [
    ImageUploadComponent,
    NotificationComponent,
    AddressFormComponent,
    CategorySelectorComponent,
    CompanyModalComponent,
    PaginatorComponent,
    TimepickerComponent,
    DatepickerComponent,
    ImagePreviewDirective,
    PaginatorComponent,
    RestaurantSelectorComponent,
    OrderTypeComponent,
    CategoryFilterPipe,
    UserSelectorComponent,
    PaymentStatusComponent,
    OrderStatusComponent,
    DateTimePipe,
    FilterItemSelectorComponent,
    DaterangeSelectorComponent,
    PrinterSettingsModalComponent,
    AddBixolonWebPrinterModalComponent,
    MultipleItemSelectorComponent,
    SafePipe,
    CustomCurrencyPipe,
    CancelPaymentDialogComponent,
    PaymentMethodComponent,
    LoaderComponent,
    QrCodeIconComponent,
    AddServerPrinterModalComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    NgbToastModule,
    FormsModule,
    TranslateModule,
    MatPaginatorModule,
    CRUDTableModule,
    NgbTimepickerModule,
    NgbDatepickerModule,
    LightboxModule,
    NgbTimepickerModule,
    NgxCurrencyModule,
    RouterModule,
    NgxDaterangepickerMd,
    AgmCoreModule,
    NgbTooltipModule,
    QRCodeModule,
  ],
  exports: [
    TranslateModule,
    InlineSVGModule,
    ExtrasModule,
    NgbButtonsModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    SubheaderModule,
    WidgetsModule,
    MatListModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    ImageUploadComponent,
    NotificationComponent,
    FormsModule,
    AddressFormComponent,
    CategorySelectorComponent,
    PaginatorComponent,
    TimepickerComponent,
    DatepickerComponent,
    NgxCurrencyModule,
    RestaurantSelectorComponent,
    MatFormFieldModule,
    MatChipsModule,
    MatAutocompleteModule,
    OrderTypeComponent,
    CategoryFilterPipe,
    UserSelectorComponent,
    ImagePreviewDirective,
    PaymentStatusComponent,
    OrderStatusComponent,
    DateTimePipe,
    CustomCurrencyPipe,
    FilterItemSelectorComponent,
    DaterangeSelectorComponent,
    MultipleItemSelectorComponent,
    NgbTooltipModule,
    PaymentMethodComponent,
    LoaderComponent,
    QrCodeIconComponent,
    AddServerPrinterModalComponent,
  ],
  providers: [CategoryFilterPipe],
})
export class SharedModule {}
