import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from '../../modules/auth';
import { FormHelper } from '../helpers/form.helper';


@Injectable({
  providedIn: 'root'
})
export class BaseApiService {

  protected constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
  }

  public newRequest(method: string, path: string = '', body = {} as any, queryParams: any = {}, options: any = {}): Observable<any> {
    if (body && (Object.keys(body).length !== 0)) {
      return this.http[method]<any>(`${path}`, body, {
        params: queryParams,
        ...options
      } as any);
    } else {
      return this.http[method](`${path}`, {
        params: queryParams,
        ...options
      } as any);
    }
  }

  public get(path: string = '', queryParams: any = {}): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.apiUrl}${path}`, {
      headers: this.headers,
      observe: 'response',
      params: queryParams
    });
  }

  public post(path: string, body: any, queryParams: any = {}, options = {}): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.apiUrl}${path}`, body, {
      headers: this.headers,
      observe: 'response',
      params: queryParams,
      ...options
    });
  }

  public postFormData(path: string, body: any, queryParams: any = {}, options = {}): Observable<HttpResponse<any>> {
    const formData = FormHelper.generateFormData(body);
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    });
    const auth = this.authService.getAuthFromLocalStorage();
    if (auth && auth.authToken) {
      headers = headers.append('Authorization', 'Bearer ' + auth.authToken);
    }
    return this.http.post(`${environment.apiUrl}${path}`, formData, {
      headers,
      observe: 'response',
      params: queryParams,
      ...options
    });
  }


  public put(path: string = '', body: any, queryParams: any = {}): Observable<HttpResponse<any>> {
    return this.http.put(`${environment.apiUrl}${path}`, body, {
      headers: this.headers,
      observe: 'response',
      params: queryParams
    });
  }

  public delete(path: string = '', queryParams: any = {}): Observable<HttpResponse<any>> {
    return this.http.delete(`${environment.apiUrl}${path}`, {
      headers: this.headers,
      observe: 'response',
      params: queryParams
    });
  }

  public uploadAttachment(body: any, queryParams: any = {}): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.apiUrl}files/upload`, body, {
      headers: this.attachmentHeaders,
      observe: 'response',
      params: queryParams
    });
  }

  public get headers(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    const auth = this.authService.getAuthFromLocalStorage();
    if (auth && auth.authToken) {
      headers = headers.append('Authorization', 'Bearer ' + auth.authToken);
    }
    return headers;
  }

  public get attachmentHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    const auth = this.authService.getAuthFromLocalStorage();
    if (auth && auth.authToken) {
      headers = headers.append('Authorization', 'Bearer ' + auth.authToken);
    }
    return headers;
  }

}
