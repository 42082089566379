import { AddressModel } from './address.model';
import { SocialNetworksModel } from './social-networks.model';
import { BaseModel } from './base.model';
import { ROLE, ROLES } from '../globals';

export class UserModel extends BaseModel {
  id: number;
  username: string;
  password: string;
  email: string;
  pic: string;
  role: string;
  currency: string;
  isActive: 0 | 1;
  occupation: string;
  companyName: string;
  phone: string;
  address?: AddressModel;
  socialNetworks?: SocialNetworksModel;
  // personal information
  firstName: string;
  imageFile?: File;
  lastName: string;
  website: string;
  // account information
  language: string;
  timeZone: string;
  restaurantId?: number;
  communication: {
    email: boolean,
    sms: boolean,
    phone: boolean
  };
  userId;
  // email settings
  emailSettings: {
    emailNotification: boolean,
    sendCopyToPersonalEmail: boolean,
    activityRelatesEmail: {
      youHaveNewNotifications: boolean,
      youAreSentADirectMessage: boolean,
      someoneAddsYouAsAsAConnection: boolean,
      uponNewOrder: boolean,
      newMembershipApproval: boolean,
      memberRegistration: boolean
    },
    updatesFromKeenthemes: {
      newsAboutKeenthemesProductsAndFeatureUpdates: boolean,
      tipsOnGettingMoreOutOfKeen: boolean,
      thingsYouMissedSindeYouLastLoggedIntoKeen: boolean,
      newsAboutMetronicOnPartnerProductsAndOtherServices: boolean,
      tipsOnMetronicBusinessProducts: boolean
    },
  };

  setUser(user: any) {
    this.id = user.id;
    this.username = user.username || '';
    this.password = user.password || '';
    this.firstName = user.first_name || '';
    this.lastName = user.last_name || '';
    this.email = user.email || '';
    this.pic = user.pic || './assets/media/users/default.jpg';
    this.role = user.role || '';
    this.address = user.address;
    this.language = user.language;
    this.userId = user.user_id;
  }

  toApi(model?: this): {} {
    return {
      ...super.toApi(model),
      ...((model && model.imageFile) ? {image_file: model.imageFile} : {}),
    };
  }

  get fullname(): string {
    return this.firstName + ' ' + this.lastName;
  }

  get isAdmin(): boolean {
    return this.role === ROLE.admin;
  }

  get isManager(): boolean {
    return this.role === ROLE.manager;
  }

  get isStaff(): boolean {
    return this.role === ROLE.staff;
  }

  get isCustomer(): boolean {
    return this.role === ROLE.customer;
  }

  get RESTAURANT_ROLES() {
    if (this.isManager || this.isAdmin) {
      return ROLES.filter(role => role.value !== ROLE.admin && role.value !== ROLE.customer);
    }
    if (this.isStaff) {
      return ROLES.filter(role => role.value !== ROLE.admin && role.value !== ROLE.manager && role.value !== ROLE.customer);
    }
  }

}
