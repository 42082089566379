<!--begin::List Widget 10-->
<div class="card card-custom" [ngClass]="cssClass">
  <!--begin::Header-->
  <div class="card-header border-0">
    <h3 class="card-title font-weight-bolder text-dark">Notifications</h3>
    <div class="card-toolbar">
      <div class="dropdown dropdown-inline" ngbDropdown>
        <a
          ngbDropdownToggle
          class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="ki ki-bold-more-ver"></i>
        </a>
        <div
          class="dropdown-menu dropdown-menu-md dropdown-menu-right"
          ngbDropdownMenu
        >
          <app-dropdown-menu3></app-dropdown-menu3>
        </div>
      </div>
    </div>
  </div>
  <!--end::Header-->

  <!--begin::Body-->
  <div class="card-body pt-0">
    <!--begin::Item-->
    <div class="mb-6">
      <!--begin::Content-->
      <div class="d-flex align-items-center flex-grow-1">
        <!--begin::Checkbox-->
        <label class="checkbox checkbox-lg checkbox-lg flex-shrink-0 mr-4">
          <input type="checkbox" value="1" />
          <span></span>
        </label>
        <!--end::Checkbox-->

        <!--begin::Section-->
        <div
          class="d-flex flex-wrap align-items-center justify-content-between w-100"
        >
          <!--begin::Info-->
          <div class="d-flex flex-column align-items-cente py-2 w-75">
            <!--begin::Title-->
            <a
              href="#"
              class="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
            >
              Daily Standup Meeting
            </a>
            <!--end::Title-->

            <!--begin::Data-->
            <span class="text-muted font-weight-bold"> Due in 2 Days </span>
            <!--end::Data-->
          </div>
          <!--end::Info-->

          <!--begin::Label-->
          <span
            class="label label-lg label-light-primary label-inline font-weight-bold py-4"
            >Approved</span
          >
          <!--end::Label-->
        </div>
        <!--end::Section-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Item-->

    <!--begin::Item-->
    <div class="mb-6">
      <!--begin::Content-->
      <div class="d-flex align-items-center flex-grow-1">
        <!--begin::Checkbox-->
        <label class="checkbox checkbox-lg checkbox-lg flex-shrink-0 mr-4">
          <input type="checkbox" value="1" />
          <span></span>
        </label>
        <!--end::Checkbox-->

        <!--begin::Section-->
        <div
          class="d-flex flex-wrap align-items-center justify-content-between w-100"
        >
          <!--begin::Info-->
          <div class="d-flex flex-column align-items-cente py-2 w-75">
            <!--begin::Title-->
            <a
              href="#"
              class="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
            >
              Group Town Hall Meet-up with showcase
            </a>
            <!--end::Title-->

            <!--begin::Data-->
            <span class="text-muted font-weight-bold"> Due in 2 Days </span>
            <!--end::Data-->
          </div>
          <!--end::Info-->

          <!--begin::Label-->
          <span
            class="label label-lg label-light-warning label-inline font-weight-bold py-4"
            >In Progress</span
          >
          <!--end::Label-->
        </div>
        <!--end::Section-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Item-->

    <!--begin::Item-->
    <div class="mb-6">
      <!--begin::Content-->
      <div class="d-flex align-items-center flex-grow-1">
        <!--begin::Checkbox-->
        <label class="checkbox checkbox-lg checkbox-lg flex-shrink-0 mr-4">
          <input type="checkbox" value="1" />
          <span></span>
        </label>
        <!--end::Checkbox-->

        <!--begin::Section-->
        <div
          class="d-flex flex-wrap align-items-center justify-content-between w-100"
        >
          <!--begin::Info-->
          <div class="d-flex flex-column align-items-cente py-2 w-75">
            <!--begin::Title-->
            <a
              href="#"
              class="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
            >
              Next sprint planning and estimations
            </a>
            <!--end::Title-->

            <!--begin::Data-->
            <span class="text-muted font-weight-bold"> Due in 2 Days </span>
            <!--end::Data-->
          </div>
          <!--end::Info-->

          <!--begin::Label-->
          <span
            class="label label-lg label-light-success label-inline font-weight-bold py-4"
            >Success</span
          >
          <!--end::Label-->
        </div>
        <!--end::Section-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Item-->

    <!--begin::Item-->
    <div class="mb-6">
      <!--begin::Content-->
      <div class="d-flex align-items-center flex-grow-1">
        <!--begin::Checkbox-->
        <label class="checkbox checkbox-lg checkbox-lg flex-shrink-0 mr-4">
          <input type="checkbox" value="1" />
          <span></span>
        </label>
        <!--end::Checkbox-->

        <!--begin::Section-->
        <div
          class="d-flex flex-wrap align-items-center justify-content-between w-100"
        >
          <!--begin::Info-->
          <div class="d-flex flex-column align-items-cente py-2 w-75">
            <!--begin::Title-->
            <a
              href="#"
              class="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
            >
              Sprint delivery and project deployment
            </a>
            <!--end::Title-->

            <!--begin::Data-->
            <span class="text-muted font-weight-bold"> Due in 2 Days </span>
            <!--end::Data-->
          </div>
          <!--end::Info-->

          <!--begin::Label-->
          <span
            class="label label-lg label-light-danger label-inline font-weight-bold py-4"
            >Rejected</span
          >
          <!--end::Label-->
        </div>
        <!--end::Section-->
      </div>
      <!--end::Content-->
    </div>
    <!--end: Item-->

    <!--begin: Item-->
    <div class="">
      <!--begin::Content-->
      <div class="d-flex align-items-center flex-grow-1">
        <!--begin::Checkbox-->
        <label class="checkbox checkbox-lg checkbox-lg flex-shrink-0 mr-4">
          <input type="checkbox" value="1" />
          <span></span>
        </label>
        <!--end::Checkbox-->

        <!--begin::Section-->
        <div
          class="d-flex flex-wrap align-items-center justify-content-between w-100"
        >
          <!--begin::Info-->
          <div class="d-flex flex-column align-items-cente py-2 w-75">
            <!--begin::Title-->
            <a
              href="#"
              class="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
            >
              Data analytics research showcase
            </a>
            <!--end::Title-->

            <!--begin::Data-->
            <span class="text-muted font-weight-bold"> Due in 2 Days </span>
            <!--end::Data-->
          </div>
          <!--end::Info-->

          <!--begin::Label-->
          <span
            class="label label-lg label-light-warning label-inline font-weight-bold py-4"
            >In Progress</span
          >
          <!--end::Label-->
        </div>
        <!--end::Section-->
      </div>
      <!--end::Content-->
    </div>
    <!--end: Item-->
  </div>
  <!--end: Card Body-->
</div>
<!--end: Card-->
<!--end: List Widget 10-->
