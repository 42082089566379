import { ORDER_TYPES, PAYMENT_METHODS } from '../globals';
import { BaseModel } from './base.model';

export class OrderTypeModel extends BaseModel {
  id: number;
  orderType: string;
  additionalCost: number;
  vatPercent: number;
  isActive?: boolean;
  paymentMethods: PaymentMethodModel[];

  get initialValues() {
    return {
      isActive: false,
      paymentMethods: [],
    };
  }

  get childModels() {
    return {
      paymentMethods: PaymentMethodModel,
    }
  }

  get activePaymentMethods() {
    return this.paymentMethods.filter((o: PaymentMethodModel) => o.isActive);
  }

  get typeLabel() {
    const type = ORDER_TYPES.find(item => item.value == this.orderType);
    return type.key;
  }

  getPaymentMethodByKey(key:string) {
    return this.paymentMethods.find(o => o.paymentMethod === key);
  }

  getPaymentMethodLabelByKey(key:string) {
    const method = PAYMENT_METHODS.find(item => item.key == key);
    return method.value;
  }
}

export class PaymentMethodModel extends BaseModel {
  id: number;
  paymentMethod: string;
  isActive?: boolean;

  get initialValues() {
    return {
      isActive: false,
    };
  }

  get methodLabel() {
    const method = PAYMENT_METHODS.find(item => item.key == this.paymentMethod);
    return method.value;
  }
}
