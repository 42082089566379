import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-filter-item-selector',
  templateUrl: './filter-item-selector.component.html',
  styleUrls: ['./filter-item-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterItemSelectorComponent),
      multi: true
    }
  ]
})
export class FilterItemSelectorComponent implements OnInit, ControlValueAccessor {

  @Input() list: any[];
  @Input() listName: string;
  @Input() selectedItem: any;

  constructor() {
  }

  ngOnInit(): void {

  }

  handleSelection(item: any) {
    this.selectedItem = item;
    this.propagateChange(item);
  }

  resetSelection() {
    this.selectedItem = null;
    this.propagateChange(null);
  }

  writeValue(value: any): void {
    this.selectedItem = value;
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn: (_: any) => {}) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {

  }

  get selectedItemValue():string{
    return this.selectedItem?.displayValue ? this.selectedItem?.displayValue : this.selectedItem?.value;
  }
}
