import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Form } from '../../interfaces/form';
import { FormHelper } from '../../helpers/form.helper';
import { UserModel } from '../../models/user.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatchValidator } from '../../validators/match-validator';
import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit, Form, OnDestroy {

  @ViewChild('formElement') formElement: ElementRef;
  title = _('Add User');
  private subscriptions: Subscription[] = [];

  set user(value: UserModel) {
    this._user = value;
    this.initForm(value);
  }

  get user(): UserModel {
    return this._user;
  }

  get updateSelf(): boolean {
    return this._updateSelf;
  }

  set updateSelf(value: boolean) {
    this._updateSelf = value;
    this.initForm(this._user);
  }

  private _user: UserModel;
  private _updateSelf = false;
  canceled = new EventEmitter<boolean>();
  saved = new EventEmitter<UserModel>();
  saving: boolean;
  form: FormGroup;
  loggedUser: UserModel;


  constructor(
    private userService: UserService
  ) {
    this.initForm();
    this.subscriptions.push(
      this.userService.user.subscribe(user => {
        if (user) {
          this.loggedUser = user;
        }
      })
    );
  }

  ngOnInit(): void {
  }

  cancel(): void {
    this.canceled.emit(true);
  }

  save(): void {
    if (this.form.valid) {
      this.saved.emit(this.form.value);
    } else {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      FormHelper.focusError(this.formElement);
    }
  }

  setImage(control: string, e: any): void {
    this.form.get(control).setValue(e);
  }

  initForm(user?: UserModel) {
    this.form = new FormGroup({
      id: new FormControl(user ? user.id : null),
      email: new FormControl(user ? user.email : null, [Validators.required, Validators.email]),
      posPasscode: new FormControl(null, [Validators.pattern(/^\d*$/), Validators.maxLength(5)]),
      newPassword: new FormControl(user ? user.password : null, !user ? Validators.required : []),
      passwordRepeat: new FormControl(user ? user.password : null, !user ? Validators.required : []),
      firstName: new FormControl(user ? user.firstName : null, Validators.required),
      lastName: new FormControl(user ? user.lastName : null, Validators.required),
      imageFile: new FormControl(null),
      phone: new FormControl(user ? user.phone : null, this._updateSelf ? Validators.required : []),
      role: new FormControl(user ? user.role : '', !this._updateSelf ? Validators.required : []),
    }, MatchValidator('newPassword', 'passwordRepeat'));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
