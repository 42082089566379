<section class="card card-custom">
    <div class="card-header">
        <h3 class="card-title">
            {{'Add a Bixolon Web printer' | translate}}
        </h3>
    </div>
    <div class="card-body pt-2">

        <form [formGroup]="form" (ngSubmit)="save()">
            <div class="row mt-5">
                <div class="col-12">
                    <div class="form-group">
                        <label>{{'Logical name' | translate}}</label>
                        <input formControlName="logicalName" type="text" class="form-control"
                               placeholder="{{'Logical name' | translate}}">
                        <div *ngIf="logicalName.invalid && (logicalName.dirty || logicalName.touched)"
                             class="text-danger">
                            <small *ngIf="logicalName.errors?.required">
                                {{'Logical name is required.' | translate}}
                            </small>
                        </div>
                    </div>
                </div>

                <div class="col-8">
                    <div class="form-group">
                        <label>{{'IP Address' | translate}}</label>
                        <input formControlName="ipAddress" type="text" class="form-control"
                               placeholder="{{'IP Address' | translate}}">
                        <div *ngIf="ipAddress.invalid && (ipAddress.dirty || ipAddress.touched)" class="text-danger">
                            <small *ngIf="ipAddress.errors?.required">
                                {{'IP Address is required.' | translate}}
                            </small>
                        </div>
                    </div>
                </div>

                <div class="col-4">
                    <div class="form-group">
                        <label>{{'Port number' | translate}}</label>
                        <input formControlName="port" type="text" class="form-control"
                               placeholder="{{'Port number' | translate}}">
                        <div *ngIf="port.invalid && (port.dirty || port.touched)"
                             class="text-danger">
                            <small *ngIf="port.errors?.required">
                                {{'Port number is required.' | translate}}
                            </small>
                            <small *ngIf="port.errors?.pattern">
                                {{'Please enter a valid number.' | translate}}
                            </small>
                        </div>
                    </div>
                </div>
            </div>

        </form>

    </div>
    <div class="modal-footer">
        <button (click)="cancel()" type="button" class="btn btn-light" tabindex="-1">
            {{'Cancel' | translate}}
        </button>
        <button (click)="save()" type="submit" class="btn btn-success" [disabled]="!form.valid || isSaving">
            {{'Save' | translate}}
            <span *ngIf="isSaving" class="spinner-border spinner-border-sm ml-2" role="status">
                <span class="sr-only">Loading...</span>
            </span>
        </button>
    </div>
</section>
