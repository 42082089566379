import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CategoryModel } from 'src/app/shared/models/product.model';
import { CategoryService } from 'src/app/shared/services/category.service';
import { CATEGORIES_COLORS, WEEK_DAYS } from '../../../shared/globals';
import { Subscription } from 'rxjs';
import { AvailabilityHoursModel } from '../../../shared/models/availability-hours.model';

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.scss']
})
export class CategoryFormComponent implements OnInit {

  @Input() category: CategoryModel;

  canceled = new EventEmitter<boolean>();
  categorySaved = new EventEmitter<CategoryModel>();

  private subscriptions: Subscription[] = [];

  categoryForm: FormGroup;
  isSaving = false;
  private categoryImage: File;

  availabilityHoursForm: any;
  isAvailabilityFormVisible: boolean;

  weekDays = WEEK_DAYS;
  categoryColors = CATEGORIES_COLORS;
  availabilityHours: AvailabilityHoursModel = new AvailabilityHoursModel({});

  constructor(
    private categoryService: CategoryService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.availabilityHours = new AvailabilityHoursModel(this.category.availabilityHours);
    this.initAvailabilityHoursForm(this.availabilityHours);
    this.initAvailabilityFormVisibility();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  save(): void {
    if (this.categoryForm.valid) {
      this.isSaving = true;
      this.category.setAttributes(this.categoryForm.value);
      this.category.availabilityHours = this.availabilityHours;
      this.category.imageFile = this.categoryImage;
      this.categoryService.saveCategory(this.category).subscribe((category: CategoryModel) => {
        this.isSaving = false;
        this.category = category;
        this.categorySaved.emit(this.category);
      });
    } else {
      this.categoryForm.updateValueAndValidity();
      this.categoryForm.markAllAsTouched();
    }
  }

  cancel() {
    this.canceled.emit(true);
  }

  get name() {
    return this.categoryForm.get('name');
  }

  get parentId() {
    return this.categoryForm.get('parentId');
  }

  private initForm() {
    this.categoryForm = new FormGroup({
      name: new FormControl(this.category.name, Validators.required),
      parentId: new FormControl(this.category.parentId),
      posColor: new FormControl(this.category.posColor),
      description: new FormControl(this.category.description)
    });
  }

  setColor(key: string) {
    this.categoryForm.get('posColor')?.setValue(key);
  }

  removeColor() {
    this.categoryForm.get('posColor')?.setValue(null);
  }

  setImage(file: any): void {
    if (file === null) {
      this.category.imageId = null;
    } else {
      this.categoryImage = file;
    }
  }

  addTimeEntry(day: string): void {
    if (this.availabilityHoursForm[day].valid) {
      if (this.availabilityHours[day] == undefined) {
        this.availabilityHours[day] = [];
      }

      this.availabilityHours[day].push({
        startTime: this.availabilityHoursForm[day].value.startTime,
        endTime: this.availabilityHoursForm[day].value.endTime,
      });
      this.availabilityHoursForm[day].get('startTime').reset();
      this.availabilityHoursForm[day].get('endTime').reset();
    } else {
      this.availabilityHoursForm[day].updateValueAndValidity();
      this.availabilityHoursForm[day].markAllAsTouched();
    }
  }

  removeTimeEntry(day: string, index: number): void {
    this.availabilityHours[day].splice(index, 1);
  }

  getEntries(day: string): number[] {
    const array = this.availabilityHoursForm.get(day).get('entries').value;
    return [...Array(array.length).keys()];
  }

  private initAvailabilityHoursForm(availabilityHours?: AvailabilityHoursModel): void {
    this.availabilityHoursForm = undefined;
    this.cdr.detectChanges();
    this.availabilityHoursForm = {
      monday: this.initDay(availabilityHours ? availabilityHours.monday?.length : false),
      tuesday: this.initDay(availabilityHours ? availabilityHours.tuesday?.length : false),
      wednesday: this.initDay(availabilityHours ? availabilityHours.wednesday?.length : false),
      thursday: this.initDay(availabilityHours ? availabilityHours.thursday?.length : false),
      friday: this.initDay(availabilityHours ? availabilityHours.friday?.length : false),
      saturday: this.initDay(availabilityHours ? availabilityHours.saturday?.length : false),
      sunday: this.initDay(availabilityHours ? availabilityHours.sunday?.length : false),
    };
    this.cdr.detectChanges();
    for (const day of Object.keys(this.availabilityHoursForm)) {
      this.subscriptions.push(
        this.availabilityHoursForm[day].get('isActive').valueChanges.subscribe(value => {
          if (!value) {
            this.availabilityHours[day] = [];
            this.availabilityHoursForm[day].get('startTime').disable();
            this.availabilityHoursForm[day].get('endTime').disable();
          } else {
            this.availabilityHoursForm[day].get('startTime').enable();
            this.availabilityHoursForm[day].get('endTime').enable();
          }
        })
      );
    }
  }

  private initAvailabilityFormVisibility(): void {
    this.isAvailabilityFormVisible = this.weekDays.some(day => this.availabilityHours[day.value].length);
  }

  toggleAvailabilityFormVisibility(): void {
    this.isAvailabilityFormVisible = !this.isAvailabilityFormVisible;
  }

  private initDay(isActive): FormGroup {
    return new FormGroup({
      isActive: new FormControl(!!isActive),
      startTime: new FormControl({value: null, disabled: !!!isActive}, Validators.required),
      endTime: new FormControl({value: null, disabled: !!!isActive}, Validators.required)
    });
  }

  getSelectedColor() {
   return this.categoryForm.get('posColor').value;
  }

  getSelectedColorTextColor() {
    const selectedColorKey = this.categoryForm.get('posColor').value;

    if (!selectedColorKey) {
      return;
    }

    return this.categoryColors.find(categoryColor => categoryColor.key === selectedColorKey)?.textColor;
  }

  showError(day: string, control: string): boolean {
    return !!(this.availabilityHoursForm[day].get(control).touched && this.availabilityHoursForm[day].get(control).errors);
  }
}
