<div class="btn-group row no-gutters w-100">
    <div class="btn-group col" ngbDropdown role="group">
        <button id="categorySelectorDropdown" type="button" class="btn btn-outline-secondary text-left"
                ngbDropdownToggle>
            {{selectedUser ? selectedUser.firstName + ' ' + selectedUser.lastName : ('Select user' | translate)}}
            <i class="fal fa-angle-down float-right mt-3"></i>
        </button>
        <div aria-labelledby="categorySelectorDropdown" class="dropdown-menu w-100" ngbDropdownMenu>
            <button type="button" *ngFor="let user of users" ngbDropdownItem
                    (click)="handleSelection(user)">
                {{user.firstName + ' ' + user.lastName}}
            </button>
        </div>
    </div>
</div>

