import { CategoryModel } from '../models/product.model';
import { OrderItem } from '../models/order.model';
import { group } from '@angular/animations';

export class CategoryHelper {

  static buildCategoryChildrenArray(categories: CategoryModel[], categoryToInsert?: CategoryModel): CategoryModel[] {
    let categoriesClone = categories.map(x => new CategoryModel(Object.assign({}, x)));
    if (categoryToInsert) {
      categoriesClone.push(categoryToInsert);
    }
    let tree: CategoryModel[] = [];
    const idMapping = categoriesClone.reduce((acc, el, i) => {
      acc[el.id] = i;
      return acc;
    }, {});

    categoriesClone.forEach(el => {
      el.children = [];
    });


    categoriesClone.forEach(el => {
      if (!el.parentId) {
        tree.push(el);
        return;
      }
      const parentEl = categoriesClone[idMapping[el.parentId]];
      parentEl.children.push(el);
    });

    return tree;
  }

  static flattenCategoryTree(tree: CategoryModel[], result?: CategoryModel[], level?: number) {
    tree.forEach((node: CategoryModel, i: number) => {
      node.level = level || 0;
      result.push(node);
      if (node.children.length > 0) {
        this.flattenCategoryTree(node.children, result, node.level + 1);
      }
    });

    return result;
  }

  static numberOfSubcategories(category: CategoryModel): number {
    let number = category.children.length;
    for (const categoryElement of category.children) {
      number += categoryElement.children.length;
      this.numberOfSubcategories(categoryElement);
    }
    return number;
  }

  static findRootCategory(category: CategoryModel, categories: CategoryModel[]) {
    let rootCategory = category;

    while (rootCategory.parentId) {
      rootCategory = categories.find(item => item.id == rootCategory.parentId);
    }

    return rootCategory;
  }

  static groupItemsWithRootCategories(items: OrderItem[], categories: CategoryModel[]) {
    let groupedItems = [];

    items.forEach(item => {
      let rootCategory = this.findRootCategory(item.product.category, categories);

      let group = groupedItems.find(group => group.category.id == rootCategory.id);

      if (!group) {
        group = {category: rootCategory, items: [item]};
        groupedItems.push(group);
      } else {
        group.items.push(item);
      }
    });

    return groupedItems.sort((a, b) => a.category.sort - b.category.sort);
  }

}
