import { Directive, ElementRef, HostListener } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';

@Directive({
  selector: '[appImagePreview]'
})
export class ImagePreviewDirective {

  constructor(
    private _lightbox: Lightbox,
  ) {
  }

  @HostListener('click', ['$event.target'])
  onClick(image) {
    this._lightbox.open([{src: image.src, thumb: ''}], 0, {
      centerVertically: true
    });
  }

}
