import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, } from '@angular/router';
import { AuthService } from './auth.service';
import { ToastManagementService } from '../../../shared/services/toast-management.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UserService } from '../../../shared/services/user.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private toast: ToastManagementService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.authenticate(route, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.authenticate(childRoute, state);
  }

  private authenticate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser = this.userService.userInfo;
    const authData = this.authService.getAuthFromLocalStorage();

    if (currentUser?.id) {
      if (currentUser.isCustomer) {
        this.toast.showDanger(_('You are not allowed to login to our admin panel.'));
        this.authService.logout();
        return false;
      }

      return this.checkRole(route, currentUser);
    }


    if (!currentUser?.id && !authData) {
      this.router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}}).then();
      return true;
    }
  }

  private checkRole(route: ActivatedRouteSnapshot, currentUser): boolean {
    if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
      this.router.navigate(['/']).then();
      return false;
    }
    return true;
  }

}
