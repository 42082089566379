import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CategoryFormComponent } from 'src/app/pages/category/category-form/category-form.component';
import { CategoryModel } from '../../models/product.model';
import { CategoryService } from '../../services/category.service';
import { ModalService } from '../../services/modal.service';
import { CategoryHelper } from '../../helpers/category.helper';

@Component({
  selector: 'app-category-selector',
  templateUrl: './category-selector.component.html',
  styleUrls: ['./category-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CategorySelectorComponent),
      multi: true
    }
  ]
})
export class CategorySelectorComponent implements OnInit, ControlValueAccessor {

  @Input() selectedCategoryId: number;
  @Input() hasCreateButton: boolean = false;

  private unsubscribe: Subscription[] = [];

  productCategories: CategoryModel[];
  selectedCategory: CategoryModel;

  constructor(
    private modalService: ModalService,
    private categoryService: CategoryService
  ) { }

  ngOnInit(): void {
    this.loadCategories();
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn: (_: any) => {}) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  writeValue(value: any) {
    this.selectedCategoryId = value;
  }

  handleSelection(category: CategoryModel) {
    this.selectedCategory = category;
    this.propagateChange(category.id);
  }

  handleCreateCategoryModal(): void {

    if (!this.hasCreateButton) {
      return;
    }

    const modalRef = this.modalService.open(CategoryFormComponent);
    modalRef.componentInstance.category = new CategoryModel();

    const savedSubscription = modalRef.componentInstance.categorySaved.subscribe((category: CategoryModel) => {
      this.handleSelection(category);
      this.addCategoryOption(category);
      this.modalService.close(modalRef);
    });

    const canceledSubscription = modalRef.componentInstance.canceled.subscribe(() => {
      this.modalService.close(modalRef);
    });

    this.unsubscribe.push(savedSubscription);
    this.unsubscribe.push(canceledSubscription);
  }

  private loadCategories() {
    this.categoryService.getCategories({ expand: 'parent_id,level', "per-page": 100 }).subscribe((categories: CategoryModel[]) => {
      this.productCategories = categories;
      this.initSelectedCategory();
    });
  }

  private initSelectedCategory() {
    this.productCategories.forEach((category: CategoryModel) => {
      if (this.selectedCategoryId == category.id) {
        this.selectedCategory = category;
      }
    });
  }

  private addCategoryOption(categoryToInsert: CategoryModel) {
    let categoryTree = CategoryHelper.buildCategoryChildrenArray(this.productCategories, categoryToInsert);
    this.productCategories = CategoryHelper.flattenCategoryTree(categoryTree, []);
  }

}
