<!--begin::Mixed Widget 12-->
<div class="card card-custom" [ngClass]="cssClass">
  <!--begin::Body-->
  <div class="card-body d-flex flex-column">
    <div class="flex-grow-1 pb-5">
      <!--begin::Info-->
      <div class="d-flex align-items-center pr-2 mb-6">
        <span class="text-muted font-weight-bold font-size-lg flex-grow-1">
          5 Weeks Ago
        </span>
        <div class="symbol symbol-50">
          <span class="symbol-label bg-light-light">
            <span
              [inlineSVG]="'./assets/media/svg/misc/003-puzzle.svg'"
              class="svg-icon h-50 align-self-center"
            ></span>
          </span>
        </div>
      </div>
      <!--end::Info-->

      <a
        href="#"
        class="text-dark font-weight-bolder text-hover-primary font-size-h4"
      >
        KT.com - High Quality<br />
        Templates
      </a>

      <!--begin::Desc-->
      <p class="text-dark-50 font-weight-normal font-size-lg mt-6">
        Easy to use, incredibly flexible and secure<br />
        with in-depth documentation that outlines<br />
        everything for you
      </p>
      <!--end::Desc-->
    </div>

    <!--begin::Team-->
    <div class="d-flex align-items-center">
      <!--begin::Pic-->
      <a href="#" class="symbol symbol-45 symbol-light mr-3">
        <div class="symbol-label">
          <span
            [inlineSVG]="'./assets/media/svg/avatars/001-boy.svg'"
            class="svg-icon h-75 align-self-end"
          ></span>
        </div>
      </a>
      <!--end::Pic-->

      <!--begin::Pic-->
      <a href="#" class="symbol symbol-45 symbol-light mr-3">
        <div class="symbol-label">
          <span
            [inlineSVG]="'./assets/media/svg/avatars/028-girl-16.svg'"
            class="svg-icon h-75 align-self-end"
          ></span>
        </div>
      </a>
      <!--end::Pic-->

      <!--begin::Pic-->
      <a href="#" class="symbol symbol-45 symbol-light">
        <div class="symbol-label">
          <span
            [inlineSVG]="'./assets/media/svg/avatars/024-boy-9.svg'"
            class="svg-icon h-75 align-self-end"
          ></span>
        </div>
      </a>
      <!--end::Pic-->
    </div>
    <!--end::Team-->
  </div>
  <!--end::Body-->
</div>
<!--end::Mixed Widget 12-->
