import { HttpResponse } from '@angular/common/http';

export class HttpHelper {
  static requestPassed(response: HttpResponse<any>): boolean {
    return response.body && response.body.success !== false;
  }

  static filterEmptyQueryParams(queryParams: any): any {
    for (const param in queryParams) {
      if (!queryParams[param] || queryParams[param] == null) {
        delete queryParams[param];
      }
    }
    return queryParams;
  }
}
