<div class="d-flex flex-column flex-root h-100" id="kt_login_wrapper">
  <!--begin::Login-->
  <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
    <!--begin::Aside-->
    <div class="login-aside d-flex flex-column flex-row-auto" style="background-color: #F2C98A;">
      <!--begin::Aside Top-->
      <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
        <!--begin::Aside header-->
        <a href="#" class="text-center mb-10">
          <img [src]="logoUrl" class="max-h-70px w-75" alt="" />
        </a>
        <!--end::Aside header-->

        <!--begin::Aside title-->
        <h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg" style="color: #986923;">
          {{'Discover Amazing Dinego' | translate}}
        </h3>
        <!--end::Aside title-->
      </div>
      <!--end::Aside Top-->

      <!--begin::Aside Bottom-->
      <div class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
        style="background-image: url('./assets/media/svg/illustrations/login-visual-1.svg')"></div>
      <!--end::Aside Bottom-->
    </div>
    <!--begin::Aside-->

    <!--begin::Content-->
    <div
      class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
      <!--begin::Content body-->
      <div class="d-flex flex-column-fluid flex-center">
        <router-outlet></router-outlet>
      </div>
      <!--end::Content body-->

    </div>
    <!--end::Content-->
  </div>
  <!--end::Login-->
</div>