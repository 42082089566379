import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { CategoryModel } from '../models/product.model';

@Pipe({
  name: 'categoryFilter',
})
@Injectable()
export class CategoryFilterPipe implements PipeTransform {
  transform(categories: CategoryModel[], fields: string[], value: string): any[] {

    if (!categories) {
      return [];
    }

    if (!value) {
      return categories;
    }

    return this.getFilteredCategories(categories, fields, value);
  }

  getFilteredCategories(categories: CategoryModel[], fields: any, value: string): CategoryModel[] {

    let filteredCategories = [];

    categories.forEach(category => {
      let checked = this.checkCategory(category, fields, value);

      if (checked) {
        filteredCategories.push(checked);
      }

    });

    return filteredCategories;
  }

  checkCategory(category: CategoryModel, fields: any, value: string): CategoryModel {

    let categoryCopy = Object.assign({}, category);

    let match = fields.some(field => {
      if (category[field]) {
        return category[field].toLowerCase().includes(value.toLowerCase());
      }

      return false;
    });

    if (match) {
      return categoryCopy;
    }

    let children = [];

    category.children.forEach(child => {
      let checked = this.checkCategory(child, fields, value);

      if (checked) {
        children.push(checked);
      }

    });

    if (children.length == 0) {
      return null;
    }

    categoryCopy.children = children;

    return categoryCopy;
  }

}
