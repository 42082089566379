interface GoogleAddress {
    address: string,
    street: string,
    zipcode: string,
    city: string,
    state: string,
    country: string
}

export class GoogleMapHelper {

    static extractAddressComponents(place) {
        let address = {
            street: '',
            zipcode: '',
            city: '',
            state: '',
            country: ''
        };

        for (const component of place.address_components) {
            const componentType = component.types[0];

            switch (componentType) {

                case "street_number": {
                    address.street = `${address.street} ${component.long_name}`;
                    break;
                }

                case "route": {
                    address.street = `${component.short_name} ${address.street}`;
                    break;
                }

                case "postal_code": {
                    address.zipcode = `${component.long_name}${address.zipcode}`;
                    break;
                }

                case "postal_code_suffix": {
                    address.zipcode = `${address.zipcode}-${component.long_name}`;
                    break;
                }

                case "locality":
                    address.city = component.long_name;
                    break;

                case "administrative_area_level_1": {
                    address.state = component.short_name;
                    break;
                }

                case "country":
                    address.country = component.long_name;
                    break;
            }
        }

        return address;
    }

}