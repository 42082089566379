<form [formGroup]="categoryForm" (ngSubmit)="save()">
    <div class="modal-header">
        <h4 class="modal-title">{{(category.id ? 'Update Category' : 'Create Category') | translate}}</h4>
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="d-flex align-items-center justify-content-center">
                    <app-image-upload [stretched]="true"
                                      [label]="'Category Thumb' | translate"
                                      [imageUrl]="category.getImageUrl('w300')"
                                      (image)="setImage($event)"
                                      [placeholderImage]="category.defaultImageUrl"
                                      class="mx-auto mb-4">
                    </app-image-upload>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label>{{'Name' | translate}}</label>
                    <input formControlName="name" type="text" class="form-control"
                           placeholder="{{'Category Name' | translate}}">
                    <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger">
                        <small *ngIf="name.errors?.required">
                            {{'Category name is required.' | translate}}
                        </small>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>{{'Parent Category' | translate}}</label>
                    <app-category-selector
                        class="input-group"
                        [formControl]="parentId"
                        [selectedCategoryId]="parentId.value">
                    </app-category-selector>
                </div>
            </div>

            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label>{{'POS Color' | translate}}</label>
                    <div class="btn-group row no-gutters w-100">
                        <div class="btn-group col" ngbDropdown role="group">
                            <button id="categorySelectorDropdown" type="button" class="btn btn-outline-secondary text-left bg-{{getSelectedColor()}} text-{{getSelectedColorTextColor()}}" ngbDropdownToggle>
                                {{!!getSelectedColor() ? ('POS Color' | translate) : ('Select color' | translate)}}
                                <i class="fal fa-angle-down float-right mt-1 text-{{getSelectedColorTextColor()}}"></i>
                            </button>
                            <div aria-labelledby="categorySelectorDropdown" class="dropdown-menu w-100 dropdown-scroll" ngbDropdownMenu>
                                <button *ngIf="getSelectedColor()" type="button" ngbDropdownItem (click)="removeColor()">
                                    {{'Remove color' | translate}}
                                </button>
                                <div *ngIf="getSelectedColor()" class="dropdown-divider"></div>
                                <div *ngFor="let color of categoryColors" ngbDropdownItem class="">
                                    <button class="btn btn-{{color.key}} w-100" type="button" (click)="setColor(color.key)">
                                        {{color.name}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="form-group">
                    <label>{{'Description' | translate}}</label>
                    <textarea formControlName="description" class="form-control" rows="3"></textarea>
                </div>
            </div>

            <div class="col-12">
                <div class="form-group">
                    <button class="btn btn-light" type="button" (click)="toggleAvailabilityFormVisibility()">{{'Adjust Availability' | translate}}</button>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="availabilityHoursForm" [hidden]="!isAvailabilityFormVisible">
            <form class="col-12 grid-border" (ngSubmit)="addTimeEntry(day.value)"
                  [formGroup]="availabilityHoursForm[day.value]" *ngFor="let day of weekDays">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="form-group form-check pl-0">
                    <span class="switch">
                        <label>
                              <input id="{{day.value}}"
                                     formControlName="isActive"
                                     type="checkbox"
                                     class="form-check-input">
                            <span></span>
                        </label>
                        <label class="ml-2" for="{{day.value}}">{{day.key | translate}}</label>
                    </span>
                    </div>
                </div>
                <ul class="working-hours-list">
                    <li *ngFor="let entry of availabilityHours[day.value]; index as i;first as isFirst">
                        <div class="d-flex align-items-center justify-content-between">
                            <h5 class="m-0 font-weight-bold">
                                {{entry.startTime}} - {{entry.endTime}}
                            </h5>
                            <button type="button"
                                    (click)="removeTimeEntry(day.value, i)"
                                    ngbTooltip="{{'Remove Time Entry' | translate}}"
                                    class="btn btn-hover-transparent-danger btn-sm btn-icon">
                                <i class="fal fa-times"></i>
                            </button>
                        </div>
                    </li>
                </ul>
                <div class="row no-gutters pb-4">
                    <div class="col-auto d-flex align-items-center">
                        <app-timepicker formControlName="startTime"></app-timepicker>
                    </div>
                    <div class="col-auto px-3 pb-5 d-flex align-items-center">-</div>
                    <div class="col-auto d-flex align-items-center">
                        <app-timepicker formControlName="endTime"></app-timepicker>
                    </div>
                    <div class="col d-flex justify-content-end align-items-center">
                        <small class="text-danger mr-3"
                               *ngIf="showError(day.value, 'startTime') || showError(day.value, 'endTime')">
                            {{'The entry is invalid' | translate}}
                        </small>
                        <button class="btn btn-light-primary"
                                type="submit"
                                placement="top"
                                [disabled]="availabilityHoursForm[day.value].get('startTime').disabled"
                                ngbTooltip="{{'Add Time Entry' | translate}}">
                            <i class="fal fa-plus pr-0"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" (click)="cancel()" class="btn btn-light">
            {{'Cancel' | translate}}
        </button>
        <button type="submit" class="btn btn-success" [disabled]="isSaving">
            {{'Save' | translate}}
            <span *ngIf="isSaving" class="spinner-border spinner-border-sm ml-2" role="status">
                <span class="sr-only">Loading...</span>
            </span>
        </button>
    </div>
</form>
