<div class="btn-group custom no-gutters w-100 input-group">
    <div class="btn-group w-100" ngbDropdown container="body" role="group">
        <button type="button" class="btn btn-outline-secondary text-left"
                [ngClass]="{'text-muted': !selectedItem}"
                ngbDropdownToggle>
            <span class="mr-4">{{(selectedItemValue | translate) || listName}}</span>
            <i class="fal fa-angle-down float-right mt-3"></i>
        </button>
        <div class="dropdown-menu w-auto position-absolute" ngbDropdownMenu>
            <span *ngIf="selectedItem" class="small m-4">
                {{listName | translate}}
            </span>
            <div *ngIf="selectedItem" class="dropdown-divider"></div>
            <button *ngIf="selectedItem" type="button" ngbDropdownItem (click)="resetSelection()">
                {{'Any' | translate}}
            </button>
            <div *ngIf="selectedItem" class="dropdown-divider"></div>
            <button type="button" *ngFor="let item of list" ngbDropdownItem
                    (click)="handleSelection(item)" [innerHTML]="item.value | translate | safe">
            </button>
        </div>
    </div>
</div>
