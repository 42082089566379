import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget4-dropdown',
  templateUrl: './widget4-dropdown.component.html',
})
export class Widget4DropdownComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
