<div class="form-group pb-0">
    <label>{{label}}</label>
    <div class="input-group">
        <input class="form-control" placeholder="dd/mm/yyyy"
               name="dp" [formControl]="date" ngbDatepicker #d="ngbDatepicker">
        <div class="input-group-append">
            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                <i class="fal fa-calendar-alt"></i>
            </button>
        </div>
    </div>
</div>
