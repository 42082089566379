<section class="card card-custom">
    <div class="card-header">
        <h3 *ngIf="!device" class="card-title">
            {{'Add a Server printer' | translate}}
        </h3>
        <h3 *ngIf="device" class="card-title">
            {{'Edit a Server printer' | translate}}
        </h3>
    </div>
    <div class="card-body pt-2">
        <form [formGroup]="form" (ngSubmit)="save()">
            <div class="row mt-5">
                <div class="col-6">
                    <div class="form-group">
                        <label>{{'Printer name' | translate}}</label>
                        <input formControlName="name" type="text" class="form-control"
                               placeholder="{{'Printer name' | translate}}">
                        <div *ngIf="name.invalid && (name.dirty || name.touched)"
                             class="text-danger">
                            <small *ngIf="name.errors?.required">
                                {{'Printer name is required.' | translate}}
                            </small>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>{{'Serial number' | translate}}</label>
                        <input formControlName="serialNumber" type="text" class="form-control"
                               placeholder="{{'Serial number' | translate}}">
                        <div *ngIf="serialNumber.invalid && (serialNumber.dirty || serialNumber.touched)"
                             class="text-danger">
                            <small *ngIf="serialNumber.errors?.required">
                                {{'Serial number is required.' | translate}}
                            </small>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>{{'IP Address' | translate}}</label>
                        <input formControlName="ipAddress" type="text" class="form-control"
                               placeholder="{{'IP Address' | translate}}">
                        <div *ngIf="ipAddress.invalid && (ipAddress.dirty || ipAddress.touched)"
                             class="text-danger">
                            <small *ngIf="ipAddress.errors?.required">
                                {{'IP Address is required.' | translate}}
                            </small>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>{{'Language' | translate}}</label>
                        <div class="input-group" ngbDropdown>
                            <button
                                    type="button"
                                    class="btn btn-outline-secondary text-left rounded d-flex justify-content-between w-100"
                                    ngbDropdownToggle
                            >
                                <span class="text-truncate" style="max-width: 140px">
                                  {{ selectedLanguage?.name || ("Select language" | translate) }}
                                </span>
                                <i class="fal fa-angle-down pt-3 pl-3"></i>
                            </button>
                            <div class="dropdown-menu" ngbDropdownMenu>
                                <button
                                        *ngFor="let language of languages"
                                        type="button"
                                        ngbDropdownItem
                                        class="d-flex"
                                        [ngClass]="{
                                                'font-weight-bold bg-light': selectedLanguage?.lang === language.lang
                                              }"
                                        (click)="handleLanguageSelection(language)"
                                >
                                  <span class="text-truncate">
                                    {{ language.name }}
                                  </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label>{{ 'Description' | translate }}</label>
                        <textarea formControlName="description" rows="3" class="form-control"
                                  placeholder="{{ 'Description' | translate }}"></textarea>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button (click)="cancel()" type="button" class="btn btn-light" tabindex="-1">
            {{'Cancel' | translate}}
        </button>
        <button (click)="save()" type="submit" class="btn btn-success" [disabled]="!form.valid || isSaving">
            {{'Save' | translate}}
            <span *ngIf="isSaving" class="spinner-border spinner-border-sm ml-2" role="status">
                <span class="sr-only">Loading...</span>
            </span>
        </button>
    </div>
</section>
