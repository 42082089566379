import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map, shareReplay, switchMap } from 'rxjs/operators';
import { UserModel } from '../../../shared/models/user.model';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  static LOGIN = new EventEmitter<boolean>();
  static LOGOUT = new EventEmitter<boolean>();

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private readonly _getUserData = new Map<string, Observable<UserModel>>();

  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;


  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  login(email: string, password: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthToLocalStorage(auth);
        AuthService.LOGIN.emit(true);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout(): void {
    AuthModel.logout();
    AuthService.LOGOUT.emit(true);
  }

  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    const cacheKey = auth.authToken;
    if (this._getUserData && this._getUserData.get(cacheKey)) {
      return this._getUserData.get(cacheKey);
    }

    this.isLoadingSubject.next(true);
    this._getUserData.set(cacheKey, this.authHttpService.getUserByToken(auth.authToken).pipe(
      map((user: UserModel) => {
        if (user) {
          this.currentUserSubject = new BehaviorSubject<UserModel>(new UserModel(user));
        } else {
          this.logout();
        }
        return user;
      }),
      shareReplay(),
      finalize(() => this.isLoadingSubject.next(false))
    ));

    return this._getUserData.get(cacheKey);
  }

  refreshToken(token: string) {
    this.isLoadingSubject.next(true);
    return this.authHttpService.refreshToken(token).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthToLocalStorage(auth);
        AuthService.LOGIN.emit(true);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  resetPassword(password: string, passwordRepeat: string, token:string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .resetPassword(password, passwordRepeat, token)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }


  getAuthFromLocalStorage(): AuthModel {
    return AuthModel.getAuthFromLocalStorage();
  }

  // private methods
  private setAuthToLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    return AuthModel.setAuthToLocalStorage(auth);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}
