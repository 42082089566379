import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import { Subject } from 'rxjs';
import { NgbTimeAdapter, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStringAdapter } from './format.adapter';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TimepickerComponent,
      multi: true
    },
    {
      provide: NgbTimeAdapter,
      useClass: NgbTimeStringAdapter
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimepickerComponent),
      multi: true,
    },
  ]
})
export class TimepickerComponent implements ControlValueAccessor {

  @ViewChild('instance', {static: true}) instance: NgbTypeahead;
  @Input() minuteStep = 15;
  time = new FormControl(null);
  isDisabled: boolean;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor() {
  }

  onTouched = () => {
  }

  onValidationChange = () => {
  }

  writeValue(time: string): void {
    this.time.setValue(time);
  }

  registerOnChange(onChange: any): void {
    this.time.valueChanges.subscribe(onChange);
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    this.onTouched();
  }

  setDisabledState(disabled: boolean): void {
    disabled ? this.time.disable() : this.time.enable();
  }

  validate(control: AbstractControl): ValidationErrors {
    const timeIsBlank = !this.time.value;
    return timeIsBlank ? {timeIsBlank} : null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

}
