import {Component} from '@angular/core';
import {ToastManagementService} from '../../services/toast-management.service';

@Component({
  selector: 'app-notification',
  template: `
      <ngb-toast
          *ngFor="let toast of toastService.toasts"
          [class]="toast.classname"
          [autohide]="true"
          [delay]="toast.delay || 5000"
          (hidden)="toastService.remove(toast)"
      >
          <span>{{ toast.textOrTpl | translate }}</span>
      </ngb-toast>
  `,
  host: {'[class.ngb-toasts]': 'true'}
})
export class NotificationComponent {

  constructor(
    public toastService: ToastManagementService
  ) {
  }

}
