import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { PrinterService } from '../../services/printer.service';
import { Printer } from '../../printing/printer';
import { ModalService } from '../../services/modal.service';
import { Subscription } from 'rxjs';
import { UsbPrinterConnector } from '../../printing/printer-connectors/usb.printer-connector';
import { ToastManagementService } from '../../services/toast-management.service';
import { TranslateService } from '@ngx-translate/core';
import { AddBixolonWebPrinterModalComponent } from '../add-bixolon-web-printer-modal/add-bixolon-web-printer-modal.component';
import {AddServerPrinterModalComponent} from '../add-server-printer-modal/add-server-printer-modal.component';
import {DeviceService} from '../../services/device.service';
import {DeviceModel} from '../../models/device.model';
import {LANGUAGES} from '../../globals';
import {UserService} from '../../services/user.service';
import {UserModel} from '../../models/user.model';

interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}

@Component({
  selector: 'app-printer-settings-modal',
  templateUrl: './printer-settings-modal.component.html',
  styleUrls: ['./printer-settings-modal.component.scss']
})
export class PrinterSettingsModalComponent implements OnInit, OnDestroy {

  languages: LanguageFlag[] = LANGUAGES;
  printers: Printer[] = [];
  devices: DeviceModel[] = [];
  user: UserModel;

  saved = new EventEmitter<boolean>();

  private subscriptions: Subscription[] = [];

  constructor(public printerService: PrinterService,
              private modalService: ModalService,
              private toast: ToastManagementService,
              private translate: TranslateService,
              private deviceService: DeviceService,
              private userService: UserService,
              ) {
    this.user = this.userService.userInfo;
    this.printers = this.printerService.printers;
    this.devices = this.deviceService.devices;
  }

  ngOnInit(): void {
  }

  async connectUsbPrinter() {
    let printerConnector = new UsbPrinterConnector();

    let printer: Printer;

    await printerConnector.selectPrinter()
      .then(result => printer = result)
      .catch(error => this.toast.showDanger(this.translateLabel(error.message)));

    if (printer) {
      this.printerService.addPrinter(printer);
    }

  }

  connectBixolonWebPrinter() {
    const modalRef = this.modalService.open(AddBixolonWebPrinterModalComponent, {centered: true});
    this.subscriptions.push(
      modalRef.componentInstance.saved.subscribe(value => {
        if (value) {
          this.printerService.addPrinter(value);
        }
        this.modalService.close(modalRef);
      })
    );
  }

  connectServerPrinter(device?: DeviceModel) {
    const modalRef = this.modalService.open(AddServerPrinterModalComponent, {centered: true});

    if (device) {
      modalRef.componentInstance.device = device;
      modalRef.componentInstance.modalRef = modalRef;
    }

    this.subscriptions.push(
        modalRef.componentInstance.saved.subscribe(() => this.modalService.close(modalRef))
    );
  }

  cancel() {
    this.saved.emit(false);
  }

  testPrint(printer: Printer) {
    this.printerService.testPrint(printer, this.printCallback.bind(this));
  }

  private printCallback(){

  }

  removePrinter(printer: Printer) {
    this.printerService.removePrinter(printer);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  translateLabel(value: string) {
    return this.translate.instant(value);
  }

  lineWidthChanged(printer:Printer) {
    let newValue = printer.lineWidth;

    newValue = newValue < Printer.MIN_LINE_WIDTH ? Printer.MIN_LINE_WIDTH : parseInt(newValue.toString());

    printer.lineWidth = newValue;
    this.printerService.savePrinters();
  }

  changePrinterProductType(printer: Printer, productType: string) {
    printer.productType = productType;
    this.printerService.savePrinters();
  }

  getLanguageFlag(lang: any) {
    const selectedLanguage = this.languages.find(language => language.lang === (lang || 'de'));
    return selectedLanguage.flag;
  }

  removeDevice(device: DeviceModel) {
    this.deviceService.deleteDevice(device, 'Device successfully deleted.').subscribe((response) => {
      if (response) {
        this.deviceService.removeDevice(device);
      }
    });
  }
}
