import { AddressModel } from './address.model';
import { OrderTypeModel } from './order-type.model';
import { ImageModel } from './image.model';
import { CompanyModel } from './company.model';
import { WorkingHoursModel } from './working-hours.model';
import { ImageHelper } from '../helpers/image.helper';
import { BaseModel } from './base.model';
import { TableModel } from './table.model';

export class RestaurantModel {
  id: number;
  companyId: number;
  company?: CompanyModel;
  addressId: number;
  preparationTime?: number;
  deliveryTime?: number;
  name: string;
  datatransMerchantId: string;
  slug: string;
  description?: string;
  declaration?: string;
  email?: string;
  phone?: string;
  contactName?: string;
  inactiveMessage?: string;
  isActive?: 0 | 1;
  logoId?: number;
  logoFile?: File;
  coverFile?: File;
  coverId?: number;
  isFranchise?: boolean;
  address?: AddressModel;
  orderTypes?: OrderTypeModel[];
  deliveryRules?: DeliveryRuleModel[];
  workingHours?: WorkingHoursModel;
  logo: ImageModel;
  cover: ImageModel;
  defaultLogoUrl = '/assets/images/placeholder-restaurant.jpg';
  defaultCoverUrl = '/assets/images/placeholder-restaurant-cover.jpg';
  earnings: EarningModel[];
  tableLocations?: string[];

  constructor(restaurant: any) {
    this.id = restaurant.id;
    this.companyId = restaurant.company ? restaurant.company.id : 0;
    this.company = restaurant.company ? new CompanyModel(restaurant.company) : undefined;
    this.addressId = restaurant.address_id;
    this.name = restaurant.name;
    this.slug = restaurant.slug;
    this.email = restaurant.email;
    this.phone = restaurant.phone;
    this.preparationTime = restaurant.preparation_time;
    this.deliveryTime = restaurant.delivery_time;
    this.datatransMerchantId = restaurant.datatrans_merchant_id;
    this.phone = restaurant.phone;
    this.contactName = restaurant.contact_name;
    this.inactiveMessage = restaurant.inactive_message;
    this.description = restaurant.description;
    this.declaration = restaurant.declaration;
    this.isActive = restaurant.is_active;
    this.isFranchise = restaurant.is_franchise;
    this.tableLocations = restaurant.table_locations;
    this.logoId = restaurant.logo_id;
    this.coverId = restaurant.cover_id;
    this.address = restaurant.address ? new AddressModel(restaurant.address) : undefined;
    this.orderTypes = restaurant.order_types ? restaurant.order_types.map(type => new OrderTypeModel(type)) : undefined;
    this.deliveryRules = restaurant.delivery_rules ? restaurant.delivery_rules.map(rule => new DeliveryRuleModel(rule)) : undefined;
    this.workingHours = restaurant.working_hours ? new WorkingHoursModel(restaurant.working_hours) : undefined;
    this.logo = restaurant.logo;
    this.cover = restaurant.cover;
    this.earnings = restaurant.earnings ? restaurant.earnings.map(earning => new EarningModel(earning)) : undefined;
  }

  toApi(restaurant: RestaurantModel) {
    const payload = {
      ...(restaurant.name ? {name: restaurant.name} : {}),
      ...(restaurant.email ? {email: restaurant.email} : {}),
      ...(restaurant.isFranchise && restaurant.companyId ? {company_id: restaurant.companyId} : {}),
      ...(restaurant.logoFile ? {logo_file: restaurant.logoFile} : {}),
      ...(restaurant.coverFile ? {cover_file: restaurant.coverFile} : {}),
      ...(restaurant.preparationTime ? {preparation_time: restaurant.preparationTime} : {}),
      ...(restaurant.deliveryTime ? {delivery_time: restaurant.deliveryTime} : {}),
      ...(restaurant.contactName !== undefined ? {contact_name: restaurant.contactName} : {}),
      ...(restaurant.description !== undefined ? {description: restaurant.description} : {}),
      ...(restaurant.declaration ? {declaration: restaurant.declaration} : {}),
      ...(restaurant.isActive !== undefined ? {is_active: restaurant.isActive ? 1 : 0} : {}),
      ...(restaurant.address ? {address: new AddressModel({}).toApi(restaurant.address)} : {}),
      ...(restaurant.workingHours ? {working_hours: new WorkingHoursModel({}).toApi(restaurant.workingHours)} : {}),
      ...(restaurant.orderTypes ? {order_types: restaurant.orderTypes.map(type => new OrderTypeModel({}).toApi(type))} : {}),
      ...(restaurant.deliveryRules ? {delivery_rules: restaurant.deliveryRules.map(rule => new DeliveryRuleModel({}).toApi(rule))} : {}),
      inactive_message: restaurant.inactiveMessage,
      datatrans_merchant_id: restaurant.datatransMerchantId,
      phone: restaurant.phone,
      slug: restaurant.slug
    };

    return payload;
  }

  get totalIncome(): number {
    let total = 0;
    this.earnings.forEach(earning => total += parseFloat(earning.income));
    return total;
  }

  get totalCommission(): number {
    let total = 0;
    this.earnings.forEach(earning => total += parseFloat(earning.commission));
    return total;
  }

  getOrderTypeByKey(key: string) {
    return this.orderTypes.find(o => o.orderType === key);
  }

  get logoUrl() {
    if (this.logo && this.logo.id) {
      return ImageHelper.createUrl(this.logo.id, 'w150');
    }
    return this.defaultLogoUrl;
  }

  get coverUrl() {
    if (this.cover && this.cover.id) {
      return ImageHelper.createUrl(this.cover.id, 'w300');
    }
    return this.defaultCoverUrl;
  }

  get activeOrderTypes() {
    return this.orderTypes.filter((o: OrderTypeModel) => o.isActive);
  }

  createModelFromFormValue(restaurant) {
    Object.assign(this, restaurant);
    return this;
  }

  getEarningObject(paymentMethod: string): EarningModel {
    let earning = this.earnings.find(earning => earning.paymentMethod === paymentMethod);
    return earning ? earning : new EarningModel();
  }

  addDeliveryRule(rule: DeliveryRuleModel) {
    this.deliveryRules.push(rule);
  }

  removeDeliveryRule(rule: DeliveryRuleModel) {
    this.deliveryRules = this.deliveryRules.filter((item: DeliveryRuleModel) => {
      if (rule.id) {
        return rule.id !== item.id;
      }

      return rule.zipcode !== item.zipcode || rule.deliveryAmount !== item.deliveryAmount || rule.minOrderAmount !== item.minOrderAmount;
    });
  }

}

export class EarningModel extends BaseModel {
  paymentMethod: string;
  income: string;
  commission: string;

  get initialValues(): {} {
    return {
      paymentMethod: '',
      income: '0',
      commission: '0'
    };
  }
}

export class DeliveryRuleModel extends BaseModel {
  id: number;
  zipcode: string;
  deliveryAmount: number;
  minOrderAmount: number;

  get unsafeApiAttributes(): string[] {
    return [];
  }

}

export class FloorplanModel extends BaseModel {
  id: number;
  imageId: number;
  location: string;
  image: ImageModel;
  imageFile: File;
  floorplanObjects: FloorplanObjectModel[];
  defaultImageUrl = '/assets/images/placeholder-meal.png';

  get childModels() {
    return {
    };
  }

  get unsafeApiAttributes() {
    return ['image', 'defaultImageUrl'];
  }

  imageThumb(specs = ''): string {
    return ImageHelper.createUrl(this.image.id, specs);
  }

  get initialValues() {
    return {
    };
  }

  toApi(model?: this): {} {
    return {
      ...super.toApi(model),
      ...(model ? {image_file: model.imageFile} : this.imageFile ? {image_file: this.imageFile} : {}),
    };
  }

  getImageUrl(specs = '') {
    if (this.image && this.image.url) {
      if (this.image && this.image.id) {
        return ImageHelper.createUrl(this.image.id, specs);
      }
    }
    return this.defaultImageUrl;
  }
}

export class FloorplanObjectModel extends BaseModel {
  id: number;
  width: number;
  height: number;
  top: number;
  left: number;
  rotation: number;
  shape: string;
  color: string;
  table?: TableModel;

  get childModels() {
    return {
      table: TableModel
    };
  }

}
