import { BaseModel } from './base.model';
import { ImageModel } from './image.model';
import { ORDER_TYPES } from '../globals';
import { environment } from '../../../environments/environment';
import { ImageHelper } from '../helpers/image.helper';
import { AvailabilityHoursModel } from './availability-hours.model';

export class ProductModel extends BaseModel {

  static readonly TYPE_FOOD: string = 'food';
  static readonly TYPE_BEVERAGE: string = 'beverage';
  static readonly ORDER_TYPES = ORDER_TYPES;

  id: number;
  categoryId: number;
  imageId: number;
  name: string;
  description: string;
  addonsTitle: string;
  type: string;
  orderTypes: OrderTypesModel;
  price: number;
  maxAddon: number;
  isActive: boolean;
  image: ImageModel;
  category: CategoryModel;
  tags: TagModel[];
  addons: AddonModel[];
  availability: WeeklyAvailabilityModel;

  imageFile?: File;
  defaultImageUrl = '/assets/images/placeholder-meal.png';

  toApi(model?: this): {} {
    return {
      ...super.toApi(model),
      ...(model ? {image_file: model.imageFile} : this.imageFile ? {image_file: this.imageFile} : {}),
    };
  }

  get unsafeApiAttributes() {
    return ['id', 'defaultImageUrl', 'hasAddons', 'addonName', 'addonPrice', 'category', 'image'];
  }

  get initialValues() {
    return {
      tags: [],
      addons: [],
      isActive: true,
      type: ProductModel.TYPE_FOOD,
      availability: new WeeklyAvailabilityModel(),
      orderTypes: new OrderTypesModel(),
    };
  }

  get childModels() {
    return {
      tags: TagModel,
      addons: AddonModel,
      category: CategoryModel,
      availability: WeeklyAvailabilityModel,
      orderTypes: OrderTypesModel
    };
  }

  getImageUrl(specs = '') {
    if (this.image && this.image.url) {
      if (this.image && this.image.id) {
        return ImageHelper.createUrl(this.image.id, specs);
      }
    }
    return this.defaultImageUrl;
  }

  get typeFood() {
    return ProductModel.TYPE_FOOD;
  }

  get typeBeverage() {
    return ProductModel.TYPE_BEVERAGE;
  }

  get hasAddons(): boolean {
    return this.addons.length > 0;
  }

  set hasAddons(b: boolean) {
    if (!b) {
      this.addons = [];
    }
  }

  addAddon(addon: AddonModel) {
    this.addons.push(addon);
  }

  removeAddon(addon: AddonModel) {
    this.addons = this.addons.filter((item: AddonModel) => {
      if (addon.id) {
        return addon.id !== item.id;
      }

      return addon.name !== item.name || addon.price !== item.price;
    });
  }

  typeToCapital(): string {
    return this.type.charAt(0).toUpperCase() + this.type.slice(1);
  }
}

export class CategoryModel extends BaseModel {
  id: number;
  parentId: number;
  imageId: number;
  name: string;
  description: string;
  sort: number;
  level: number;
  posColor: string;
  image: ImageModel;
  imageFile: File;
  parent?: CategoryModel;
  availabilityHours?: AvailabilityHoursModel
  defaultImageUrl = '/assets/images/placeholder-meal.png';

  children?: CategoryModel[] = [];

  get childModels() {
    return {
      children: CategoryModel,
      parent: CategoryModel,
      availabilityHours: AvailabilityHoursModel
    };
  }

  get unsafeApiAttributes() {
    return ['image', 'defaultImageUrl'];
  }

  imageThumb(specs = ''): string {
    return ImageHelper.createUrl(this.image.id, specs);
  }

  get initialValues() {
    return {
      sort: 0,
      level: 0
    };
  }

  toApi(model?: this): {} {
    return {
      ...super.toApi(model),
      ...(model ? {image_file: model.imageFile} : this.imageFile ? {image_file: this.imageFile} : {}),
    };
  }

  getImageUrl(specs = '') {
    if (this.image && this.image.url) {
      if (this.image && this.image.id) {
        return ImageHelper.createUrl(this.image.id, specs);
      }
    }
    return this.defaultImageUrl;
  }
}

export class TagModel extends BaseModel {
  id: number;
  restaurantId: number;
  name: string;
  type: string;

  get unsafeApiAttributes() {
    return [];
  }
}

export class AddonModel extends BaseModel {
  id: number;
  name: string;
  price: number;

  get unsafeApiAttributes() {
    return [];
  }
}

export class WeeklyAvailabilityModel extends BaseModel {
  onMon: boolean;
  onTue: boolean;
  onWed: boolean;
  onThu: boolean;
  onFri: boolean;
  onSat: boolean;
  onSun: boolean;
}

export class OrderTypesModel extends BaseModel {
  hasDelivery: boolean;
  hasTakeaway: boolean;
  hasInRestaurant: boolean;
}
