import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ToastManagementService } from '../../shared/services/toast-management.service';

const GENERIC_ERROR = 'Your request has failed. Please Try again Later.';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private toast: ToastManagementService
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (!event.body || event.body.success === false) {
            this.toast.showDanger(event.body.errors[0]);
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status != 401 && error.status != 403) {
          this.toast.showDanger(GENERIC_ERROR);
        }
        return throwError(error);
      })
    ) as Observable<HttpEvent<any>>;
  }
}
