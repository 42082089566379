import { BaseModel } from './base.model';
import { DateHelper } from '../helpers/date.helper';

export class HolidayModel extends BaseModel {
  id: number;
  restaurantId: number;
  name: string;
  displayMessage: string;
  startDate: string;
  endDate: string;
  isActive: boolean;

  constructor(attributes?: any) {
    super();

    if (attributes) {
      this.setAttributes(attributes);
    }

    this.startDate = DateHelper.toModel(this.startDate);
    this.endDate = DateHelper.toModel(this.endDate);
  }

  get unsafeApiAttributes() {
    return [];
  }

  toApi(): any {
    const config = {
      ...this,
      start_date: DateHelper.toApi(this.startDate),
      end_date: DateHelper.toApi(this.endDate),
      display_message: this.displayMessage,
      is_active: this.isActive
    };
    delete config.startDate;
    delete config.endDate;
    delete config.displayMessage;
    delete config.isActive;
    return config;
  }
}
