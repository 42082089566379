<!--begin::Mixed Widget 14-->
<div
  class="card card-custom bgi-no-repeat bgi-size-cover"
  [ngClass]="cssClass"
  style="background-image: url('./assets/media/stock-600x600/img-16.jpg')"
>
  <!--begin::Body-->
  <div
    class="card-body d-flex flex-column align-items-start justify-content-start"
  >
    <div class="p-1 flex-grow-1">
      <h3 class="text-white font-weight-bolder line-height-lg mb-5">
        Create Reports<br />With App
      </h3>
    </div>

    <a href="#" class="btn btn-link btn-link-warning font-weight-bold">
      Create Report
      <span
        [inlineSVG]="'./assets/media/svg/icons/Navigation/Arrow-right.svg'"
        class="svg-icon ssvg-icon-lg svg-icon-warning"
      ></span>
    </a>
  </div>
  <!--end::Body-->
</div>
<!--end::Mixed Widget 14-->
