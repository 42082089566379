import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { CompanyModel } from '../models/company.model';
import { map } from 'rxjs/operators';
import { ToastManagementService } from './toast-management.service';
import { QueryParamsInterface } from '../interfaces/query-params.interface';


const API_COMPANY_URL = '/companies';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private api: BaseApiService,
    private toast: ToastManagementService,
  ) {
  }

  getCompanies(): Observable<HttpResponse<CompanyModel[]>> {
    const params: QueryParamsInterface = {expand: 'address'};
    return this.api.get(`${API_COMPANY_URL}`, params).pipe(
      map(response => {
          const res = JSON.parse(JSON.stringify(response));
          if (res.body) {
            res.body = res.body.map(item => new CompanyModel(item));
          }
          return res;
        }
      )
    );
  }

  getCompaniesWithPagination(queryParams: QueryParamsInterface = {}): Observable<HttpResponse<CompanyModel[]>> {
    queryParams.expand = queryParams.expand ? queryParams.expand : 'address';

    return this.api.get(`${API_COMPANY_URL}`, queryParams);
  }

  saveCompany(company: CompanyModel): Observable<HttpResponse<CompanyModel>> {
    const method = company.id ? 'put' : 'post';
    return this.api[method](`${API_COMPANY_URL}${company.id ? '/' + company.id : ''}`, company.toApi()).pipe(
      map(res => {
        if (res.body) {
          this.toast.showSuccess('Company saved Successfully');
        }
        const response = JSON.parse(JSON.stringify(res));
        response.body = new CompanyModel(response.body);
        return response;
      })
    );
  }

}
