import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { PAYMENT_STATUS, PAYMENT_STATUSES } from '../../globals';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent implements OnChanges {

  @Input() paymentStatus: string;

  PAYMENT_STATUS = PAYMENT_STATUS;

  status = '';
  color = '';

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnChanges(): void {
    this.status = PAYMENT_STATUSES.find(status => status.key === this.paymentStatus)?.value || 'N/A';
    this.color = this.paymentStatus ? PAYMENT_STATUSES.find(status => status.key === this.paymentStatus)?.color : 'light';
  }

}
