import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../_services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ConfirmPasswordValidator } from '../registration/confirm-password.validator';
import { ToastManagementService } from '../../../shared/services/toast-management.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  passwordResetForm: FormGroup;
  hasError: boolean;
  saving = false;
  returnUrl: string;
  isLoading$: Observable<boolean>;
  @Input() fullPageChangePassword = true;
  @Output() register: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() forgotPassword: EventEmitter<boolean> = new EventEmitter<boolean>();

  // private fields
  private unsubscribe: Subscription[] = [];
  private token: string;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastManagementService
  ) {
    this.isLoading$ = this.authService.isLoading$;
  }

  ngOnInit(): void {
    this.initForm();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'.toString()] || '/';
    this.token = this.route.snapshot.queryParams.reset_token || '';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.passwordResetForm.controls;
  }

  initForm() {
    this.passwordResetForm = this.fb.group({
      passwordRepeat: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
      ],
    }, {validator: ConfirmPasswordValidator.MatchPassword});
  }

  submit() {
    this.hasError = false;
    this.saving = true;
    const resPassSub = this.authService
      .resetPassword(this.f.password.value, this.f.passwordRepeat.value, this.token)
      .pipe(first())
      .subscribe((success: boolean) => {
        if (success) {
          this.toast.showSuccess(_('Password changed successfully'));
          this.router.navigate(['/auth/login']).then();
        }
      });
    this.unsubscribe.push(resPassSub);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }


  emitForgotPassWordEvent(): void {
    this.forgotPassword.emit(true);
  }

}
