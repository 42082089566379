import { Injectable, OnDestroy } from '@angular/core';
import { ToastManagementService } from './toast-management.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { OrderItem, OrderModel } from '../models/order.model';
import { Subscription } from 'rxjs';
import { PrinterSettingsModalComponent } from '../components/printer-settings-modal/printer-settings-modal.component';
import { ModalService } from './modal.service';
import { CategoryService } from './category.service';
import { CategoryModel } from '../models/product.model';
import { CategoryHelper } from '../helpers/category.helper';
import { ORDER_TYPE, PAYMENT_METHODS } from '../globals';
import { TranslateService } from '@ngx-translate/core';
import { RestaurantModel } from '../models/restaurant.model';
import { Printer } from '../printing/printer';
import { UsbPrinter } from '../printing/printers/usb.printer';
import { BixolonWebPrinter } from '../printing/printers/bixolon.web.printer';
import { Line, PrintingData } from '../printing/printing-data';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class PrinterService implements OnDestroy {

  private static readonly SAVED_PRINTERS = 'saved_printers';
  private static readonly PRINT_ORDERS_AUTOMATICALLY = 'print_orders_automatically';

  printers: Printer[] = [];

  private categories: CategoryModel[] = [];
  private subscriptions: Subscription[] = [];

  constructor(private toast: ToastManagementService, private modalService: ModalService, private categoryService: CategoryService, private translate: TranslateService) {
    this.getPrinters();

    this.categoryService.getCategories({ expand: 'parent_id', 'per-page': 100 }).subscribe((categories: CategoryModel[]) => {
      this.categories = categories;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getPrinters() {
    this.printers = JSON.parse(localStorage.getItem(PrinterService.SAVED_PRINTERS));

    if (!this.printers) {
      this.printers = [];
      return;
    }

    this.printers = this.printers.map(printer => this.mapPrinter(printer));
  }

  private mapPrinter(printer: Printer): Printer {
    switch (printer.printerType) {
      case UsbPrinter.PRINTER_TYPE:
        return new UsbPrinter(this, printer);
      case BixolonWebPrinter.PRINTER_TYPE:
        return new BixolonWebPrinter(this, printer);
      default:
        return new UsbPrinter(this, printer);
    }
  }

  get hasSavedPrinters(): boolean {
    return this.printers.length > 0;
  }

  testPrint(printer: Printer, callback: any): void {
    printer.print(this.testPrintingData(printer, callback));
  }

  printOrder(restaurant: RestaurantModel, order: OrderModel, orderItems: OrderItem[], callback: any): void {
    for (const printer of this.printers) {
      let printingData = restaurant.id != 8 ? this.generateOrderPrintData(restaurant, order, orderItems, printer.productType, printer.lineWidth, callback)
        : this.generateOrderPrintDataForLycka(restaurant, order, orderItems, printer.productType, printer.lineWidth, callback);
      printer.print(printingData);
    }
  }

  private testPrintingData(printer: Printer, callback: any): PrintingData {
    return new PrintingData(printer.lineWidth)
      .setCallback(callback)
      .setBold(true)
      .setSize(3)
      .setAlignment(Line.LINE_ALIGNMENT_CENTER)
      .addLine('Dinego')
      .setSize(2)
      .addLine(this.translateLabel(_('Test print')))
      .addBlankLine()
      .setAlignment(Line.LINE_ALIGNMENT_LEFT)
      .setSize(1)
      .addLine(this.translateLabel(_('Printer')) + ': ' + printer.deviceName)
      .addLine(this.translateLabel(_('Line Width')) + ': ' + printer.lineWidth)
      .addBlankLine()
      .addTestingSeparator()
      .setEndFeed(3);
  }

  addPrinter(printer: Printer) {
    let printerAlreadySaved = this.printers.some(savedPrinter => {
      return savedPrinter.deviceName == printer.deviceName && savedPrinter.serialNumber == printer.serialNumber && savedPrinter.productId == printer.productId;
    });

    if (printerAlreadySaved) {
      this.toast.showDanger(this.translateLabel(_('Selected printer already saved')));
      return;
    }

    this.printers.push(this.mapPrinter(printer));
    this.savePrinters();

  }

  removePrinter(printer: Printer) {
    this.printers.forEach((item, index) => {
      if (item === printer) {
        this.printers.splice(index, 1);
      }
    });

    this.savePrinters();
  }

  savePrinters() {
    localStorage.setItem(PrinterService.SAVED_PRINTERS, JSON.stringify(this.printers));
  }

  get printOrdersAutomatically(): any {
    return JSON.parse(localStorage.getItem(PrinterService.PRINT_ORDERS_AUTOMATICALLY)) === true;
  }

  set printOrdersAutomatically(printAutomatically: any) {
    localStorage.setItem(PrinterService.PRINT_ORDERS_AUTOMATICALLY, JSON.stringify(printAutomatically));
  }

  async onAutoPrintingSettingsChanged(printAutomatically: boolean): Promise<void> {
    this.printOrdersAutomatically = printAutomatically;

    if (printAutomatically) {
      this.initConnections();
      if (!this.hasSavedPrinters) {
        this.toast.showDanger(this.translateLabel(_('Printer is not connected')));
      }
    }
  }

  private initConnections(): void {
    this.printers.forEach(printer => printer.connect().catch(error => this.toast.showDanger(this.translateLabel(error.message))));
  }

  public get hasConnectedPrinters(): boolean {
    return this.printers.some(printer => printer.connected);
  }

  public onPrinterConnectionStatusChanged(): void {

  }

  generateOrderPrintData(restaurant: RestaurantModel, order: OrderModel, orderItems: OrderItem[], productType: string, lineWidth: number, callback: any): PrintingData {

    let categoryGroup = CategoryHelper.groupItemsWithRootCategories(orderItems, this.categories);

    let data = new PrintingData(lineWidth);

    data.setCallback(callback);

    data.setBold(true)
      .setAlignment(Line.LINE_ALIGNMENT_CENTER)
      .addSeparator()
      .addLine(restaurant.name);

    if (restaurant.company.vatNumber) {
      data.addBlankLine()
        .setAlignment(Line.LINE_ALIGNMENT_LEFT)
        .addLine(this.translateLabel(_('VAT Number')) + ': ' + restaurant.company.vatNumber)
        .setAlignment(Line.LINE_ALIGNMENT_CENTER)
    }

    data.addSeparator()
      .setSize(2)
      .addLine('#' + order.id)
      .addSeparator()
      .setAlignment(Line.LINE_ALIGNMENT_LEFT)
      .setSize(2)
      .addLine(this.translateLabel(order.orderTypeObj.value).toUpperCase())
      .setSize(1)
      .addBlankLine();

    if (order.isTakeaway) {
      data.addLine(this.translateLabel(_('Pickup Time:')));
    }

    if (order.isDelivery) {
      data.addLine(this.translateLabel(_('Delivery Time:')));
    }

    data.setSize(2)
      .addLine(order.deliverAtDate ? order.deliverAtTime.slice(0, -3) + ' - ' + new Date(order.deliverAtDate).toLocaleString('de-DE').slice(0, -10) : this.translateLabel(_('ASAP')));


    if (order.payment.paymentMethod) {
      data.setSize(1)
        .addLine('\n' + this.translateLabel(_('Paid with')).toUpperCase() + ': ' + this.translateLabel(PAYMENT_METHODS.find(method => method.key == order.payment.paymentMethod).value).toUpperCase());
    }

    data.setSize(1)
      .addLine(this.translateLabel(_('Ordered at')).toUpperCase() + ': ' + order.createdAtDateObject.toLocaleString('de-DE').slice(0, -3))
      .addLine(order.customer ? order.customer.fullname.toUpperCase() : '');

    if (order.orderType == ORDER_TYPE.delivery) {
      if (order.address) {
        data.addLine(order.address.asString.toUpperCase());
      }
    }

    if (order.orderType == ORDER_TYPE.delivery || order.orderType == ORDER_TYPE.takeaway) {
      if (order.customer.phone) {
        data.addLine(order.customer.phone);
      }
    }

    if (order.table) {
      data.addLine('\n' + this.translateLabel(_('Table')).toUpperCase() + ': ' + order.table.name.toUpperCase());
    }

    data.setAlignment(Line.LINE_ALIGNMENT_LEFT)
      .addSeparator();

    categoryGroup.forEach(group => {
      data.setAlignment(Line.LINE_ALIGNMENT_CENTER)
        .addSeparator()
        .addLine(PrinterService.removeEmojis(group.category.name).toUpperCase())
        .addSeparator();

      group.items.forEach(item => {

        data.setAlignment(Line.LINE_ALIGNMENT_LEFT)
          .addJustifiedLine(item.quantity + 'x ' + item.name.toUpperCase(), PrinterService.currencyAmount(item.totalAmount));

        let addons = ' -';

        item.addons.forEach(addon => {
          addons += addon.name.toUpperCase() + ', ';
        });

        if (item.addons.length > 0) {
          data.addLine(addons.slice(0, -2));
        }

        if (item.note) {
          data.setAlignment(Line.LINE_ALIGNMENT_LEFT)
            .addLine(this.translateLabel(_('Note')).toUpperCase() + ': ' + item.note.toUpperCase());
        }
      });

    });

    if (order.note) {
      data.addSeparator()
        .setAlignment(Line.LINE_ALIGNMENT_LEFT)
        .addLine(this.translateLabel(_('Note')).toUpperCase() + ': ' + order.note.toUpperCase());
    }

    if (order.additionalAmount || order.deliveryAmount || order.discountAmount) {
      data.addSeparator();
    }

    if (order.additionalAmount) {
      data.addJustifiedLine(this.translateLabel(_('Additional Cost')) + ': ', PrinterService.currencyAmount(order.additionalAmount.toString()));
    }

    if (order.deliveryAmount) {
      data.addJustifiedLine(this.translateLabel(_('Delivery Cost')) + ': ', PrinterService.currencyAmount(order.deliveryAmount.toString()));
    }

    if (order.discountAmount) {
      data.addJustifiedLine(this.translateLabel(_('Discount')) + ': ', PrinterService.currencyAmount((order.discountAmount * (-1)).toString()));
    }

    data.addSeparator()
      .addJustifiedLine(this.translateLabel(_('Price without VAT')) + ': ', PrinterService.currencyAmount((order.totalAmount - order.tipAmount - order.totalTax).toFixed(2).toString()))
      .addJustifiedLine(this.translateLabel(_('VAT Amount')) + ' (' + order.payment.vatPercent + '%): ', PrinterService.currencyAmount(order.totalTax.toFixed(2).toString()));

    if (order.tipAmount > 0) {
      data.addSeparator()
        .addJustifiedLine(this.translateLabel(_('Tip Amount')) + ': ', PrinterService.currencyAmount(order.tipAmount.toString()));
    }

    data.addSeparator()
      .addJustifiedLine(this.translateLabel(_('Total Amount')) + ': ', PrinterService.currencyAmount(order.totalAmount.toString()))
      .addSeparator();

    data.setEndFeed(5);

    return data;
  }

  generateOrderPrintDataForLycka(restaurant: RestaurantModel, order: OrderModel, orderItems: OrderItem[], productType: string, lineWidth: number, callback: any): PrintingData {

    let categoryGroup = CategoryHelper.groupItemsWithRootCategories(orderItems, this.categories);

    let data = new PrintingData(lineWidth);

    data.setCallback(callback);

    data.setBold(true)
      .setAlignment(Line.LINE_ALIGNMENT_CENTER)

    data.setSize(2)
      .addSeparator()
      .setAlignment(Line.LINE_ALIGNMENT_LEFT)
      .setSize(1)
      .addJustifiedLine(this.translateLabel(order.orderTypeObj.value).toUpperCase(), '#' + order.id)
      .setSize(1)
      .addBlankLine();

    if (order.isTakeaway && order.deliverAtDate) {
      data.addLine(this.translateLabel(_('Pickup Time:')));
    }

    if (order.deliverAtDate) {
      data.setSize(1)
        .addLine(order.deliverAtDate ? order.deliverAtTime.slice(0, -3) + ' - ' + new Date(order.deliverAtDate).toLocaleString('de-DE').slice(0, -10) : this.translateLabel(_('ASAP')));
    }

    data.setSize(1)
      .addLine(this.translateLabel(_('Ordered at')).toUpperCase() + ': ' + order.createdAtDateObject.toLocaleString('de-DE').slice(0, -3))

    if (order.table) {
      data.addLine('\n' + this.translateLabel(_('Table')).toUpperCase() + ': ' + order.table.name.toUpperCase());
    }

    data.setAlignment(Line.LINE_ALIGNMENT_LEFT)
      .addSeparator();

    categoryGroup.forEach(group => {
      data.setAlignment(Line.LINE_ALIGNMENT_CENTER)

      group.items.forEach(item => {

        data.setAlignment(Line.LINE_ALIGNMENT_LEFT)
          .addJustifiedLine(item.quantity + 'x ' + item.name.toUpperCase(), PrinterService.currencyAmount(item.totalAmount));

        let addons = ' -';

        item.addons.forEach(addon => {
          addons += addon.name.toUpperCase() + ', ';
        });

        if (item.addons.length > 0) {
          data.addLine(addons.slice(0, -2));
        }

        if (item.note) {
          data.setAlignment(Line.LINE_ALIGNMENT_LEFT)
            .addLine(this.translateLabel(_('Note')).toUpperCase() + ': ' + item.note.toUpperCase());
        }
      });

    });

    if (order.note) {
      data.addSeparator()
        .setAlignment(Line.LINE_ALIGNMENT_LEFT)
        .addLine(this.translateLabel(_('Note')).toUpperCase() + ': ' + order.note.toUpperCase());
    }

    data.addSeparator()

    data.setEndFeed(3);

    return data;
  }

  private static currencyAmount(amount: string) {
    const currency = UserService.getCurrencyConfig();
    return currency.isPrefix ? `${currency.value}${amount}` : `${amount}${currency.value}`;
  }

  private static removeEmojis(text: string) {
    return text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
  }

  public openPrinterSettingsModal() {
    const modalRef = this.modalService.open(PrinterSettingsModalComponent, { centered: true, size: 'lg' });
    this.subscriptions.push(
      modalRef.componentInstance.saved.subscribe(value => {
        this.modalService.close(modalRef);
      })
    );
  }

  translateLabel(value: string) {
    return this.translate.instant(value);
  }

  public showErrorMessage(device: string, message: string): void {
    this.toast.showDanger(device + ': ' + this.translateLabel(message));
  }

  public showSuccessMessage(device: string, message: string): void {
    this.toast.showSuccess(device + ': ' + this.translateLabel(message));
  }
}
