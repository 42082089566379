<form [formGroup]="form" (ngSubmit)="save()">
    <div class="modal-header">
        <h4 class="modal-title">{{'Company' | translate}}</h4>
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="companyName">{{'Name' | translate}}</label>
            <input type="text"
                   class="form-control"
                   id="companyName"
                   formControlName="name"
                   [class.form-field-error]="form.get('name').touched && form.get('name').errors">
            <small *ngIf="form.get('name').touched && form.get('name').errors?.required" class="text-danger">
                {{'Name is required' | translate}}
            </small>
        </div>
        <app-address-form formGroupName="address"></app-address-form>
        <div class="row">
            <div class="col-6">
                <label for="vatNumber">{{'VAT Number' | translate}}</label>
                <input type="text"
                       class="form-control mb-4"
                       id="vatNumber"
                       formControlName="vatNumber"
                       [class.form-field-error]="form.get('vatNumber').touched && form.get('vatNumber').errors">
                <small *ngIf="form.get('vatNumber').touched && form.get('vatNumber').errors?.required" class="text-danger">
                    {{'VAT Number is required' | translate}}
                </small>
            </div>
            <div class="col-6">
                <label for="currency">{{'Currency' | translate}}</label>
                <select class="form-control"
                        id="currency"
                        formControlName="currency">
                    <option *ngFor="let currency of currencies" [value]="currency.code">
                        {{currency.code | translate}} ({{currency.name}})
                    </option>
                </select>
                <small *ngIf="form.get('currency').touched && form.get('currency').errors?.required" class="text-danger">
                    {{'Currency is required' | translate}}
                </small>
            </div>
        </div>
        <span class="switch switch-sm">
            <label>
                <input type="checkbox" id="hasCommission" formControlName="hasCommission">
                <span></span>
            </label>
            <label for="hasCommission">{{'Has Commission' | translate}}</label>
        </span>
        <div class="row mt-5">
            <div class="form-group col-12 col-md-6">
                <label for="commissionType">{{'Commission Type' | translate}}</label>
                <select class="form-control"
                        id="commissionType"
                        formControlName="commissionType">
                    <option *ngFor="let type of commissionTypes" [value]="type.value">
                        {{type.key | translate}}
                    </option>
                </select>
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="commissionAmount">{{'Commission Amount' | translate}}</label>
                <input type="number"
                       class="form-control"
                       id="commissionAmount"
                       formControlName="commissionAmount">
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="cancel()" class="btn btn-light">
            {{'Cancel' | translate}}
        </button>
        <button type="submit" class="btn btn-success" [disabled]="isSaving">
            {{'Save' | translate}}
            <span *ngIf="isSaving" class="spinner-border spinner-border-sm ml-2" role="status">
                <span class="sr-only">Loading...</span>
            </span>
        </button>
    </div>
</form>
